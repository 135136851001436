import React from 'react'
import {Carousel} from '3d-react-carousal';


<div class='p-4' style={{borderRadius:'50px', background:'red',backgroundImage:`url(${require('../Images/carousel/dote.png')})`}}>
    <p>Ayesha Khan</p>
    <br />
    <p>eu dapibus justo rutrum.</p>
    <p style={{maxWidth:'650px'}}>Eeu dapibus justo rutrum. Morbi cursus tortor eu magna sagittis, et posuere felis finibus. Nulla fringilla bibendum erat. Phasellus justo diam</p>
    <hr />
    <p>Instagram | 09th Jan 2023</p>

</div>

const MCarousel = () => {
    let slides = [
        <div class='p-5' style={{minWidth:'300px', borderRadius:'216px', background:'#D81818',backgroundImage:`url(${require('../Images/carousel/dote.png')})`,backgroundRepeat:'no-repeat',backgroundPosition:'top 40% left 35%',boxShadow:' 0px 4px 4px rgba(0, 0, 0, 0.25), inset 8px 8px 6px #EC2E2E, inset 0px -5px 4px rgba(0, 0, 0, 0.25)'}}>
            <p class='fs-1 fw-bold text-white mt-4'>Ayesha Khan</p>
            <br />
            <p class='fs-5 fw-bold text-white'>eu dapibus justo rutrum.</p>
            <p class='fs-5 text-white' style={{maxWidth:'650px'}}>Eeu dapibus justo rutrum. Morbi cursus tortor eu magna sagittis, et posuere felis finibus. Nulla fringilla bibendum erat. Phasellus justo diam</p>
            <hr style={{border: 'none',borderTop: '3px dotted white',color:'red',width:'80%',background:'red',margin:'15px auto'}} />
            <p class='fs-5 text-white'>Instagram | 09th Jan 2023</p>
            <br /> 
         </div>,
         <div class='p-5' style={{minWidth:'300px', borderRadius:'216px', background:'#D81818',backgroundImage:`url(${require('../Images/carousel/dote.png')})`,backgroundRepeat:'no-repeat',backgroundPosition:'top 40% left 35%',boxShadow:' 0px 4px 4px rgba(0, 0, 0, 0.25), inset 8px 8px 6px #EC2E2E, inset 0px -5px 4px rgba(0, 0, 0, 0.25)'}}>
            <p class='fs-1 fw-bold text-white my-4'>Ayesha Khan</p>
            <br />
            <p class='fs-5 fw-bold text-white'>eu dapibus justo rutrum.</p>
            <p class='fs-5 text-white' style={{maxWidth:'650px'}}>Eeu dapibus justo rutrum. Morbi cursus tortor eu magna sagittis, et posuere felis finibus. Nulla fringilla bibendum erat. Phasellus justo diam</p>
            <hr style={{border: 'none',borderTop: '3px dotted white',color:'red',width:'80%',background:'red',margin:'15px auto'}} />
            <p class='fs-5 text-white'>Instagram | 09th Jan 2023</p>
            <br /> 
        </div>,
        <div class='p-5' style={{minWidth:'300px', borderRadius:'216px', background:'#D81818',backgroundImage:`url(${require('../Images/carousel/dote.png')})`,backgroundRepeat:'no-repeat',backgroundPosition:'top 40% left 35%',boxShadow:' 0px 4px 4px rgba(0, 0, 0, 0.25), inset 8px 8px 6px #EC2E2E, inset 0px -5px 4px rgba(0, 0, 0, 0.25)'}}>
            <p class='fs-1 fw-bold text-white my-4'>Ayesha Khan</p>
            <br />
            <p class='fs-5 fw-bold text-white'>eu dapibus justo rutrum.</p>
            <p class='fs-5 text-white' style={{maxWidth:'650px'}}>Eeu dapibus justo rutrum. Morbi cursus tortor eu magna sagittis, et posuere felis finibus. Nulla fringilla bibendum erat. Phasellus justo diam</p>
            <hr style={{border: 'none',borderTop: '3px dotted white',color:'red',width:'80%',background:'red',margin:'15px auto'}} />
            <p class='fs-5 text-white'>Instagram | 09th Jan 2023</p> 
            <br />
        </div>,
           
    ];
    
  return (
    <>
    <Carousel slides={slides} arrows={true} autoplay={false} interval={5000}   /> 
    
    </>
    
  )
}

export default MCarousel