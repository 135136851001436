import React from 'react'

const Footer = () => {
  return (
    <div style={{background:'#282525'}}>
        <div style={{width:'90%',margin:'auto'}}>   
            <nav class="navbar navbar-expand-lg p-0 m-0 py-3">
                <div class="container-fluid">
                    <img style={{width:'150px'}} src={require('../Images/navbar/logo.png')} alt="" />
                    <button class="navbar-toggler bg-secondary-subtle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContentfooter" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    
                    <div class="collapse navbar-collapse " id="navbarSupportedContentfooter">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-start px-5">
                        <li class="nav-item dropdown text-start">
                        <a class="nav-link fw-semibold dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Insurance
                        </a>
                        <ul class="dropdown-menu" style={{width:'fit-contant',margin:'0'}}>
                            <li><a class="dropdown-item " href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </li> 
                        <li class="nav-item">
                            <a class="nav-link fw-semibold text-white" href="#">Renew</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link fw-semibold text-white" href="#">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link fw-semibold text-white" href="#">Support</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link fw-semibold text-white" href="#">Contact Us</a>
                        </li>
                    </ul>
                
                    </div>
                </div>
            </nav> 

            <div class='my-3' style={{border:'.5px solid gray'}}></div>


            <div class='row gap-3 py-3' style={{justifyContent:'space-between'}}> 
                    <div class='col-md-3 text-start d-flex ' style={{alignItems:'center'}}>
                        <div class='px-3'>
                            <img style={{width:'45px'}} src={require('../Images/home/Calling.png')} alt="" />
                        </div>
                        <div>
                            <p class='text-secondary fs-5'>Customer Sales Enquiries</p>
                            <p class='fw-bold text-secondary fs-5'>123 456 7890</p>
                        </div>
                    </div>
                    <div class='col-md-3 text-start d-flex ' style={{alignItems:'center'}}>
                        <div class='px-3'>
                            <img style={{width:'45px'}} src={require('../Images/home/Mail.png')} alt="" />
                        </div>
                        <div>
                            <p class='text-secondary fs-5'>Customer Sales Enquiries</p>
                            <p class='fw-bold text-secondary fs-5'>123 456 7890</p>
                        </div>
                    </div>
                    <div class='col-md-3 text-start d-flex ' style={{alignItems:'center'}}>
                        <div class='px-3'>
                            <img style={{width:'45px'}} src={require('../Images/home/Location.png')} alt="" />
                        </div>
                        <div>
                            <p class='text-secondary fs-5'>Customer Sales Enquiries</p>
                            <p class='fw-bold text-secondary fs-5'>123 456 7890</p>
                        </div>
                    </div> 
            </div>

            <div class='my-3' style={{border:'.5px solid gray'}}></div>

            <div class='row gap-3 py-3' style={{justifyContent:'space-between'}}> 
                    <div class='col-md-3 text-start d-flex ' style={{alignItems:'center'}}> 
                            <p class='text-secondary fs-5'>@ All right reserved Hi Lab</p> 
                    </div>
                    <div class='col-md-3 text-start d-flex ' style={{alignItems:'center'}}> 
                            <p class='text-secondary fs-5'>Privacy Policy</p> 
                    </div>
                    <div class='col-md-3 text-start d-flex ' style={{alignItems:'center'}}> 
                            <p class='text-secondary fs-5'>Terms and conditions</p> 
                    </div> 
            </div>
            <br />

        </div> 
    </div>
  )
}

export default Footer