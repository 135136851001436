import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import md5 from "md5-hash";
import sha256 from "sha256";
import Swal from "sweetalert2";

const NewAccount = () => {
  const navigate = useNavigate();
  // --------veryfy-----------
  const [getOtp, setGetOtp] = useState("");
  const [otp, setOtp] = useState("");
  const [details, setDetails] = useState(false);
  // -----------------------
  const [mobile, setMobile] = useState("");
  const [crypt, setCrypt] = useState("");
  const [radio, setRadio] = useState("ClientNIDA");
  const [rValue, setRValue] = useState("");
  const [userData, setUserData] = useState({});
  const [show, setShow]= useState(false)
  const date = new Date()
    .toISOString()
    .replace("-", "")
    .split("T")[0]
    .replace("-", "");

  const cMobileNo = "255" + mobile;
  const md5Mobile = md5(cMobileNo);
  const md5Date = md5(date);
  const cryptToken = sha256(crypt);

  // console.log(details);
  // console.log(otp);
  // console.log(getOtp);
  // console.log(userData);

  const userToken = localStorage.getItem("User_token");
  const number = localStorage.getItem("number");  
  let lastNumber = ''
  for(let i = 0; i < number.length; i++){
    if( i > 4){ 
      lastNumber += number[i]
    }  
  }
  //    crypt token maker--------------
  const cryptMaker = () => {
    axios
      .post("https://hi-labsolution.org/aris.php", {
        Mobile: md5Mobile,
        Double: md5Date,
        accessKey: "2GuBwAiJ7Akshk3DGKK6AVR410JD7S7dj",
      })
      .then((response) => {
        //   console.log( 'res' ,response.data.data)
        setCrypt(response.data.data);
      })
      .catch((error) => console.log("error", error));
  };
  cryptMaker();

  // -----------------send otp------------------

  const sendOtp = () => {
    axios
      .post(
        `https://tzapis.aris-world.com/fetchMobileOTP.php?accesstoken=${userToken}`
      )
      .then((response) => {
        //   console.log( 'res' ,response.data)
        if (response.data.success == true) {
          setOtp(response.data.data);
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    sendOtp();
  }, []);

  // ------verifyOtp----------------
  const verifyOtp = () => {
    if (otp == getOtp) {
      setDetails(true);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `OTP does not match `,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  //-------------------

  const signUpDetails = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const mobileNum = () => {
    const token = { ...userData };
    token["ClientMobile"] = 255 + number;
    token["accesstoken"] = userToken;
    setUserData(token);
  };
  const setNewAccount = () => {
    const newUserData = JSON.stringify({
      ClientName: userData.ClientName,
      ClientMobile: userData.ClientMobile,
      ClientPOBox: userData.ClientPOBox,
      ClientCity: userData.ClientCity,
      ClientAddress: userData.ClientAddress,
      ClientDoB: userData.ClientDoB,
      ClientEmail: userData.ClientEmail,
      ClientNIDA: userData.ClientNIDA,
      ClientTIN: userData.ClientTIN,
      accesstoken: userToken,
    });
    // console.log(newUserData);
    const headers = {
      "Content-Type": "text/plain",
    };
    axios
      .post(
        "https://tzapis.aris-world.com/sendNewClientDetails.php",
        newUserData,
        { headers }
      )
      .then((response) => {
        //   console.log( 'res' ,response.data)
        if (response.data.success == true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${response.data.message}}`,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/dashboard")

        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 2000,
          });
        }

        //   setCrypt(response.data.data)
      })
      .catch((error) => console.log("error", error));
  };

  const chengNidaTin = (e) => {
    const value = e.target.value;
    setRValue(value);
    radioValue(radio, value);
  };
  const radioValue = (key, value) => {
    setRadio(key);
    const formData = { ...userData };
    if (key == "ClientNIDA") {
      formData.ClientNIDA = value || rValue;
      formData.ClientTIN = "";
    } else {
      formData.ClientTIN = value || rValue;
      formData.ClientNIDA = "";
    }
    setUserData(formData);
  };

  useEffect(() => {
    mobileNum();
  }, [cryptToken]);

  const counters =()=>{
    sendOtp() 
    setShow(true) 
  }

  if(getOtp.length == 6){
    verifyOtp()
  }

  return (
    <div
      style={{
        backgroundImage: `url(${require("../../Images/login/arisLogin.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight: "100vh",
      }}
    >
      <div
        class="text-start   p-5"
        style={{ maxWidth: "550px", margin: "auto" }}
      >
        <img src={require("../../Images/login/finder.png")} alt="" />
        {details ? (
          ""
        ) : (
          <div>
            <p class="text-white fs-1 fw-bold mt-3">
              Verify Your Mobile Number
            </p>
            <p class='text-white'>Please type the verification code <br /> sent to +255 *****<span>{lastNumber}</span> <span><button class='btn text-danger p-0 m-0 border-0 mb-2' disabled={show ? true : false}    onClick={counters}>Resend OTP?</button></span> <span></span> </p>
            <input
              id="contactInputForm"
              class="form-control p-2 px-4 my-3"
              type="text"
              placeholder="OTP"
              onChange={(e) => setGetOtp(e.target.value)}
              maxlength="6"
            />
            <button
              id="button"
              class="btn btn-danger mt-4 p-2 rounded-5"
              style={{ width: "100%" }}
              onClick={verifyOtp}
            >
              Verify
            </button>
          </div>
        )}
        {details ? (
          <div>
            <p class="text-white fs-1 fw-bold mt-3">Add New Account</p>
            <p class="text-white">Fill Personal Details</p>
            <input
              id="contactInputForm"
              class="form-control p-2 px-4 my-3"
              type="text"
              name="ClientName"
              placeholder="Full Name"
              onChange={signUpDetails}
            />
            <input
              id="contactInputForm"
              class="form-control p-2 px-4 my-3"
              type="text"
              name="ClientEmail"
              placeholder="Email Addres"
              onChange={signUpDetails}
            />
            <div class="input-group mb-3 mt-4">
              <span
                class="input-group-text "
                id="numContactInputForm"
                style={{ borderRadius: "50px 0px 0px 50px" }}
              >
                +255
              </span>
              <input
                id="numContactInputForm"
                style={{ borderRadius: " 0px 50px 50px 0px " }}
                class="form-control p-2  m-0"
                type="text"
                value={number}
                readOnly
                name="ClientMobile"
                placeholder="Phone No."
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            {/* <input id='contactInputForm' class="form-control p-2 px-4 my-2" type="text" name='ClientMobile' placeholder='Phone No.' onChange={(e)=>setMobile(e.target.value)} /> */}

            <input
              id="contactInputForm"
              class="form-control p-2 px-4 my-2"
              type="date"
              name="ClientDoB"
              placeholder="DOB"
              onChange={signUpDetails}
            />

            <div class="d-flex gap-3">
              <input
                id="contactInputForm"
                class="form-control p-2 px-4 my-2"
                type="text"
                name="ClientPOBox"
                placeholder="Post Code"
                onChange={signUpDetails}
              />
              <input
                id="contactInputForm"
                class="form-control p-2 px-4 my-2"
                type="text"
                name="ClientCity"
                placeholder="City"
                onChange={signUpDetails}
              />
            </div>
            <div class="d-flex gap-4">
              <div class="d-flex gap-3 align-items-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radio"
                    id="nida"
                    onChange={() => radioValue("ClientNIDA")}
                  />
                  <label class="form-check-label text-light" for="nida">
                    NIDA
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="radio"
                    id="tin"
                    onChange={() => radioValue("ClientTIN")}
                  />
                  <label class="form-check-label text-light" for="tin">
                    TIN
                  </label>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  id="contactInputForm"
                  class="form-control p-2 px-4 my-2"
                  type="text"
                  name={radio}
                  placeholder="Value"
                  onChange={chengNidaTin}
                />
              </div>
            </div>
            <input
              id="contactInputForm"
              class="form-control p-2 px-4 my-2"
              type="text"
              name="ClientAddress"
              placeholder="Full Address"
              onChange={signUpDetails}
            />

            

            <div>
              <button
                id="button"
                class="btn btn-danger mt-4 p-2 rounded-5"
                style={{ width: "100%" }}
                onClick={setNewAccount}
              >
                Continue
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NewAccount;
