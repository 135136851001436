import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import md5 from "md5-hash";
import sha256 from "sha256";
import Swal from "sweetalert2";
import { useEffect } from "react";

const FindAccount = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [findMobileNumber, setFindMobileNumber] = useState("");

  const [crypt, setCrypt] = useState("");

  const date = new Date()
    .toISOString()
    .replace("-", "")
    .split("T")[0]
    .replace("-", "");

  const md5Mobile = md5(mobileNumber);
  const md5Date = md5(date);
  const cryptToken = sha256(crypt);

  const [findUser, setFindUser] = useState([]);

 

  // -----------genret crypt token--------------

  const cryptMaker = () => {
    axios
      .post("https://hi-labsolution.org/aris.php", {
        Mobile: md5Mobile,
        Double: md5Date,
        accessKey: "2GuBwAiJ7Akshk3DGKK6AVR410JD7S7dj",
      })
      .then((response) => {
        // console.log( 'res' ,response.data.data)
        setCrypt(response.data.data);
      })
      .catch((error) => console.log("error", error));
  };
  cryptMaker();

  //---------------send find request-----------------
  const getFind = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/checkCovernote.php?coverNoteNo=${mobileNumber}&accesstoken=${cryptToken}`
      )

      .then((response) => {
        console.log("res", response.data);
        if (response.data?.success == true) {
          const number = response.data?.data;
          const getNumber = number[0]?.clientMobile;
          const getMobileNum = getNumber.split("+").join('')
          const md5getNumber = md5(getMobileNum); 
          let lastNumber = ''
          for(let i = 0; i < getMobileNum.length; i++){
            if( i > 2){ 
              lastNumber += getMobileNum[i]
            }  
          }
          window.localStorage.setItem("number",lastNumber)  
          axios
          .post("https://hi-labsolution.org/aris.php", {
            Mobile: md5getNumber,
            Double: md5Date,
            accessKey: "2GuBwAiJ7Akshk3DGKK6AVR410JD7S7dj",
          })
            .then((res) => {
              // console.log("res :", res.data); 
              let cryptFind = res.data.data;
              let cryptFindHash =sha256(cryptFind)  
              axios.get(`https://tzapis.aris-world.com/checkMobile.php?mobileNo=+${getNumber}&accesstoken=${cryptFindHash}`)
              .then((response) =>{  
                // console.log( 'res' ,response.data)
                 
                    window.localStorage.setItem("User_token",response.data.token)
                    if(response.data?.message == "Existing User"){
                      navigate('/verification') 
                    }else{
                      Swal.fire({
                        title: 'No client associated with this Mobile Number',
                        icon: 'info', 
                      
                          html:
                          '<hr/>'+ 
                          '<a style="color:red;" href="/find-account">Find Your Account</a> ' +
                          '<br/>'+
                          '<hr/>'+ 
                          '<a style="color:blue;" href="/find-account">Setup New Account</a> ' ,
                        
                        showCloseButton: true,
                        // showCancelButton: false,
                        // focusConfirm: false, 
                      })
                    }
                })
                .catch(error => console.log('error',error)) 
             })
            .catch((e) => console.log(e));
        }else{
          Swal.fire({
            title:`${response.data.message}`,
            icon: 'info', 
          
              html:  
              '<hr/>'+ 
              '<a style="color:red; padding:4px 20px;background:white;border-radius: 50px;color: black;width:100%;text-decoration: none" href="/new-account">Setup New Account</a> ' ,
             
            showCloseButton: true,
            // showCancelButton: false,
            // focusConfirm: false, 
          })
        }
      })
      .catch((error) => {
        console.log("error", error); 
      });
  };
 

  return (
    <div
      style={{
        backgroundImage: `url(${require("../../Images/login/arisLogin.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight:'100vh',
      }}
    >
      <br />
      <br />

      <div
        class="text-start   p-5"
        style={{ maxWidth: "550px", margin: "auto" }}
      >
        <img src={require("../../Images/login/finder.png")} alt="" />
        <p class="text-white fs-1 fw-bold mt-3">
          Find your <br /> Account
        </p>
        <p class="text-white">Please enter vehicle registration Number </p>
        <input
          id="contactInputForm"
          class="form-control p-2 px-4 my-2"
          type="text"
          placeholder="Number"
          onChange={(e) => setMobileNumber(e.target.value)}
        />

        <div class="mt-5">
          <div>
            <p class="text-white fs-5 text-center">OR</p>
            <p class="text-white ">Please enter Cover Note Number</p>
            <input
              id="contactInputForm"
              class="form-control p-2 px-4 my-2"
              type="text"
              placeholder="Number"
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            id="button"
            class="btn btn-danger mt-5 p-2 rounded-5"
            style={{ width: "100%" }}
            onClick={getFind}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FindAccount;
