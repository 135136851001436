import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const Contact = () => {
  return (
    <>
    <Navbar/>
    <div style={{background:'#181616' ,overflowX:'hidden'}}>
        <br />
        <br />
        <br />
      <br /> 
        <div class='row gap-4 justify-content-around p-0'>
            <div class='col-md-5 text-start p-4'>
              <p class='text-white fs-1 fw-bold'>Contact Us</p>
              <div class='py-5 text-start' style={{display:'flex',alignItems:'center', maxWidth:'400px',minHeight:'180px',alignItems:'center' ,backgroundImage:`url(${require('../Images/contact/mail.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'100%  100%'}}>
                  <div style={{width:'60%', marginLeft:'23%'}}>
                    <p class='fw-bold fs-5  '>Email</p>
                    <p class='text-secondary '>Loremipsum@ABC.in</p>
                  </div>
              </div> 
              <div class='py-5 text-start' style={{display:'flex',alignItems:'center', maxWidth:'400px',minHeight:'180px',alignItems:'center' ,backgroundImage:`url(${require('../Images/contact/phone.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'100%  100%'}}>
                  <div style={{width:'60%', marginLeft:'23%'}}>
                    <p class='fw-bold fs-5'>Phone </p>
                    <p class='text-secondary'>+1 1234 567 890</p>
                  </div>
              </div> 
              <div class='py-5 text-start' style={{display:'flex',alignItems:'center', maxWidth:'400px',minHeight:'180px',alignItems:'center' ,backgroundImage:`url(${require('../Images/contact/address.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'100%  100%'}}>
                  <div style={{width:'60%', marginLeft:'23%'}}>
                    <p class='fw-bold fs-5'>Address</p>
                    <p class='text-secondary'>408 3rd Floor Sanket Tower, Near Grid Crossing-388001.</p>
                  </div>
              </div> 
            </div>
            <div class='col-md-6 p-0' style={{backgroundImage:`url(${require('../Images/contact/bg.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100% '}}>
              <div class='p-5 text-start'>
                <p class='fs-1 fw-bold text-white'>Fill the Form</p>
                <input id='contactInputForm' class="form-control p-3 px-4" type="text" placeholder='Full Name' />
                <input id='contactInputForm' class="form-control p-3 px-4" type="text" placeholder='Email Address'/> 
                <input id='contactInputForm' class="form-control p-3 px-4" type="text" placeholder='Phone No.'/>
                <textarea id='contactInputForm' class="form-control p-3 px-4" placeholder='Your Message' />
                <p class='fw-bold text-danger my-3' style={{cursor:'pointer'}}>+ Attached Files</p>
                <div class='text-end'>
                    <button id='button' class='btn btn-danger p-2 px-4 fw-bold rounded-5'>Submit</button>
                </div>
              </div>
            </div>
        </div>
        <div style={{height:'50vh'}}>
          <img style={{width:'100%', height:'100%'}} src={require('../Images/contact/map.png')} alt="" />

        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Contact