import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Style.css'

const Navbar = () => {
    const navigat = useNavigate()

  
    
    
 
    
    useEffect(()=>{
        const nav = document.querySelector('.mainNavbar');
        let lastScrollY = window.scrollY; 
        window.addEventListener('scroll',()=>{
            if(window.scrollY > 50){
                 nav.classList.add("navColor"); 
            }else{
               nav.classList.remove("navColor");  
            }
        })
        window.addEventListener('scroll',()=>{
          if(lastScrollY < window.scrollY){
            nav.classList.add('mainNavbar--hidden');
          }else{
            nav.classList.remove('mainNavbar--hidden');
          }  
          lastScrollY =window.scrollY

        })  
    })
  return (
    <div class='mainNavbar'  >
        <nav class="navbar navbar-expand-lg p-0 m-0">
        <div class="container-fluid">
            <img style={{width:'150px',cursor:'pointer'}} src={require('../Images/navbar/logo.png')} alt="" onClick={()=>navigat('/')} />
            <button class="navbar-toggler bg-secondary-subtle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                <a class="nav-link fw-semibold dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Insurance
                </a>
                <ul class="dropdown-menu"  >
                    <li><a class="dropdown-item" onClick={()=>navigat('/insurance')} >insurance</a></li>
                    <li><a class="dropdown-item" onClick={()=>navigat('/insurance')}>insurance</a></li>
                    <li><a class="dropdown-item" onClick={()=>navigat('/members')}>Team Members</a></li>
                </ul>
                </li> 
                <li class="nav-item">
                    <a class="nav-link fw-semibold text-white" >Renew</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-semibold text-white" onClick={()=>navigat('/about-us')} >About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-semibold text-white" >Support</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-semibold text-white"  onClick={()=>navigat('/contact')}>Contact Us</a>
                </li>
            </ul>
            <div>
                <button id='button' class="btn btn-danger rounded-pill px-5 mx-5 fw-semibold" type="submit" onClick={()=>navigat('/blank')}>Create an Account</button>
            </div>
            <br />
            </div>
        </div>
        </nav>
    </div>
  )
}

export default Navbar
 