 

import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { AiFillFile, AiOutlineDown, AiOutlineRight } from 'react-icons/ai';
import { FaChevronCircleLeft, FaUserAlt, FaUserPlus } from 'react-icons/fa';
import { IoIosHome } from 'react-icons/io';
import { IoNotifications } from 'react-icons/io5';
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import Swal from 'sweetalert2';

 

const Disclaimer = () => {
  const navigate = useNavigate();

    const [showNoti, setShowNoti] = useState(false);
    const [active, setActive] = useState(false);
    const userToken = localStorage.getItem("User_token");
    const [data, setData] = useState({});
    const [dashboardData, setDashboardData] = useState({});
    const [clientCode, setClientCode] = useState("");
    const [selectBorder, setSelectBorder] = useState("");
    let [loading, setLoading] = useState(false);
    const [discription,setDiscription] = useState()
    const [faqs, setFaqs] = useState({})
    const [tAndC,setTAndC] = useState()

    const details = data?.data;
    const code = localStorage.getItem("clientCode");
    const navClientName = localStorage.getItem("clientName");


    

    const disclaimer = () =>{
      axios.post(`https://tzapis.aris-world.com/getTermsConditions.php?accesstoken=${userToken}&pageCode=disclaimer`)
      .then((response)=>{
        console.log(response.data);
        if(response.data.message ){
          setTAndC(response.data.data)
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
    useEffect(()=>{
        disclaimer()
    },[]) 


    const getMobileData = () => {
        axios
          .get(
            `https://tzapis.aris-world.com/getMobileData.php?accesstoken=${userToken}`
          )
          .then((response) => {
            // console.log("res", response.data);
            if (response.data.success == true) {
              setData(response.data);
              setClientCode(response.data.default);
            }
          })
          .catch((error) => console.log("error", error));
      };
      useEffect(() => {
        getMobileData();
      }, []);

      const client = (clientCode) => { 
        axios
          .get(
            `https://tzapis.aris-world.com/setDefaultClient.php?clientCode=${clientCode}&accesstoken=${userToken}`
          )
          .then((response) => {
            // console.log("Dashboard", response.data);
          })
          .catch((error) => console.log("error", error));
    
        setClientCode(clientCode);
        navigate("/dashboard");
      };
      window.localStorage.setItem("clientCode", clientCode);

      const logOut = () => {
        localStorage.clear();
        navigate("/");
      };


    
 
 
  return (
    <>
    {!userToken ? navigate('/log-in'):
    
    <div
    style={{
      backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 110%",
      minHeight: "110vh",
    }}
  >
    <br />
    <nav
      class="d-flex"
      style={{
        background: "red",
        borderRadius: "50px",
        margin: "0 10px",
        height: "98px",
      }}
    >
      <div
        class="d-flex justify-content-between align-items-center"
        style={{ width: "95%", margin: "auto" }}
      >
        <div class="text-start d-flex align-items-center gap-3">
          <div>
            <img style={{height:'60px'}} src={require('../../Images/home/aris-w-logo.png')} alt="" />
          </div>
          <div> 
            <p class="text-white" style={{ fontWeight: "12px" }}>
              Welcome
            </p>
            <p class="text-white fw-bold userHName">{navClientName}</p>
          </div>
        </div>
        <div class="d-flex">
          <div class="btn-group dropstart">
            <div
              class="rounded-5"
              type="button"
              style={{
                borderRadius: "50px",
                background: "white",
                padding: "10px",
              }}
              onClick={() => setShowNoti(!showNoti)}
            >
              <IoNotifications class="fs-2" />
            </div>
            {!showNoti ? (
              ""
            ) : (
              <ul
                class="text-start px-3 bg-light rounded"
                style={{
                  minWidth: "300px",
                  position: "absolute",
                  left: "-200px",
                  top: "50px",
                  zIndex: "999",
                  listStyle: "none",
                }}
              >
                <p class="fs-5 fw-bold">Notifications</p>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <div
                    class="d-flex gap-2 align-items-center"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <div>
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={require("../../Images/dashoard/notif.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <p class="fw-bold">Claim Accepted</p>
                      <p style={{ fontSize: "12px" }}>1h57min ago</p>
                    </div>
                  </div>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <div
                    class="d-flex gap-2 align-items-center"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <div>
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={require("../../Images/dashoard/notif.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <p class="fw-bold">
                        Policy No- XXXX45241 renewal has been due on 12-Apr
                        2019
                      </p>
                      <p style={{ fontSize: "12px" }}>1h57min ago</p>
                    </div>
                  </div>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <div
                    class="d-flex gap-2 align-items-center"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <div>
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={require("../../Images/dashoard/notif.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <p class="fw-bold">Claim accepted by Insurer</p>
                      <p style={{ fontSize: "12px" }}>1h57min ago</p>
                    </div>
                  </div>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <div
                    class="d-flex gap-2 align-items-center"
                    style={{ borderBottom: "1px solid" }}
                  >
                    <div>
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={require("../../Images/dashoard/notif.png")}
                        alt=""
                      />
                    </div>
                    <div>
                      <p class="fw-bold">
                        Claim amount has been transferred to customer account
                      </p>
                      <p style={{ fontSize: "12px" }}>1h57min ago</p>
                    </div>
                  </div>
                </li>
              </ul>
            )} 
          </div>
          <div
            class="btn-group mx-2 "
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <div
              class="dropdown rounded-5"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                borderRadius: "50px",
                background: "white",
                padding: "10px",
              }}
            >
              <FaUserAlt class="fs-2" />
            </div>
            <div
              class="offcanvas offcanvas-end"
              tabindex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
                <div style={{ width: "100%" }}>
                  <p
                    class="offcanvas-title fs-4 fw-bold"
                    id="offcanvasRightLabel"
                  >
                    Switch Account
                  </p>
                </div>
              </div>
              <div class="offcanvas-body">
                <div
                  style={{
                    display: "grid",
                    background: "rgb(230 230 230)",
                    height: "auto",
                    gap: "1px",
                  }}
                >
                  {details?.map((el) => {
                    const sortName = el.clientName
                      .split(" ")
                      .map((el) => el[0]);
                    const sn = sortName.join("");
                    let pn = "";
                    for (let i = 0; i < sn.length; i++) {
                      if (i < 2) {
                        pn += sn[i];
                      }
                    }

                    return (
                      <div
                        class="d-flex justify-content-between p-2 userCard"
                        style={{ background: "white" }}
                        onClick={() => client(el.clientCode)}
                      >
                        <div class="d-flex gap-3">
                          <div>
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                                background: `#${Math.floor(
                                  Math.random() * 16777215
                                ).toString(16)}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p class="fs-5 text-white">{pn}</p>
                            </div>
                          </div>
                          <div class="text-start">
                            <p class="fw-bold">{el.clientName}</p>
                            {/* <p
                              class="text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {el.clientCode}
                            </p> */}
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <input
                            class="form-check-input radio-danger"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={code == el.clientCode}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {
                    data?.canAddAccount == "Yes" ?
                  <button
                  
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/add-account")}
                  >
                    <span>
                      <FaUserPlus class="fs-5 mx-2" /> <span>Add Account</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                  :
                  ""
                  }

                <button
                 
                  id="button2"
                  class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                  onClick={() => navigate("/file-upload")}
                >
                  <span>
                    <AiFillFile class="fs-5 mx-2" /> <span>Upload files</span>{" "}
                  </span>
                  <span>
                    <AiOutlineRight />
                  </span>
                </button>

                <button
                   
                  id="button2LogOut"
                  class=" col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                  onClick={logOut}
                >
                  <span>
                    <MdLogout class="fs-5 mx-2" /> <span>Log-Out</span>{" "}
                  </span>
                  <span>
                    <AiOutlineRight />
                  </span>
                </button>
              </div>
            </div>
          </div>

            <div
              class="rounded-5"
              type="button"
              style={{
                borderRadius: "50px",
                background: "white",
                padding: "10px",
              }}
              onClick={() => navigate("/dashboard")}
            >
              <IoIosHome class="fs-2" />
            </div>
        </div>
      </div>
    </nav>
    <SyncLoader
      color="red"
      loading={loading}
      // cssOverride={override}
      size={15}
      aria-label="Loading Spinner"
      data-testid="loader"
      style={{
        position: "absolute",
        left: "0%",
        top: "50%",
        width: "100%",
        zIndex: "999999",
      }}
    />
   

   <div class="col-md-10 col-11 m-auto">
            <div class="d-flex justify-content-between m-4 align-items-center">
              <div>
                <p class="text-light fw-bold fs-5 mt-1 text-start">Disclaimer</p>
              </div>
              <div class="d-flex align-items-center">
                <button
                  class="btn text-white fs-2"
                  onClick={() => navigate("/dashboard")}
                >
                  <FaChevronCircleLeft />
                </button>
              </div>
            </div> 
            <div class='text-white text-start' dangerouslySetInnerHTML={{ __html: tAndC?.[0]?.SectionInfo, }} ></div>  

        
                    <br />
          </div> 

    </div>
    }
    </>
  )
}

export default Disclaimer