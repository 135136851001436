import React, { useEffect, useState } from "react";
import "../Style.css";
import { IoDocuments, IoNotifications } from "react-icons/io5";
import { FaChevronCircleLeft, FaUserAlt, FaUserPlus } from "react-icons/fa";

import {
  MdContactPage,
  MdLogout,
  MdMail,
  MdNote,
  MdNoteAdd,
  MdNoteAlt,
  MdOutlineWifiCalling3,
  MdSpeakerNotes,
  MdWifiCalling1,
  MdWifiCalling3,
} from "react-icons/md";
import { AiFillFile, AiOutlineClose, AiOutlineDown, AiOutlineDownload, AiOutlineRight, AiOutlineUpload } from "react-icons/ai";
import { GiNotebook, GiStabbedNote, GiVikingLonghouse } from "react-icons/gi";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-scroll";
import { BiArrowBack, BiCurrentLocation } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import DatePicker from "react-datepicker";
import MapContainer from './Maps';


const today = new Date();
  const fifteenDaysAgo = new Date()
  fifteenDaysAgo.setDate(today.getDate()+ 15 );
   
  
  const API_key = `AIzaSyAvzHK00m3gO1-hBanLOTHn9wNE_BUgdMw`;
const Claims = () => {
  const navigate = useNavigate();
  const [showNoti, setShowNoti] = useState(false);

  const [active, setActive] = useState(false);
  const userToken = localStorage.getItem("User_token");
  const [data, setData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [clientCode, setClientCode] = useState("");
  const [page, setPage] = useState("Dashboard");
  const [selectBorder, setSelectBorder] = useState("");
  const [navName, setNavName] = useState("");
  const [clientDetails, setClientDetails] = useState("");

  const [contactMeDetails, setContactMeDetails] = useState({})
  const [selectedDate,setSelectedDate] = useState()

  const details = data?.data;

 
  const code = localStorage.getItem("clientCode");
  const navClientName = localStorage.getItem("clientName");

  // clim part 
  const [claimD, setClaimD] = useState({});
  const [reportToPolice, setReportToPolice] = useState("No");
  const [claimTypes, setClaimTypes] = useState("Select One");
  const [coverNote, setCoverNote] = useState("");
  const [vehNo, setVehNo] = useState("");
  const [policeCoverNote, setPoliceCoverNote] = useState("");
  const [policeVehNo, setPoliceVehNo] = useState("");
  const [claimType, setClaimType] = useState("");
  const [selectedDates, setSelectedDates] = useState(null);
  const [position,setPosition] = useState()
  const [showMap ,setShowMap] = useState(false)
  const [address, setAddress] = useState('')
  
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null); 
 
  const today = new Date();
  const thirtyDaysAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 30
  );


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  },[]);


   
  // get mobile data
  const getMobileData = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getMobileData.php?accesstoken=${userToken} `
      )
      .then((response) => {
        // console.log("res", response.data);
        if (response.data.success == true) {
          setData(response.data);
          setClientCode(response.data.default);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getMobileData();
  }, []);

  // -------------------------------
  // clientCode ----------------------

  const client = (clientCode) => {
    // debugger
    axios
      .get(
        `https://tzapis.aris-world.com/setDefaultClient.php?clientCode=${clientCode}&accesstoken=${userToken}`
      )
      .then((response) => {
        //   console.log("Dashboard", response.data);
      })
      .catch((error) => console.log("error", error));
    setClientCode(clientCode);
    navigate("/dashboard");
  };
  window.localStorage.setItem("clientCode", clientCode);

  // --------------------dashboard-----------

  const dashboard = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getClientData.php?clientCode=${code}&accesstoken=${userToken}&infoType=Claims`
      )
      .then((response) => {
        // console.log("Cliam data", response.data.data);
        if (response.data.success == true) {
          setDashboardData(response.data.data);
          getMobileData();
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    // debugger
    dashboard();
  }, [code]);

  // --------------user card ----------

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  // console.log("dashboardData", dashboardData);

  const policyCard = () => {
    let arr = [];
    for (let i = 0; i < dashboardData.length; i++) {
      arr.push(
        <div
          class="bg-light mt-3 row"
          style={{
            boxShadow:
              "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
            borderRadius: "20.28px",
          }}
        >
          <div class="col-7 text-start p-3">
            <p class="text-danger fs-4 fw-bold">
              {dashboardData[i].PolicyType} - Claims
            </p>
            <p class="fw-semibold">Claim No : {dashboardData[i].ClaimNo}</p>
            <p class="fw-semibold ">
              {" "}
              Current Status :{dashboardData[i].ClaimStatus}
            </p>
            {/* <p
                class="text-secondary fw-semibold"
                style={{ fontSize: "14px" }}
              >
                {" "}
                <span>
                  {" "}
                  <input
                    class="form-check-input text-bg-danger"
                    type="radio"
                    checked
                  />
                </span>{" "}
                {dashboardData[i].DueDate}
              </p> */}
          </div>
          <div class="col-5 p-0 text-end">
            <div class="d-grid " style={{ height: "100%", alignItems: "end" }}>
              <div>
                <img
                  style={{ width: "99px" }}
                  src={`https://tzapis.aris-world.com/icons/${dashboardData[i].iconFile}`}
                  alt=""
                />
              </div>
              <div>
                <Link
                  onClick={() => setClientDetails(dashboardData[i])}
                  activeClass="active"
                  to="clientRightDetails"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <button
                    class="btn mt-2 text-white px-3"
                    id="button"
                    style={{
                      borderRadius: "20px 0 20px 20px ",
                      height: "",
                    }}
                  >
                    {" "}
                    View Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return arr;
  };

  const contactDetails = (e)=>{
    const { name, value } = e.target;
    setContactMeDetails({ ...contactMeDetails, [name]: value });
  }
 

  useEffect(()=>{ 
    const contact = { ...contactMeDetails };
    contact.requestFor = 'Claims';
    contact.referenceNo = clientDetails?.ClaimNo;
    contact.requestType =  'ContactClient';
    contact.AccessToken = userToken 
    contact.contactDate = selectedDate;

    setContactMeDetails(contact);
  },[clientDetails,userToken,selectedDate])


  const submitContact = ()=>{
    if(
      contactMeDetails?.requestFor && contactMeDetails?.requestFor != "" &&
      contactMeDetails?.referenceNo && contactMeDetails?.referenceNo != "" &&
      contactMeDetails?.requestType && contactMeDetails?.requestType != "" &&
      contactMeDetails?.AccessToken && contactMeDetails?.AccessToken != "" &&
      contactMeDetails?.contactDate && contactMeDetails?.contactDate != "" &&
      contactMeDetails?.requestNotes && contactMeDetails?.requestNotes != ""){
        axios.post(`https://tzapis.aris-world.com/sendContactRequest.php`,contactMeDetails)
        .then((res)=>{
          if(res.data.success == true){
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${res.data.message}`,
              showConfirmButton: false,
              timer: 2000,
            });
            setContactMeDetails({ 
              'contactDate' :'',
              'requestNotes' :''
             })
          }else{ 
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${res.data.message}`,
                showConfirmButton: false,
                timer: 2000,
              });
          }
        })
        .catch((error)=>console.log(error))
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Fill all filds`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
  }

  // claim

  
  const claimCoverNote = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getClientData.php?clientCode=${code}&accesstoken=${userToken}&infoType=Vehicles`
      )
      .then((response) => {
        // console.log("claimCoverNote  ", response.data);
        setCoverNote(response.data.data[0]?.CoverNote);
        setVehNo(response.data.data[0]?.VehRegNo);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    claimCoverNote();
  }, [clientCode]);

  const claimDetails = (e) => {
    const { name, value } = e.target;
    setClaimD({ ...claimD, [name]: value });
  };

  useEffect(() => {
    const addressDetail = { ...claimD };
    addressDetail.autoaddress = address;
    addressDetail.latitude = position?.lat;
    addressDetail.longitude = position?.lng;
    addressDetail.AccessToken = userToken

    setClaimD(addressDetail);
    // setDometicD(addressDetail)
  }, [position,address,userToken]);

  useEffect(() => {
    const police = { ...claimD };
    police.reportToPolice = reportToPolice;
    police.claimType = claimTypes;
    police.incidentDate = selectedDates;

    setClaimD(police);
  }, [reportToPolice, claimTypes,selectedDates]);

  useEffect(() => {
    const report = { ...claimD };
    report.CoverNoteNo = policeCoverNote;
    report.vehRegNo = policeVehNo;
    setClaimD(report);
  }, [policeCoverNote, policeVehNo]);

  const policeCover = () => {
    let arr = [];
    for (let i = 0; i < coverNote?.length; i++) {
      arr.push(
        <div
          data-bs-target="#reportCoverNo"
          data-bs-toggle="collapse"
          onClick={() => setPoliceCoverNote(`${coverNote[i].Number}`)}
        >
          <p>{coverNote[i]?.Description}</p>
        </div>
      );
    }
    return arr;
  };

  const policeVehical = () => {
    let arr = [];
    for (let i = 0; i < vehNo?.length; i++) {
      arr.push(
        <div
          data-bs-target="#reportVehNo"
          data-bs-toggle="collapse"
          onClick={() => setPoliceVehNo(`${vehNo[i]}`)}
        >
          <p>{vehNo[i]}</p>
        </div>
      );
    }
    return arr;
  };
// console.log(claimD);
  const submilClaimReport = () => {
    if(
      claimD?.latitude && claimD?.latitude != "" &&
      claimD?.longitude && claimD?.longitude != "" &&
      claimD?.autoaddress && claimD?.autoaddress != "" &&
      claimD?.incidentDate && claimD?.incidentDate != "" &&
      claimD?.incidentDetails && claimD?.incidentDetails != "" &&
      claimD?.inspectionLocation && claimD?.inspectionLocation != "" &&
      claimD?.reportToPolice && claimD?.reportToPolice != "" &&
      claimD?.claimType && claimD?.claimType != "" &&
      claimD?.AccessToken && claimD?.AccessToken != "" &&
     (claimD?.vehRegNo && claimD?.vehRegNo != "" ||
      claimD?.CoverNoteNo && claimD?.CoverNoteNo != "")
    ){ 
    axios
      .post(`https://tzapis.aris-world.com/sendClaimNotification.php`, claimD)
      .then((response) => {
        if (response.data.success == true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${response.data.message}`,
            showConfirmButton: true,
            // timer: 2000,
          });
          setClaimD({ 
            'autoaddress' : "",
            'incidentDate' : "",
            'incidentDetails': "", 
            'inspectionLocation': "",  
            'reportToPolice': "", 
            'claimType': "", 
            'AccessToken' : "",
            'vehRegNo' : "", 
            'CoverNoteNo' : "", 
           })
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    }else{
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All Fields Are Required `,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const findLocation =()=>{ 
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position?.lat},${position?.lng}&key=${API_key}`)
    .then((res)=>   {
      // console.log(res.data);
    setAddress(res.data.results[5].formatted_address)}
    )
    .catch((error)=>{
      // console.log(error);
    })
  }  

 

  useEffect(()=>{
    findLocation()
  },[position])


  return (
    <>
    {!userToken ? navigate('/log-in'): 
    <div
      style={{
        backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight: "100vh",
      }}
    >
      <br />
      <nav
        class="d-flex"
        style={{
          background: "red",
          borderRadius: "50px",
          margin: "0 10px",
          height: "98px",
        }}
      >
        <div
          class="d-flex justify-content-between align-items-center"
          style={{ width: "95%", margin: "auto" }}
        >
          <div class="text-start d-flex align-items-center gap-3">
            <div>
              <img
                style={{ height: "60px" }}
                src={require("../../Images/home/favicon.png")}
                alt=""
              />
            </div>
            <div>
              <p class="text-white" style={{ fontWeight: "12px" }}>
                Welcome
              </p>
              <p class="text-white fw-bold userHName">{navClientName}</p>
            </div>
          </div>
          <div class="d-flex">
            <div class="btn-group dropstart">
              <div
                class="rounded-5"
                type="button"
                // onClick={() => setShowNoti(!showNoti)}
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
              >
                <IoNotifications class="fs-2" />
              </div>
              {!showNoti ? (
                ""
              ) : (
                <ul
                  class="text-start px-3 bg-light rounded"
                  style={{
                    minWidth: "300px",
                    position: "absolute",
                    left: "-200px",
                    top: "50px",
                    zIndex: "999",
                    listStyle: "none",
                  }}
                >
                  <p class="fs-5 fw-bold">Notifications</p>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">Claim Accepted</p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">
                          Policy No- XXXX45241 renewal has been due on 12-Apr
                          2019
                        </p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">Claim accepted by Insurer</p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">
                          Claim amount has been transferred to customer account
                        </p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div
              class="btn-group mx-2 "
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <div
                class="dropdown rounded-5  "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
              >
                <FaUserAlt class="fs-2" />
              </div>
              <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                  <div style={{ width: "100%" }}>
                    <p
                      class="offcanvas-title fs-4 fw-bold"
                      id="offcanvasRightLabel"
                    >
                      Switch Account
                    </p>
                  </div>
                </div>
                <div class="offcanvas-body">
                  <div
                    style={{
                      display: "grid",
                      background: "rgb(230 230 230)",
                      height: "auto",
                      gap: "1px",
                    }}
                  >
                    {details?.map((el) => {
                      const sortName = el.clientName
                        .split(" ")
                        .map((el) => el[0]);
                      const sn = sortName.join("");
                      let pn = "";
                      for (let i = 0; i < sn.length; i++) {
                        if (i < 2) {
                          pn += sn[i];
                        }
                      }

                      return (
                        <div
                          class="d-flex justify-content-between p-2 userCard"
                          style={{ background: "white" }}
                          onClick={() => client(el.clientCode)}
                        >
                          <div class="d-flex gap-3">
                            <div>
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50px",
                                  background: `#${Math.floor(
                                    Math.random() * 16777215
                                  ).toString(16)}`,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p class="fs-5 text-white">{pn}</p>
                              </div>
                            </div>
                            <div class="text-start">
                              <p class="fw-bold">{el.clientName}</p>
                              {/* <p
                                                                class="text-secondary"
                                                                style={{ fontSize: "14px" }}
                                                            >
                                                                {el.clientCode}
                                                            </p> */}
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <input
                              class="form-check-input radio-danger"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked={code == el.clientCode}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {/* {usersCart()} */}
                  </div>
                  {
                    data?.canAddAccount == "Yes" ?
                  <button
                  
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/add-account")}
                  >
                    <span>
                      <FaUserPlus class="fs-5 mx-2" /> <span>Add Account</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                  :
                  ""
                  }

                  {/* <button
                    
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/file-upload")}
                  >
                    <span>
                      <AiFillFile class="fs-5 mx-2" /> <span>Upload files</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button> */}

                  <button
                    
                    id="button2LogOut"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                    onClick={logOut}
                  >
                    <span>
                      <MdLogout class="fs-5 mx-2" /> <span>Log-Out</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div
              class="rounded-5 "
              type="button"
              style={{
                borderRadius: "50px",
                background: "white",
                padding: "10px",
              }}
              onClick={() => navigate("/dashboard")}
            >
              <IoIosHome class="fs-2" />
            </div>
          </div>
        </div>
      </nav>

      <div class="row g-0">
        <div class="col-lg-7" style={{ borderRight: "gray 1px solid" }}>
          <div class="">
            <div class="d-flex justify-content-between m-4 align-items-center">
              <div>
                <p class="text-light fw-bold fs-5 mt-1 text-start  ">
                  My Claims
                </p>
              </div>
              <div class="d-flex align-items-center">
                <button
                  class="btn text-white fs-2"
                  onClick={() => navigate("/dashboard")}
                >
                  <FaChevronCircleLeft />
                </button>
              </div>
            </div> 
          </div>

          <div class="col-11 text-center m-auto ">
            {/* <p class="text-white fs-5 fw-bold">My Claims</p> */}
            {dashboardData.length > 0 ? 
            policyCard() :
            <div
            class="bg-light mt-3 row"
            style={{
              boxShadow:
                "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
              borderRadius: "20.28px",
            }}
          >
            <div class="col-7 text-start p-3">
                <p class='fw-bold m-0'>No Pending Claims</p>
            </div>
            <div class="col-5 p-2 text-end ">
                <div> 
                      <button
                        class="btn text-white px-5 "
                        id="button"
                        style={{
                          borderRadius: "20px 20px 20px 20px ",
                          height: "40px", 
                        }} 
                        data-bs-toggle="offcanvas"
                        data-bs-target="#reportAClaim"
                        aria-controls="reportAClaim"
                      >
                        {" "}
                        Report a Claim
                      </button>  
                      <div
                        class="offcanvas offcanvas-start"
                        tabindex="-1"
                        id="reportAClaim"
                        aria-labelledby="reportAClaimLabel"
                        style={{
                          backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 110%",
                        }}
                      >
                        <div class="offcanvas-header">
                          <p class="text-white fs-4 fw-bold" id="offcanvasExampleLabel">
                            Report A Claim
                          </p>
                          <button
                            type="button"
                            class="btn text-white"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <AiOutlineClose class="fw-bold fs-3" />
                          </button>
                        </div>
                        <div class="offcanvas-body text-start">
                          {/* <Map details={setLocation}/> */}

                          {/* <input id="contactInputForm" class="form-control p-2 px-4 my-3" type="text" name="CoverNoteNo" placeholder="Cover Note No." onChange={claimDetails} /> */}
                          <div class='d-flex'>
                          <div class="input-group mb-3 ">
                                      <input
                                        id="numContactInputForm"
                                        style={{ borderRadius: " 50px 0px 0px 50px " }}
                                        class="form-control p-2 px-4  m-0"
                                        type="text"
                                        name="autoaddress"
                                        placeholder="Location"
                                        value={claimD?.autoaddress}
                                        onChange={(e) => setAddress(e.target.value)}
                                      />
                                      <span
                                        class="input-group-text "
                                        id="numContactInputForm"
                                        style={{ borderRadius: "0px 50px 50px 0px" }}
                                      >
                                        <BiCurrentLocation
                                          onClick={()=>setShowMap(!showMap) }
                                          style={{
                                            color: "rgb(147, 144, 144)",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </span>
                                    </div>
                                    <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>

                                      {
                                        !showMap ? "" :
                                        <div class='mb-3'>
                                          <div class='text-end'>
                                              <button class='btn fs-4 text-white m-0 p-0' onClick={()=>setShowMap(!showMap) }><AiOutlineClose/></button>
                                          </div>
                                          <MapContainer data={setPosition} lat={latitude} lng={longitude}/>
                                        </div>
                                      }
                          <div class="d-flex gap-4">
                            <div class="d-flex gap-3 align-items-center">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id="CoverNoteNo"
                                  onClick={() => setClaimType("coverNote")}
                                />
                                <label class="form-check-label text-light" for=" ">
                                  Cover Note No.
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id=" VehRegNo"
                                  onClick={() => setClaimType("vehNo")}
                                />
                                <label class="form-check-label text-light" for=" ">
                                  Veh. Reg. No.
                                </label>
                              </div>
                            </div>
                          </div>
                          {claimType == "coverNote" ? (
                            <div class="text-start mt-3">
                              <label
                                class="form-check-label text-light text-start m-0 px-3"
                                for=" "
                              >
                                Cover Note No.
                              </label>
                              <div class='d-flex'>
                              <div
                                id="contactInputForm"
                                class="form-control p-2 px-4 mt-0  mb-0 d-flex justify-content-between"
                                data-bs-toggle="collapse"
                                href="#reportCoverNo"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                style={{  }}
                              >
                                <p style={{ color: "rgb(147, 144, 144)" }}>
                                  {claimD?.CoverNoteNo}
                                </p>
                                <p style={{ color: "rgb(147, 144, 144)" }}>
                                  <AiOutlineDown />
                                </p>
                              </div>
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                              <div class="collapse" id="reportCoverNo">
                                <div class="card card-body reportPolice text-start">
                                  {policeCover()}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {claimType == "vehNo" ? (
                            <div class="text-start mt-3">
                              <label
                                class="form-check-label text-light text-start m-0 px-3"
                                for=" "
                              >
                                Veh. Reg. No.
                              </label>
                              <div class='d-flex'>
                              <div
                                id="contactInputForm"
                                class="form-control p-2 px-4 mt-0  mb-0 d-flex justify-content-between"
                                data-bs-toggle="collapse"
                                href="#reportVehNo"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                style={{  }}
                              >
                                <p style={{ color: "rgb(147, 144, 144)" }}>
                                  {claimD?.vehRegNo}
                                </p>
                                <p style={{ color: "rgb(147, 144, 144)" }}>
                                  <AiOutlineDown />
                                </p>
                              </div>      
                               <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                              <div class="collapse" id="reportVehNo">
                                <div class="card card-body reportPolice text-start">
                                  {policeVehical()}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <label
                            class="form-check-label text-light text-start mt-3 px-3"
                            for=" "
                          >
                            Incident Date
                          </label>
                          <div class='d-flex'>
                          <DatePicker
                            selected={selectedDates}
                            onChange={(date) => setSelectedDates(date)}
                            minDate={thirtyDaysAgo}
                            maxDate={today}
                            showTimeSelect={false}
                            style={{zIndex:'10'}}
                          />
                           <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                          {/* <input id="datepicker" class="form-control p-2 px-4 my-3" type="date" name="incidentDate" placeholder="Inciden Date" onChange={claimDetails} /> */}
                          <div class='d-flex my-3'>
                          <input
                            id="contactInputForm"
                            class="form-control p-2 px-4 m-0"
                            type="text"
                            name="incidentDetails"
                            placeholder="Incident Details"
                            minLength={20}
                            value={claimD?.incidentDetails}
                            onChange={claimDetails}
                          />
                           <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                          <p
                            class="text-danger text-start px-3 fw-semibold"
                            style={
                              claimD?.incidentDetails?.length >= "20"
                                ? { display: "none" }
                                : {
                                    marginTop: "-15px",
                                    display: "block",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            Provide Details - Minimum 20 charactor Required 
                          </p>
                          <div class='d-flex my-3'>
                          <input
                            id="contactInputForm"
                            class="form-control p-2 px-4 m-0"
                            type="text"
                            name="inspectionLocation"
                            placeholder="Inspection Location"
                            value={claimD?.inspectionLocation}
                            onChange={claimDetails}
                          />
                           <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                          {/* <input id="contactInputForm" class="form-control p-2 px-4 my-3" type="text" name="vehRegNo" placeholder="Veh. Reg. No." onChange={claimDetails} /> */}
                          {/* <div
                            id="contactInputForm"
                            class="form-control p-2 px-4 my-3 mb-0 d-flex justify-content-between"
                            data-bs-toggle="collapse"
                            href="#reportPolice"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            style={{  }}
                          >
                            <p style={{ color: "rgb(147, 144, 144)" }}>
                              {reportToPolice}
                            </p>
                            <p style={{ color: "rgb(147, 144, 144)" }}>
                              <AiOutlineDown />
                            </p>
                          </div>
                          <div class="collapse" id="reportPolice">
                            <div class="card card-body reportPolice text-start">
                              <div
                                data-bs-target="#reportPolice"
                                data-bs-toggle="collapse"
                                onClick={() => setReportToPolice("Yes")}
                              >
                                <p>Yes</p>
                              </div>
                              <div
                                data-bs-target="#reportPolice"
                                data-bs-toggle="collapse"
                                onClick={() => setReportToPolice("No")}
                              >
                                <p>No</p>
                              </div>
                            </div>
                          </div> */}

                          <div class='d-flex gap-3 mt-3 mx-3'>
                            <div>
                              <p class='text-white'> Reported to Police?</p>
                            </div>
                            <div>
                              <div class='d-flex  justify-content-between gap-1 text-white' style={{borderRadius: '150px',Color: 'white',background: '#484C55',border: '1.3px solid #494E59',boxShadow: '0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25)'}}>
                                <div class='px-2' style={reportToPolice == "Yes"?{borderRadius:'50px',background:'red',cursor:'pointer'}:{cursor:'pointer'}} onClick={() => setReportToPolice("Yes")}>Yes</div>
                                <div class='px-2' style={reportToPolice == "No"?{borderRadius:'50px',background:'red',cursor:'pointer'}:{cursor:'pointer'}}onClick={() => setReportToPolice("No")}>No</div>
                              </div>
                            </div> 
                          </div>

                          {/* --claim-- */}
                          <div class='d-flex my-3'>
                          <div
                            id="contactInputForm"
                            class="form-control p-2 px-4 m-0 mb-0 d-flex justify-content-between"
                            data-bs-toggle="collapse"
                            href="#claimType"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            style={{  }}
                          >
                            <p style={{ color: "white" }}>{claimD?.claimType}</p>
                            <p style={{ color: "rgb(147, 144, 144)" }}>
                              <AiOutlineDown />
                            </p>
                          </div>
                          <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                           
                          <div class="collapse" id="claimType">
                            <div class="card card-body claimType text-start">
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() => setClaimTypes("Accident")}
                              >
                                <p>Accident</p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() =>
                                  setClaimTypes("Third Party Property Damage")
                                }
                              >
                                <p>Third Party Property Damage</p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() =>
                                  setClaimTypes("Third Party/Pedestrian Bodily Injury")
                                }
                              >
                                <p>Third Party / Pedestrian Bodily Injury</p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() => setClaimTypes("Passenger Bodily Injury")}
                              >
                                <p>Passenger Bodily Injury</p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() =>
                                  setClaimTypes("Rider Bodily Injury/Medical Expenses")
                                }
                              >
                                <p>Rider Bodily Injury / Medical Expenses </p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() => setClaimTypes("Rifer Death Benefit")}
                              >
                                <p>Rifer Death Benefit</p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() => setClaimTypes("Rider Burial Expenses")}
                              >
                                <p>Rider Burial Expenses</p>
                              </div>
                              <div
                                data-bs-target="#claimType"
                                data-bs-toggle="collapse"
                                onClick={() => setClaimTypes("Theft")}
                              >
                                <p>Theft</p>
                              </div>
                            </div>
                          </div>

                          {/* <a
                      style={{ width: "100%", margin: "auto" }}
                      id="button2LogOut"
                      class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                      type="button" 
                    >
                      <span>
                        <AiOutlineUpload class="fs-5 mx-2" />{" "}
                        <span>Upload 1</span>{" "}
                      </span>
                   
                    </a> */}
                    {/* <a
                      style={{ width: "100%", margin: "auto" }}
                      id="button2LogOut"
                      class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                      type="button" 
                    >
                      <span>
                        <AiOutlineUpload class="fs-5 mx-2" />{" "}
                        <span>Upload 2</span>{" "}
                      </span>
                     
                    </a> */}
                    {/* <a
                      style={{ width: "100%", margin: "auto" }}
                      id="button2LogOut"
                      class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                      type="button" 
                    >
                      <span>
                        <AiOutlineUpload class="fs-5 mx-2" />{" "}
                        <span>Upload 3</span>{" "}
                      </span> 
                    </a> */}


                          <button
                            id="button"
                            class="btn btn-danger mt-3 p-2 rounded-5"
                            style={{ width: "100%" }}
                            onClick={submilClaimReport}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      
                </div>
            </div>
          </div>
            }
          </div>
        </div>
        <div class="col-lg-5 clientRightDetails">
          {clientDetails ? (
            <div>
              <div class="col-11 text-center m-auto p-3  ">
                <p class="text-white fs-3 fw-bold">
                  {clientDetails?.PolicyType} - Claim
                </p>
                <div
                  class="bg-light mt-3 p-3 row text-start"
                  style={{
                    boxShadow:
                      "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                    borderRadius: "20.28px",
                  }}
                >
                  <div class=" text-end">
                    <p
                      class="text-secondary fw-semibold "
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      <span>
                        {" "}
                        <input
                          class="form-check-input text-bg-danger"
                          type="radio"
                          checked
                        />
                      </span>{" "}
                      {clientDetails?.DueDate}
                    </p>
                  </div>
                  <div>
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td style={{ width: "150px" }} class="p-1 m-0">
                            <div class="d-flex align-items-center gap-2">
                              {" "}
                              <p class="fw-semibold">Claim No :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.ClaimNo}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "100px" }} class="p-1 m-0">
                            <div class="d-flex align-items-center gap-2">
                              {/* <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../Images/dashoard/insurer.png")}
                                alt=""
                              />{" "} */}
                              <p class="fw-semibold">Insurer :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.Insurer}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              {/* <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../Images/dashoard/ref.png")}
                                alt=""
                              />{" "} */}
                              <p class="fw-semibold">Ref :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.CoverNoteNo}/
                            {clientDetails?.Endorsement}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              {/* <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../Images/dashoard/Date.png")}
                                alt=""
                              />{" "} */}
                              <p class="fw-semibold">Date :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.IncidentDate}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              <p class="fw-semibold">Details :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.ClaimDetails}
                          </td>
                        </tr>
                        {clientDetails?.Surveyor == "Not Applicable" ? (
                          ""
                        ) : (
                          <tr>
                            <td class="p-1 m-0" style={{ width: "100px" }}>
                              <div class="d-flex align-items-center gap-2">
                                <p class="fw-semibold">Surveyor :</p>
                              </div>
                            </td>
                            <td class="p-1 m-0 text-secondary fw-semibold">
                              {clientDetails?.Surveyor}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              <p class="fw-semibold">Claim Handle :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.ClaimHandler}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              <p class="fw-semibold">Claimed Amount :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.ClaimAmount}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              <p class="fw-semibold">Insured :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.InsuredName}
                          </td>
                        </tr>
                        {clientDetails?.Location == "Not Available" ? (
                          ""
                        ) : (
                          <tr>
                            <td class="p-1 m-0" style={{ width: "100px" }}>
                              <div class="d-flex align-items-center gap-2">
                                <p class="fw-semibold">Location :</p>
                              </div>
                            </td>
                            <td class="p-1 m-0 text-secondary fw-semibold">
                              {clientDetails?.Location}
                            </td>
                          </tr>
                        )}
                        {clientDetails?.IncidentLocation == "TBA" ? (
                          ""
                        ) : (
                          <tr>
                            <td class="p-1 m-0" style={{ width: "100px" }}>
                              <div class="d-flex align-items-center gap-2">
                                <p class="fw-semibold">Incident Location :</p>
                              </div>
                            </td>
                            <td class="p-1 m-0 text-secondary fw-semibold">
                              {clientDetails?.IncidentLocation}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div class='p-3'> 
                                        <div style={{borderLeft:'5px solid red',borderRadius:'5px', padding:'2px 5px',background:'#f5f5f5'}}>
                                        <table class="table table-borderless"> 
                                                <tbody>
                                                    <tr  > 
                                                        <td style={{width:'100px'}} class='p-0 m-0'>
                                                            <p class='fw-semibold'>Insured :</p> 
                                                        </td>
                                                        <td class='p-0 m-0 text-secondary fw-semibold'>{clientDetails?.InsuredName}</td> 
                                                    </tr>
                                                    {clientDetails?.Location == "Not Applicable" ? "": 
                                                    <tr > 
                                                        <td  class='p-0 m-0'style={{width:'100px'}}>
                                                            <p class='fw-semibold'>Address :</p> 
                                                        </td>
                                                        <td class='p-0 m-0 text-secondary fw-semibold'>{clientDetails?.Location}</td> 
                                                    </tr>
                                                    }
                                                    <tr > 
                                                        <td class='p-0 m-0' style={{width:'110px'}}>
                                                            <p class='fw-semibold'>Policy Period  :</p> 
                                                        </td>
                                                        <td class='p-0 m-0 text-secondary fw-semibold'>{clientDetails?.PolicyPeriod}</td> 
                                                    </tr>
                                                    <tr > 
                                                        <td class='p-0 m-0' style={{width:'100px'}}>
                                                            <p class='fw-semibold'>Branch :</p> 
                                                        </td>
                                                        <td class='p-0 m-0 text-secondary fw-semibold'>{clientDetails?.Branch}</td> 
                                                    </tr> 
                                                    <tr > 
                                                        <td class='p-0 m-0' style={{width:'100px'}}>
                                                            <p class='fw-semibold'>Policy Class :</p> 
                                                        </td>
                                                        <td class='p-0 m-0 text-secondary fw-semibold'>{clientDetails?.PolicyClass}</td> 
                                                    </tr>
                                                    {
                                                        clientDetails?.VehRegNo  ?  
                                                    <tr > 
                                                        <td class='p-0 m-0' style={{width:'100px'}}>
                                                            <p class='fw-semibold'>Veh.Reg.No. :</p> 
                                                        </td>
                                                        <td class='p-0 m-0 text-secondary fw-semibold'>{clientDetails?.VehRegNo}</td> 
                                                    </tr>
                                                    :""
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                </div>
              </div>

              <div
                class=" col-11 m-auto bg-light p-3 row text-start mt-3"
                style={{
                  boxShadow:
                    "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                  borderRadius: "20.28px",
                }}
              >
                <p class="d-flex justify-content-between fw-bold ">
                  <span>Required Documents</span>{" "}
                </p>

                <div>
                  <table class="table table-border mt-3">
                    <thead>
                      <tr>
                        <td class="p-1 m-0">
                          <div class="d-flex align-items-center gap-2">
                            <p class="fw-semibold">Description</p>
                          </div>
                        </td>
                        <td class="p-1 m-0 fw-semibold">
                          <p class="fw-semibold">FileDetail</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {clientDetails?.Documents?.map((el) => {
                        return (
                          <tr>
                            <td class="">{el.Description}</td>
                            <td class="">
                              {el.FileDetail == "Not Applicable" ? (
                                <button class="btn btn-danger p-0 px-2 m-0 rounded-5">
                                  Upload
                                </button>
                              ) : (
                                <p>{el.FileDetail}</p>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class=" col-11 m-auto bg-light p-3 row text-start mt-3"
                style={{
                  boxShadow:
                    "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                  borderRadius: "20.28px",
                }}
              >
                {/* <p class='d-flex justify-content-between fw-bold '><span>  Details</span> </p> */}

                <div>
                  {clientDetails?.ARiSRiskNote == "Not Available" ? (
                    ""
                  ) : (
                    <a
                      
                      id="button2"
                      class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                      type="button"
                      href={`//${clientDetails?.FilePathURL}/${clientDetails?.ARiSRiskNote}`}
                      target="_blank"
                    >
                      <span>
                        <MdNote class="fs-5 mx-2" />{" "}
                        <span>Download CoverNote</span>{" "}
                      </span>
                      <span>
                        <AiOutlineRight />
                      </span>
                    </a>
                  )}
                </div>
              </div>

              <div
                class=" col-11 m-auto bg-light p-3 row text-start mt-3"
                style={{
                  boxShadow:
                    "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                  borderRadius: "20.28px",
                }}
              >
                <div>
                  <a
                    
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#ClaimContact" aria-controls="staticBackdrop"
                  >
                    <span>
                      <MdWifiCalling1 class="fs-5 mx-2" />{" "}
                      <span>Contact Me for Claims</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </a>
                  <div class="offcanvas offcanvas-end text-white" data-bs-backdrop="static" tabindex="-1" id="ClaimContact" aria-labelledby="staticBackdropLabel" 
                   style={{
                    backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 110%",
                  }}>
                    <div class="offcanvas-header">
                      <h5 class="offcanvas-title" id="staticBackdropLabel">Contact Me</h5>
                      <button type="button" class="btn text-white fs-5" data-bs-dismiss="offcanvas" aria-label="Close"><AiOutlineClose/></button>
                    </div>
                    <div class="offcanvas-body">
                      <div>
                        <label htmlFor="" class='text-white px-3'>Contact Date</label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          minDate={today}
                          maxDate={fifteenDaysAgo}
                          value={contactMeDetails?.contactDate}
                          showTimeSelect={false}
                          style={{zIndex:'10'}}

                        />
                  {/* <input id="datepicker" class="form-control p-2 px-4 my-3" type="date" name="incidentDate" placeholder="Inciden Date" onChange={claimDetails} /> */}
                      

                      <textarea
                        id="contactInputForm"
                        class="form-control p-2 px-4 my-3"
                        type="text"
                        name="requestNotes"
                        placeholder="Request Notes" 
                        value={contactMeDetails?.requestNotes}

                        onChange={contactDetails} 
                      />

                      <button
                        id="button"
                        class="btn btn-danger mt-3 p-2 rounded-5"
                        style={{ width: "100%" }}
                        onClick={submitContact}
                      >
                        Submit
                      </button>
                      </div>
                    </div>
                  </div>

                  <div class='col-md-10 col-12 m-auto mt-3 px-3'>
                    <p class='fw-bold'> {clientDetails?.Handler}</p>
                  </div>

                  <a
                    
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    type="button"
                    href={`tel:${clientDetails?.HandlerMobile}`}
                  >
                    <span>
                      <MdOutlineWifiCalling3 class="fs-5 mx-2" />{" "}
                      <span>Call Now</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </a>

                  <a
                    
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    type="button"
                    href={`mailto:${clientDetails?.HandlerEmail}`}
                  >
                    <span>
                      <MdMail class="fs-5 mx-2" />{" "}
                      <span>Email Now</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
        </div>
      </div>
    </div>
  }
  </>
  );
};

export default Claims;
