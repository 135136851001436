import React, { useEffect, useState } from "react";
import "../Style.css";
import { IoNotifications } from "react-icons/io5";
import { FaChevronCircleLeft, FaUserAlt, FaUserPlus } from "react-icons/fa";

import {
  MdDocumentScanner,
  MdLogout,
  MdNote,
  MdNoteAdd,
  MdNoteAlt,
  MdSpeakerNotes,
  MdWifiCalling1,
  MdWifiCalling3,
} from "react-icons/md";
import { AiFillFile, AiOutlineClose, AiOutlineDownload, AiOutlineRight } from "react-icons/ai";
import { GiNotebook, GiStabbedNote, GiVikingLonghouse } from "react-icons/gi";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-scroll";
import { BiArrowBack } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";
import DatePicker from "react-datepicker";


const today = new Date();
  const fifteenDaysAgo = new Date()
  fifteenDaysAgo.setDate(today.getDate()+ 15 );

const Renewals = () => {
  const navigate = useNavigate();
  const [showNoti, setShowNoti] = useState(false);

  const [active, setActive] = useState(false);
  const userToken = localStorage.getItem("User_token");
  const [data, setData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [clientCode, setClientCode] = useState("");
  const [page, setPage] = useState("Dashboard");
  const [selectBorder, setSelectBorder] = useState("");
  const [navName, setNavName] = useState("");
  const [clientDetails, setClientDetails] = useState("");


  
  const [contactMeDetails, setContactMeDetails] = useState({})
  const [selectedDate,setSelectedDate] = useState()
  const [renewTerms, setRenewTerms] = useState({})


  const details = data?.data;

  
  const code = localStorage.getItem("clientCode");
  const navClientName = localStorage.getItem("clientName"); 

  // get mobile data
  const getMobileData = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getMobileData.php?accesstoken=${userToken} `
      )
      .then((response) => {
        // console.log("res", response.data);
        if (response.data.success == true) {
          setData(response.data);
          setClientCode(response.data.default);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getMobileData();
  }, []);

  // -------------------------------
  // clientCode ----------------------
  const client = (clientCode) => {
    // debugger
    axios
      .get(
        `https://tzapis.aris-world.com/setDefaultClient.php?clientCode=${clientCode}&accesstoken=${userToken}`
      )
      .then((response) => {
        //   console.log("Dashboard", response.data);
      })
      .catch((error) => console.log("error", error));
    setClientCode(clientCode);
    navigate("/dashboard");
  };
  window.localStorage.setItem("clientCode", clientCode);

  // --------------------dashboard-----------

  const dashboard = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getClientData.php?clientCode=${code}&accesstoken=${userToken}&infoType=Renewals`
      )
      .then((response) => {
        // console.log("Dashboard", response.data);
        if (response.data.success == true) {
          setDashboardData(response.data.data);
          getMobileData();
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    // debugger
    dashboard();
  }, [code]);
  // --------------user card ----------

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const policyCard = () => {
    let arr = [];
    for (let i = 0; i < dashboardData.length; i++) {
      arr.push(
        <div
          class="bg-light mt-3 row"
          style={{
            boxShadow:
              "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
            borderRadius: "20.28px",
          }}
        >
          <div class="col-7 text-start p-3">
            <p class="text-danger fs-4 fw-bold">
              {dashboardData[i].PolicyType}
            </p>
            <p class="">
              {dashboardData[i].LastDate} | {dashboardData[i].CoverNoteNo}
            </p>
            <p class="text-danger fw-bold ">{dashboardData[i].VehRegNo}</p>
            <p class="text-secondary fw-semibold" style={{ fontSize: "14px" }}>
              {" "}
              <span>
                {" "}
                <input
                  class="form-check-input text-bg-danger"
                  type="radio"
                  checked
                />
              </span>{" "}
              {dashboardData[i].DueDate}
            </p>
          </div>
          <div class="col-5 p-0 text-end">
            <div class="d-grid " style={{ height: "100%", alignItems: "end" }}>
              <div>
                <img
                  style={{ width: "99px" }}
                  src={`https://tzapis.aris-world.com/icons/${dashboardData[i].iconFile}`}
                  alt=""
                />
              </div>
              <div>
                <Link
                  onClick={() => setClientDetails(dashboardData[i])}
                  activeClass="active"
                  to="clientRightDetails"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <button
                    class="btn mt-2 text-white px-3"
                    id="button"
                    style={{
                      borderRadius: "20px 0 20px 20px ",
                      height: "",
                    }}
                  >
                    {" "}
                    View Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return arr;
  };

  const contactDetails = (e)=>{
    const { name, value } = e.target;
    setContactMeDetails({ ...contactMeDetails, [name]: value });
  }
 

  useEffect(()=>{ 
    const contact = { ...contactMeDetails };
    contact.requestFor = 'Renewals';
    contact.referenceNo = clientDetails?.CoverNoteNo;
    contact.requestType =  'ContactClient';
    contact.AccessToken = userToken 
    contact.contactDate = selectedDate;

    setContactMeDetails(contact);
  },[clientDetails,userToken,selectedDate])


  const submitContact = ()=>{
    if(
      contactMeDetails?.requestFor && contactMeDetails?.requestFor != "" &&
      contactMeDetails?.referenceNo && contactMeDetails?.referenceNo != "" &&
      contactMeDetails?.requestType && contactMeDetails?.requestType != "" &&
      contactMeDetails?.AccessToken && contactMeDetails?.AccessToken != "" &&
      contactMeDetails?.contactDate && contactMeDetails?.contactDate != "" &&
      contactMeDetails?.requestNotes && contactMeDetails?.requestNotes != ""){
        axios.post(`https://tzapis.aris-world.com/sendContactRequest.php`,contactMeDetails)
        .then((res)=>{
          
          if(res.data.success == true){
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${res.data.message}`,
              showConfirmButton: false,
              timer: 2000,
            }); 
             setContactMeDetails({ 
              'contactDate' :'',
              'requestNotes' :''
             })
          }else{ 
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${res.data.message}`,
                showConfirmButton: false,
                timer: 2000,
              });
          }
        })
        .catch((error)=>console.log(error))
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Fill all filds`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
  }

 
  
  useEffect(()=>{ 
    const contact = { ...renewTerms };
    contact.requestFor = 'Renewals';
    contact.referenceNo = clientDetails?.CoverNoteNo;
    contact.requestType =  'SameTerms';
    contact.AccessToken = userToken 
    contact.contactDate = today; 
    contact.requestNotes = '';  
    setRenewTerms(contact);
  },[clientDetails,userToken,today])

  const submitRenewTerms = ()=>{ 
        axios.post(`https://tzapis.aris-world.com/sendContactRequest.php`,renewTerms)
        .then((res)=>{
       
          if(res.data.success == true){
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${res.data.message}`,
              showConfirmButton: false,
              timer: 2000,
            });
          }else{ 
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${res.data.message}`,
                showConfirmButton: false,
                timer: 2000,
              });
          }
        })
        .catch((error)=>console.log(error)) 
  }
  return (
    <>
    {!userToken ? navigate('/log-in'): 
    <div
      style={{
        backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight: "100vh",
      }}
    >
      <br />
      <nav
        class="d-flex"
        style={{
          background: "red",
          borderRadius: "50px",
          margin: "0 10px",
          height: "98px",
        }}
      >
        <div
          class="d-flex justify-content-between align-items-center"
          style={{ width: "95%", margin: "auto" }}
        >
          <div class="text-start d-flex align-items-center gap-3">
            <div>
              <img style={{height:'60px'}} src={require('../../Images/home/aris-w-logo.png')} alt="" />
            </div>
            <div> 
              <p class="text-white" style={{ fontWeight: "12px" }}>
                Welcome
              </p>
              <p class="text-white fw-bold userHName">{navClientName}</p>
            </div>
          </div>
          <div class="d-flex">
            <div class="btn-group dropstart">
              <div
                class="rounded-5"
                type="button"
                // onClick={() => setShowNoti(!showNoti)}
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
              >
                <IoNotifications class="fs-2" />
              </div>
              {!showNoti ? (
                ""
              ) : (
                <ul
                  class="text-start px-3 bg-light rounded"
                  style={{
                    minWidth: "300px",
                    position: "absolute",
                    left: "-200px",
                    top: "50px",
                    zIndex: "999",
                    listStyle: "none",
                  }}
                >
                  <p class="fs-5 fw-bold">Notifications</p>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">Claim Accepted</p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">
                          Policy No- XXXX45241 renewal has been due on 12-Apr
                          2019
                        </p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">Claim accepted by Insurer</p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">
                          Claim amount has been transferred to customer account
                        </p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div
              class="btn-group mx-2 "
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <div
                class="dropdown rounded-5"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
              >
                <FaUserAlt class="fs-2" />
              </div>
              <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                  <div style={{ width: "100%" }}>
                    <p
                      class="offcanvas-title fs-4 fw-bold"
                      id="offcanvasRightLabel"
                    >
                      Switch Account
                    </p>
                  </div>
                </div>
                <div class="offcanvas-body">
                  <div
                    style={{
                      display: "grid",
                      background: "rgb(230 230 230)",
                      height: "auto",
                      gap: "1px",
                    }}
                  >
                    {details?.map((el) => {
                      const sortName = el.clientName
                        .split(" ")
                        .map((el) => el[0]);
                      const sn = sortName.join("");
                      let pn = "";
                      for (let i = 0; i < sn.length; i++) {
                        if (i < 2) {
                          pn += sn[i];
                        }
                      }

                      return (
                        <div
                          class="d-flex justify-content-between p-2 userCard"
                          style={{ background: "white" }}
                          onClick={() => client(el.clientCode, el.clientName)}
                        >
                          <div class="d-flex gap-3">
                            <div>
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50px",
                                  background: `#${Math.floor(
                                    Math.random() * 16777215
                                  ).toString(16)}`,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p class="fs-5 text-white">{pn}</p>
                              </div>
                            </div>
                            <div class="text-start">
                              <p class="fw-bold">{el.clientName}</p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <input
                              class="form-check-input radio-danger"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked={code == el.clientCode}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {
                    data?.canAddAccount == "Yes" ?
                  <button
                  
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/add-account")}
                  >
                    <span>
                      <FaUserPlus class="fs-5 mx-2" /> <span>Add Account</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                  :
                  ""
                  }

                  {/* <button
                     
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/file-upload")}
                  >
                    <span>
                      <AiFillFile class="fs-5 mx-2" /> <span>Upload files</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button> */}

                  <button 
                    id="button2LogOut"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                    onClick={logOut}
                  >
                    <span>
                      <MdLogout class="fs-5 mx-2" /> <span>Log-Out</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div
                class="rounded-5"
                type="button"
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
                onClick={() => navigate("/dashboard")}
              >
                <IoIosHome class="fs-2" />
              </div>
          </div>
        </div>
      </nav>

      <div class="row g-0">
        <div class="col-lg-7" style={{ borderRight: "gray 1px solid" }}>
          <div class="">
            <div class="d-flex justify-content-between m-4 align-items-center">
              <div>
                <p class="text-light fw-bold fs-5 mt-1 text-start  ">My Expiring</p>
              </div>
              <div class="d-flex align-items-center">
                <button
                  class="btn text-white fs-2"
                  onClick={() => navigate("/dashboard")}
                >
                  <FaChevronCircleLeft />
                </button>
              </div>
            </div> 
          </div>
          
          <div class="col-11 text-center m-auto    ">
            {/* <p class="text-white fs-5 fw-bold">My Renewals</p> */}
            {
              dashboardData.length > 0 ?
              policyCard() :
              <div
              class="bg-light mt-3 row"
              style={{
                boxShadow:
                  "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                borderRadius: "20.28px",
              }}
            >
              <div class="col-12 text-start p-3">
                  <p class='fw-bold m-0'>No Pending Expiring in next 60 days</p>
              </div>
              {/* <div class="col-5 p-2 text-end ">
                  <div> 
                        <button
                          class="btn text-white px-5 "
                          id="button"
                          style={{
                            borderRadius: "20px 20px 20px 20px ",
                            height: "40px",
                          }}
                        >
                          {" "}
                          Buy Policy
                        </button>  
                  </div>
              </div> */}
            </div>
            }
          </div>
        </div>
        <div class="col-lg-5 clientRightDetails ">
          {clientDetails ? (
            <div>
              <div class="col-11 text-center m-auto p-3  ">
                <p class="text-white fs-3 fw-bold">
                  {clientDetails?.PolicyType}
                </p>
                <div
                  class="bg-light mt-3 p-3 row text-start"
                  style={{
                    boxShadow:
                      "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                    borderRadius: "20.28px",
                  }}
                >
                  <div class="text-end">
                    {/* <p class='text-danger fs-5 fw-bold'>{clientDetails?.PolicyType}</p> */}
                    <p
                      class="text-secondary fw-semibold"
                      style={{ fontSize: "14px" }}
                    >
                      {" "}
                      <span>
                        {" "}
                        <input
                          class="form-check-input text-bg-danger"
                          type="radio"
                          checked
                        />
                      </span>{" "}
                      {clientDetails?.DueDate}
                    </p>
                  </div>
                  <div>
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td style={{ width: "100px" }} class="p-1 m-0">
                            <div class="d-flex align-items-center gap-2">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../Images/dashoard/insurer.png")}
                                alt=""
                              />{" "}
                              <p class="fw-semibold">Insurer :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.Insurer}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../Images/dashoard/ref.png")}
                                alt=""
                              />{" "}
                              <p class="fw-semibold">Ref :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.CoverNoteNo}/
                            {clientDetails?.Endorsement}
                          </td>
                        </tr>
                        <tr>
                          <td class="p-1 m-0" style={{ width: "100px" }}>
                            <div class="d-flex align-items-center gap-2">
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../Images/dashoard/Date.png")}
                                alt=""
                              />{" "}
                              <p class="fw-semibold">Date :</p>
                            </div>
                          </td>
                          <td class="p-1 m-0 text-secondary fw-semibold">
                            {clientDetails?.LastDate}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="p-3">
                    <div
                      style={{
                        borderLeft: "5px solid red",
                        borderRadius: "5px",
                        padding: "2px 5px",
                        background: "#f5f5f5",
                      }}
                    >
                      <table class="table table-borderless">
                        <tbody>
                          <tr>
                            <td style={{ width: "100px" }} class="p-0 m-0">
                              <p class="fw-semibold">Insured :</p>
                            </td>
                            <td class="p-0 m-0 text-secondary fw-semibold">
                              {clientDetails?.InsuredName}
                            </td>
                          </tr>
                          {clientDetails?.Location == "Not Applicable" ? (
                            ""
                          ) : (
                            <tr>
                              <td class="p-0 m-0" style={{ width: "100px" }}>
                                <p class="fw-semibold">Address :</p>
                              </td>
                              <td class="p-0 m-0 text-secondary fw-semibold">
                                {clientDetails?.Location}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td class="p-0 m-0" style={{ width: "110px" }}>
                              <p class="fw-semibold">Policy Period :</p>
                            </td>
                            <td class="p-0 m-0 text-secondary fw-semibold">
                              {clientDetails?.PolicyPeriod}
                            </td>
                          </tr>
                          <tr>
                            <td class="p-0 m-0" style={{ width: "100px" }}>
                              <p class="fw-semibold">Branch :</p>
                            </td>
                            <td class="p-0 m-0 text-secondary fw-semibold">
                              {clientDetails?.Branch}
                            </td>
                          </tr>
                          <tr>
                            <td class="p-0 m-0" style={{ width: "100px" }}>
                              <p class="fw-semibold">Policy Class :</p>
                            </td>
                            <td class="p-0 m-0 text-secondary fw-semibold">
                              {clientDetails?.PolicyClass}
                            </td>
                          </tr>
                          {clientDetails?.VehRegNo ? (
                            <tr>
                              <td class="p-0 m-0" style={{ width: "100px" }}>
                                <p class="fw-semibold">Veh.Reg.No. :</p>
                              </td>
                              <td class="p-0 m-0 text-secondary fw-semibold">
                                {clientDetails?.VehRegNo}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class=" col-11 m-auto bg-light p-3 row text-start"
                style={{
                  boxShadow:
                    "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                  borderRadius: "20.28px",
                }}
              >
                <p class="d-flex justify-content-between fw-bold ">
                  {/* <span> Details</span>{" "} */}
                </p>

                <div>
                  {clientDetails?.ARiSRiskNote == "Not Available" ? (
                    ""
                  ) : (
                    <a
                      
                      id="button2"
                      class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                      type="button"
                      href={`//${clientDetails?.FilePathURL}/${clientDetails?.ARiSRiskNote}`}
                      target="_blank"
                    >
                      <span>
                        <MdNote class="fs-5 mx-2" />{" "}
                        <span>Download CoverNote</span>{" "}
                      </span>
                      <span>
                        <AiOutlineRight />
                      </span>
                    </a>
                  )}
                  {clientDetails?.PolicyFile == "Not Available" ? (
                    ""
                  ) : (
                    <a  
                      id="button2LogOut"
                      class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                      type="button"
                      href={`//${clientDetails?.FilePathURL}/${clientDetails?.PolicyFile}`}
                      target="_blank"
                    >
                      <span>
                        <AiOutlineDownload class="fs-5 mx-2" />{" "}
                        <span>Download Policy</span>{" "}
                      </span>
                      <span>
                        <AiOutlineRight />
                      </span>
                    </a>
                  )}
                </div>
                <div> 
                  <a  
                      id="button2LogOut" 
                      class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                      type="button"
                      onClick={submitRenewTerms}
                    >
                      <span>
                        <MdDocumentScanner class="fs-5 mx-2" />{" "}
                        <span>Renew with same terms</span>{" "}
                      </span>
                      <span>
                        <AiOutlineRight />
                      </span>
                    </a>
                    <a 
                      id="button2" 
                      class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                      type="button"
                      data-bs-toggle="offcanvas" data-bs-target="#renewalsContact" aria-controls="staticBackdrop"
                    >
                      <span>
                        <MdWifiCalling1 class="fs-5 mx-2" />{" "}
                        <span>Contact Me for Expiring</span>{" "}
                      </span>
                      <span>
                        <AiOutlineRight />
                      </span>
                    </a>
                    <div class="offcanvas offcanvas-end text-white" data-bs-backdrop="static" tabindex="-1" id="renewalsContact" aria-labelledby="staticBackdropLabel" 
                      style={{
                        backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 110%",
                      }}>
                        <div class="offcanvas-header">
                          <h5 class="offcanvas-title" id="staticBackdropLabel">Contact Me</h5>
                          <button type="button" class="btn text-white fs-5" data-bs-dismiss="offcanvas" aria-label="Close"><AiOutlineClose/></button>
                        </div>
                        <div class="offcanvas-body">
                          <div>
                            <label htmlFor="" class='text-white px-3'>Contact Date</label>
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              minDate={today}
                              maxDate={fifteenDaysAgo}
                              value={contactMeDetails?.contactDate}
                              showTimeSelect={false}
                            style={{zIndex:'10'}}

                            />
                      {/* <input id="datepicker" class="form-control p-2 px-4 my-3" type="date" name="incidentDate" placeholder="Inciden Date" onChange={claimDetails} /> */}
                          

                          <textarea
                            id="contactInputForm"
                            class="form-control p-2 px-4 my-3"
                            type="text"
                            name="requestNotes"
                            placeholder="Request Notes" 
                            value={contactMeDetails?.requestNotes}
                            onChange={contactDetails} 
                          />

                          <button
                            id="button"
                            class="btn btn-danger mt-3 p-2 rounded-5"
                            style={{ width: "100%" }}
                            onClick={submitContact}
                          >
                            Submit
                          </button>
                          </div>
                        </div>
                      </div>
                </div>

              </div>
            </div>
          ) : (
            ""
          )}
          <br />
        </div>
      </div>
    </div>
}</>
  );
};

export default Renewals;
