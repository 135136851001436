import React, { useState } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

const MapContainer = ({ google ,data,lat,lng}) => { 

  const [position, setPosition] = useState({ lat: lat, lng: lng });  

  const handleMarkerDragEnd = (mapProps, map, marker) => { 

    setPosition({ lat: map.position.lat(), lng: map.position.lng() });
  };

 useEffect(()=>{
   data(position)
 },[position])

  return (
    <div  >  
        <div style={{width:'100%', height:'65vh', position:'relative'}}>

            <Map google={google} initialCenter={position} zoom={14}   >
            <Marker
                position={position}
                draggable={true}
                onDragend={handleMarkerDragEnd}
            />
            </Map>
        </div>
        <div class='col-md-12 col-12 m-auto text-start '> 
            <label htmlFor="" class='text-white m-0 px-3 mt-3 '>Latitude</label>
            <input type="text"  id="contactInputForm" class="form-control p-2 px-4 m-0" placeholder="Latitude" value={position.lat} />
            <label htmlFor="" class='text-white m-0 px-3  mt-3'>Longitude</label> 
            <input type="text"  id="contactInputForm" class="form-control p-2 px-4 m-0" placeholder="Longitude " value={position.lng} />
        </div>

    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAvzHK00m3gO1-hBanLOTHn9wNE_BUgdMw'
})(MapContainer);