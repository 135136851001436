import React, { useEffect } from 'react'
import './Style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import MCarousel from './Carousel';
import Navbar from './Navbar';
import Footer from './Footer';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1
    }
  };
 

const ourPolicy=[
    {
        title:'Motor Insurance',
        details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor',
        url:'#',
        background:`${require('../Images/home/ourPolicy1.png')}`

    },
    {
        title:'Domestic Insurance',
        details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor',
        url:'#',
        background:`${require('../Images/home/ourPolicy2.png')}`
    }  
]

const whyAris = [
    {
        lines:'Leading risk service provider in East Africa. We are ISO 9001:2015 certified.'
    }
    ,
    {
        lines:'We are the only Chartered Insurance Brokers in mainland Africa.'
    },
    {
        lines:'We are a member of the Inclusivity Pledge, a UK based initiative towards commitment to inclusive behaviours in an organization.'
    }
]

const ourOffering=[
    {
        title:'Placement of the',
        heding:'Insurance Programme',
        background:`${require('../Images/home/ourOffering1.png')}`,
        url:'#'
    },
    {
        title:'Premium',
        heding:'Payment Plan',
        background:`${require('../Images/home/ourOffering2.png')}`,
        url:'#'
    },
    {
        title:'Handling of',
        heding:'Claims',
        background:`${require('../Images/home/ourOffering3.png')}`,
        url:'#'
    },
    {
        title:'Placement of the',
        heding:'Insurance Programme',
        background:`${require('../Images/home/ourOffering1.png')}`,
        url:'#'
    },
    {
        title:'Premium',
        heding:'Payment Plan',
        background:`${require('../Images/home/ourOffering2.png')}`,
        url:'#'
    },
    {
        title:'Handling of',
        heding:'Claims',
        background:`${require('../Images/home/ourOffering3.png')}`,
        url:'#'
    },
    {
        title:'Placement of the',
        heding:'Insurance Programme',
        background:`${require('../Images/home/ourOffering1.png')}`,
        url:'#'
    },
    {
        title:'Premium',
        heding:'Payment Plan',
        background:`${require('../Images/home/ourOffering2.png')}`,
        url:'#'
    },
    {
        title:'Handling of',
        heding:'Claims',
        background:`${require('../Images/home/ourOffering3.png')}`,
        url:'#'
    }
]

 const outNetwork =[
    {
        img : `${require('../Images/home/ip1.png')}`,
        url :''
    },
    {
        img : `${require('../Images/home/ip2.png')}`,
        url :''
    },
    {
        img : `${require('../Images/home/ip3.png')}`,
        url :''
    },
    {
        img : `${require('../Images/home/ip4.png')}`,
        url :''
    },
    {
        img : `${require('../Images/home/ip5.png')}`,
        url :''
    }, 
 ]

 

const Home = () => {
    useEffect(()=>{ 
        AOS.init({duration: 1500});  
    },[])
  return (
    <>
    <Navbar/>
    <div class='home'>
            <br />
            <br />
            <br />
            <br />
         <div class='rounded homeBanner' data-aos="zoom-in-up">
            <div class='text-start p-3'>
                <p class='fs-1 fw-semibold mx-4'>Let's Find you <br /> the <span class='fw-bold text-danger'>Best Insurance</span></p>
                <p class='mt-3 mx-4 fs-5'>Lorem ipsum dolor sit amet, consectetur  adipiscing elit,<br /> sed do eiusmod tempor</p>
                <button id='button'class='btn btn-danger mt-4 mx-4 rounded-5 px-4'>Explore More</button>
            </div>  
         </div>
         <div class='mt-5' style={{width:'90%',margin:'auto'}}>
            <br />
            <p class='text-white fs-1 fw-bold' data-aos="fade-up">Our Policy</p>
            <p class='text-white mt-3 fs-5' style={{maxWidth:'500px',margin:'auto'}} data-aos="fade-up" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor commodo consequat</p>
            <div class='row mt-5 px-2  ' style={{ justifyContent:'space-around',gap:'30px'}}>
                {ourPolicy.map((el)=>{
                    return(
                        <div class='col-md-5 text-start p-4' data-aos="fade-up" style={{backgroundImage:`url(${el.background})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',borderRadius:'10px'}}>
                            <p class='text-white fs-2 fw-bold'>{el.title}</p>
                            <p class='text-white fs-5' style={{maxWidth:'350px'}}>{el.details}</p>
                            <br />
                            <button id='button'class='btn btn-danger mt-5 fw-semibold rounded-5 px-4'>Book Policy</button>
                        </div> 
                    )
                })}
                 
            </div>
         </div>

         <br />

         <div class='row my-5 gap-5' style={{width:'90%', margin:'auto',backgroundImage:`url(${require('../Images/home/whyArisbg.png')})`,backgroundRepeat:'no-repeat', backgroundSize:'',backgroundPosition:'left 20% top 60px' }}>
            <div class='col-md-5' data-aos="zoom-in">
                <img style={{maxWidth:'300px'}} src={require('../Images/home/mobile.png')} alt="" />
            </div>
            <div class='col-md-6 text-end'>
                <p class='fs-3 fw-bold text-white' data-aos="fade-up">Why Aris</p>
                <p class='text-white mb-5' data-aos="fade-up">A upeior Promise!</p>
                 
                    {
                        whyAris.map((el)=>{
                            return(
                                <button class='btnWhyAris text-start ' data-aos="fade-up">{el.lines}</button>
                                )
                            })
                    } 
                
            </div>
         </div>

        <div style={{background:'red'}}>
            <div class='py-5' style={{width:'90%',margin:'auto',justifyContent:'space-around'}}>
                <div class='row mb-5 gap-4'> 
                    <div class='col-md-4 text-start'>
                        <p class='fs-1 fw-bold text-white mx-4'data-aos="fade-up">Our Offering</p>
                    </div>
                    <div class='col-md-7'>
                        <p class='text-white fs-5'data-aos="fade-up" style={{textAlign: 'justify'}}>Insurance is a complex practice that requires enormous resources and capabilities in order to deliver. This is because it is dependent on several fundamental principles and technicalities which in most cases, cannot be quantified. It therefore heavily depends on technical know-how as well as persuasive ability and commercial stature.</p>
                    </div>
                </div>
                <br />
                <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={3000} showDots={false}    removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}>
                    {ourOffering.map((el)=>{
                        return( 
                            <div class='text-start p-4'data-aos="zoom-in" style={{margin:'auto',borderRadius:'15px', maxWidth:'400px',height:'180px',backgroundImage:`url(${el.background})`,cursor:'pointer',backgroundSize:'100% 100%'}}>
                                    <p class='fw-semibold fs-5'>{el.title}</p>
                                    <p class='fw-bold fs-4 text-danger'>{el.heding}</p>
                            </div>
                        )
                    })} 
                </Carousel>
            </div> 
        </div> 

        <div class='row gap-5 ' style={{width:'90%', margin:'auto'}}>
            <div class='col-md-6 text-start'>
                <p class='fs-1 fw-bold text-white mt-5' data-aos="fade-up">Anout Us</p>
                <p class='fs-5 text-white mt-4' data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea</p>
                <p class='fs-5 text-white mt-4' data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et d Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                <button id='button'class='btn btn-danger px-4 my-5 rounded-5' data-aos="fade-up">Explor More</button>
            </div>
            <div class='col-md-5' data-aos="zoom-in">
                <img style={{maxWidth:'90%'}} src={require('../Images/home/aboutUs.png')} alt="" />
            </div>
        </div>
        
 
        <div class='testimonialsCarousel'>
            <p class='fs-1 fw-bold text-white my-5' data-aos="fade-up">Testimonials</p>
            <div data-aos="zoom-in">
                <MCarousel/> 
            </div>
        </div>

<br />
        <div style={{backgroundImage:`url(${require('../Images/home/haq.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'100px 100%'}}> 
            <br />
            <div class='row gap-4 mt-4 justify-content-between' style={{width:'90%',margin:'auto'}}>
                <div class='col-md-5 text-start'>
                    <p class='fs-1 fw-bold text-danger' data-aos="fade-up">Have a question? <br /> Here to help.</p>
                    <p class='fs-5 text-white mt-4' data-aos="fade-up">Our friendly customer support team is your extended family. Speak your heart out. They listen with undivided attention to resolve your concerns. Give us a call, request a callback or drop us an email, we’re here to help.</p>
                </div>
                <div class='col-md-4 '>
                    <div class='py-4 bg-light d-flex' style={{maxWidth:'450px',alignItems:'center',borderRadius:'150px'}} data-aos="fade-up">
                        <div class='px-3'>
                            <img style={{width:'45px'}} src={require('../Images/home/Calling.png')} alt="" />
                        </div>
                        <div>
                            <p class='text-secondary'>Customer Sales Enquiries</p>
                            <p class='fw-bold'>123 456 7890</p>
                        </div>
                    </div>

                    <div class='py-4 bg-light my-5 d-flex' style={{maxWidth:'450px',alignItems:'center',borderRadius:'150px'}} data-aos="fade-up">
                        <div class='px-3'>
                            <img style={{width:'50px',height:'100%'}} src={require('../Images/home/Mail.png')} alt="" />
                        </div>
                        <div>
                            <p class='text-secondary'>General Enquiries</p>
                            <p class='fw-bold'>care@Arish.com</p>
                        </div>
                    </div>
                </div>

            </div>
            <br />
        </div>

        <div style={{backgroundImage:`url(${require('../Images/home/ourNetwork.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}} data-aos="zoom-in-up">
            <br />
            <br />
     
            <p class='fs-1 fw-bold text-white ' data-aos="fade-up">Our Network</p>
            <div class='row mt-5 col-lg-8 m-auto '>
                <div class='col-md-6 text-start' data-aos="fade-up">
                    <p class='fs-5 fw-bold text-white'>Our company is highly reputed in the London Market where we source risk solutions from key players.</p>
                </div>
                <div class='col-md-6 text-start' data-aos="fade-up">
                    <br />
                    <br />
                    <p class='fs-5 text-white'>We have gained wide experience through the management and consulting in large risks over many years, thus placing us in a position to assist and provide unique service in Risk Management and Control. We combine our local knowledge and experience with a network of insurance providers in Africa and beyond to deliver solutions to our clients.</p>
                    <br />
                </div>
            </div>
        </div>

        <div class='ourNetwork'>
            <br />
            <p class='fs-1 fw-bold text-white' data-aos="fade-up">Our Insurance Partners</p>
            
            <div class='ourNetworkAdd mt-5 p-5 '  data-aos="zoom-in">
                {outNetwork.map((el)=>{
                    return(
                        <img class='ourNetworkImg' src={el.img} alt="" /> 
                    )
                })}
            </div>

            <div class='row gap-4 mt-5' style={{width:'90%',margin:'auto'}}>
                <div class='col-md-5 text-start' data-aos="fade-up">
                    <p class='fs-1 fw-bold text-white'>Seal of confidence! International Accreditation</p>
                </div>
                <div class='col-md-6 text-end gep-3'>
                    <button  class='btn rounded-5 ' style={{width:'200px'}}><img style={{width:'100%'}} src={require('../Images/home/ipb1.png')} alt="" data-aos="fade-up" /></button>
                    <button  class='btn rounded-5' style={{width:'200px'}}><img style={{width:'100%'}} src={require('../Images/home/ipb2.png')} alt="" data-aos="fade-up" /></button>
                    <div class='text-center' style={{width:'300px',height:'300px', borderRadius:'100px', backgroundImage:`url(${require('../Images/home/ipbbg.png')})`,backgroundSize:'100% 100%'}} data-aos="fade-up">
                        <p class='text-white p-5 fw-semibold'>The Only Chartered Insurance Brokers in mainland Africa. The Chartered Insurance Institute (CII) of London has awarded ARiS the prestigious ‘Chartered Insurance Brokers’ title.</p>
                    </div>
                    <br />
                </div>
            </div>

            <div style={{background:'white'}}>
                <div class='row gap-4' style={{width:'80%', margin:'auto',alignItems:'center',justifyContent:'center'}}>
                    <div class='col-md-5 text-start ' data-aos="fade-up">
                        <p class='fs-1 fw-bold mt-5'>Book an <br /> Appointment</p>
                        <button id='button'class='btn btn-danger p-5 py-3 fs-5 fw-bold mt-5 mb-5 rounded-5'>Book Now</button>
                    </div>
                    <div class='col-md-6 p-0' style={{maxWidth:'400px'}} data-aos="zoom-in">
                        <img class='mt-5' style={{width:'100%'}} src={require('../Images/home/bookAppointment.png')} alt="" />
                    </div>

                </div>

            </div>

        </div>

    </div>
    <Footer/>
    </>
  )
}

export default Home