import React, { useEffect, useState } from "react";
import "../Style.css";
import { IoNotifications } from "react-icons/io5";
import { FaQuestion, FaUserAlt, FaUserPlus } from "react-icons/fa";
import { BiArrowBack, BiCurrentLocation, BiLocationPlus } from "react-icons/bi";
import {  MdDangerous, MdEmail, MdHelp, MdMail, MdMale, MdMarkEmailRead, MdPolicy } from "react-icons/md";

import { IoIosHome } from "react-icons/io";
import { GoPrimitiveDot } from "react-icons/go";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import {
  MdLogout,
  MdNoteAdd,
  MdNoteAlt,
  MdSpeakerNotes,
  MdWifiCalling3,
} from "react-icons/md";
import {
  AiFillBook,
  AiFillFile,
  AiOutlineClose,
  AiOutlineDown,
  AiOutlineDownload,
  AiOutlineExclamationCircle,
  AiOutlineRight,
  AiOutlineUpload,
} from "react-icons/ai";
import { GiNotebook, GiStabbedNote, GiVikingLonghouse } from "react-icons/gi";
import axios from "axios";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from "react-scroll";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MapContainer from "./Maps";
import {
  FuneralTableBlack,
  FuneralTableBlue,
  FuneralTableMaroon,
} from "./FuneralTable";
import { BsJournalBookmark } from "react-icons/bs";

const API_endpoint = `https://api.openweathermap.org/data/3.0/onecall?lat=`;
const API_key = `AIzaSyAvzHK00m3gO1-hBanLOTHn9wNE_BUgdMw`;

 

const DashboardCopy = () => {
  const navigate = useNavigate();
  const [showNoti, setShowNoti] = useState(false);
  const [active, setActive] = useState(false);
  const userToken = localStorage.getItem("User_token");
  const [data , setData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [clientCode, setClientCode] = useState("");
  const [selectBorder, setSelectBorder] = useState("");
  let [loading, setLoading] = useState(false);
  const [discription, setDiscription] = useState();

  const [information, setInformation] = useState();
  // -----domstic section --------------------
  const [box, setBox] = useState("box0");
  const [dometicD, setDometicD] = useState({});
  const [domesticOffer, setDomesticOffer] = useState();

  const [period, setPeriod] = useState({});
  const [checkPeriod, setCheckPeriod] = useState({});

  const [mortgage, setMortgage] = useState(" ");
  const [bankName, setBankName] = useState();
  const [bankBranch, setBankBranch] = useState("");
  const [bankNo,setBankNo] = useState("")
  const [branchNameDp, setBranchNameDp] = useState("");
  const [owner ,setOwner] = useState('No')  

  // ---------------funeral ---------------------
  const [funeral, setFuneral] = useState("funeral0");
  const [funeralD, setFuneralD] = useState({});
  const [funeralPackeg, setFuneralPackeg] = useState();
  const [funeralPackegOffer, setFuneralPackegOffer] = useState("Parents");


  const [funeralPackegBlue, setFuneralPackegBlue] = useState("Parents+1");
  const [funeralPackegTitle, setFuneralPackegTitle] = useState(); 
  const [funeralPackegTable, setFuneralPackegTable] = useState()
  const [tableColor,setTableColor] = useState()
 

  const [funeralCard, setFuneralCard] = useState("");
  const [funeralDate, setFuneralDate] = useState("");
  const [funeralNote, setFuneralNote] = useState("");
  const [funeralOffer, setFuneralOffer] = useState("");
  // ---------joint education------------------------

  const [jointEducationD, setJointEducationD] = useState({});
  const [jointSchoolConDetils, setJointSchoolConDetils] = useState();
  const [jeBox, setJeBox] = useState("jeBox-1");
  const [jeStander, setJeStander] = useState("");
  const [jointEducationOffer, setJointEducationOffer] = useState();
  const [gardian1CNO, setGardion1CNO] = useState("");
  const [gardian2CNO, setGardion2CNO] = useState("");
  const [gardion1Cdetail, setGardion1Cdetail] = useState("");
  const [gardion2Cdetail, setGardion2Cdetail] = useState("");
  const [studentDob, setStudentDob] = useState(null)
  const [studentGar1, setStudentGar1] = useState(null) 

  const [studentGar2, setStudentGar2] = useState(null)
  const [gardianNida1,setGardianNida1] = useState(false) 
  const [gardianNida2,setGardianNida2] = useState(false)


  // ------------report a clim ----------------

  const [claimD, setClaimD] = useState({});
  const [reportToPolice, setReportToPolice] = useState("No");
  const [claimTypes, setClaimTypes] = useState("Select One");
  const [coverNote, setCoverNote] = useState("");
  const [vehNo, setVehNo] = useState("");
  const [policeCoverNote, setPoliceCoverNote] = useState("");
  const [policeVehNo, setPoliceVehNo] = useState("");
  const [claimType, setClaimType] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [position, setPosition] = useState();
  const [showMap, setShowMap] = useState(false);
  const [address, setAddress] = useState("");

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);


  const [numberrrrr,setNumberrrrr] = useState('')

  const today = new Date();
  const thirtyDaysAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 30
  ); 
 
  // -------------claim part end-----------

  const details = data?.data;


  const code = localStorage.getItem("clientCode");
  const navClientName = localStorage.getItem("clientName");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  // get mobile data
  const getMobileData = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getMobileData.php?accesstoken=${userToken}`
      )
      .then((response) => {
        if (response.data.success == true) {
          setData(response.data);
          setClientCode(response.data.default);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getMobileData();
  }, []);
  // -------------------------------
  // clientCode ----------------------

  const client = (clientCode) => {
    axios
      .get(
        `https://tzapis.aris-world.com/setDefaultClient.php?clientCode=${clientCode}&accesstoken=${userToken}`
      )
      .then((response) => {
      })
      .catch((error) => console.log("error", error));

    setClientCode(clientCode);
    navigate("/dashboard");
  };
  window.localStorage.setItem("clientCode", clientCode);

  // --------------------dashboard-----------

  const dashboard = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getClientData.php?clientCode=${code}&accesstoken=${userToken}&infoType=Dashboard`
      )
      .then((response) => {
        if (response.data.success == true) {
          setDashboardData(response.data.data);
          window.localStorage.setItem(
            "clientName",
            response.data.data?.[0]?.clientName
          );
          // getMobileData();
        }
        if( response.data.data?.[0]?.termsOfUse > 0){
          navigate('/terms-conditions')
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    // debugger
    setLoading(true);
    setTimeout(() => {
      dashboard();
      setLoading(false);
    }, 2000);
  }, [clientCode]);
  // --------------user card ----------

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  // ---------get Description of insurance ----------

 

  const getDescription = (number) => {
    axios
      .post(
        `https://tzapis.aris-world.com/getCoverDescription.php?accesstoken=${userToken}&covercode=${number}`
      )
      .then((response) => {
        setDiscription(response.data.data);  
        let map = {} 
        response?.data?.data?.Products.map((p)=>map[(p?.titleName )]= "c+2")
        setFuneralPackegTitle(map)
        
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      // .post(`https://hi-labsolution.org/aris/arisdp.php`)
      .post(`https://tzapis.aris-world.com/getSectionDescription.php?accesstoken=${userToken}&covercode=${number}`)

      .then((response) => {
        setInformation(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const domesticmaxDate = new Date();
  domesticmaxDate.setMonth(domesticmaxDate.getMonth() + 1);
  const domesticmaxDateformet = domesticmaxDate.toISOString().split('T')[0];

  const individualmaxDate = new Date();
  individualmaxDate.setMonth(individualmaxDate.getMonth() + 1);
  const individualmaxDateformet = individualmaxDate.toISOString().split('T')[0];

 

  const domesticDetails = (e) => {
    const { name, value } = e.target;
    setDometicD({ ...dometicD, [name]: value });
  };


  
 

  
  const bankBrancheDetails = (el, no, bname) => {
    setBankBranch(bname);
    setBankNo(no)
  };

useEffect(()=>{
  const domesticValue = { ...dometicD };
  domesticValue.Mortgage = mortgage;
  domesticValue.BankNo = bankNo;
  domesticValue.BankBranch = bankBranch+" - "+branchNameDp; 
  setDometicD(domesticValue);
},[mortgage,bankNo,branchNameDp])
 


  const domesticSubmit = (el) => {
    if (
      // dometicD?.latitude &&
      // dometicD?.latitude != "" &&
      // dometicD?.longitude &&
      // dometicD?.longitude != "" &&
      dometicD?.autoaddress &&
      dometicD?.autoaddress != "" &&
      dometicD?.flatno &&
      dometicD?.flatno != "" &&
      dometicD?.street &&
      dometicD?.street != "" &&
      dometicD?.city &&
      dometicD?.city != "" &&
      dometicD?.landmark &&
      dometicD?.landmark != "" &&
      dometicD?.SectionA &&
      dometicD?.SectionA != "" &&
      // dometicD?.SectionA1 &&
      // dometicD?.SectionA1 != "" &&
      dometicD?.SectionB &&
      dometicD?.SectionB != "" &&
      dometicD?.SectionC &&
      dometicD?.SectionC != "" &&
      dometicD?.SectionD &&
      dometicD?.SectionD != "" &&
      // dometicD?.SectionE &&
      // dometicD?.SectionE != "" &&
      // dometicD?.SectionF &&
      // dometicD?.SectionF != "" &&
      dometicD?.SectionG &&
      dometicD?.SectionG != "" &&
      dometicD?.SectionI &&
      dometicD?.SectionI != "" &&
      dometicD?.DomesticHelp &&
      dometicD?.DomesticHelp != "" &&
      dometicD?.startdate &&
      dometicD?.startdate != "" &&
      dometicD?.snote &&
      dometicD?.snote != "" &&
      dometicD?.AccessToken &&
      dometicD?.AccessToken != ""
    ) {
      axios
        .post(`https://tzapis.aris-world.com/calculatePremium1.php`, dometicD)
        .then((response) => {
          if (response.data.success == true) {
            const map = {};
            setBox(el);
            setDomesticOffer(response.data.data);
            response.data.data.forEach((el, i) => {
              map[i] = "year";
            });
            setPeriod(map);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${response.data.message}`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All Fields Are Required`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const OfferPayment = (el) => {
    window.sessionStorage.setItem("quoteid", el.quoteid);
    axios
      .post("https://tzapis.aris-world.com/sendDPkgUpdates.php", el)
      .then((response) => {
        if (response.data.success == true) {
          let url = response.data.data;
          window.open(url);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const claimDetails = (e) => {
    const { name, value } = e.target;
    setClaimD({ ...claimD, [name]: value });
  };

  const findLocation = () => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position?.lat},${position?.lng}&key=${API_key}`
      )
      .then((res) => {
        setAddress(res.data.results[5].formatted_address);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    findLocation();
  }, [position]);

  useEffect(() => {
    const addressDetail = { ...claimD };
    addressDetail.autoaddress = address;
    addressDetail.latitude = position?.lat;
    addressDetail.longitude = position?.lng;
    addressDetail.AccessToken = userToken;

    setClaimD(addressDetail);
    // setDometicD(addressDetail)
  }, [position, address, userToken]);

  useEffect(() => {
    const addressDetail = { ...dometicD };
    addressDetail.autoaddress = address;
    addressDetail.latitude = position?.lat;
    addressDetail.longitude = position?.lng;
    addressDetail.AccessToken = userToken;
    setDometicD(addressDetail);
  }, [position, address, userToken]);

  useEffect(() => {
    const police = { ...claimD };
    police.reportToPolice = reportToPolice;
    police.claimType = claimTypes;
    setClaimD(police);
  }, [reportToPolice, claimTypes]);

  useEffect(() => {
    const date = { ...claimD };
    date.incidentDate = selectedDate;
    setClaimD(date);
  }, [selectedDate]);

  useEffect(() => {
    const report = { ...claimD };
    report.CoverNoteNo = policeCoverNote;
    report.vehRegNo = policeVehNo;
    setClaimD(report);
  }, [policeCoverNote, policeVehNo]);

  useEffect(()=>{ 
      axios
        .post(
          `https://tzapis.aris-world.com/getClientData.php?accesstoken=${userToken}&infoType=Banks`
        )
        .then((response) => {
          setBankName(response.data.data);
        })
        .catch((error) => console.log(error));
  
  },[mortgage])



  const claimCoverNote = () => {
    axios
      .get(
        `https://tzapis.aris-world.com/getClientData.php?clientCode=${code}&accesstoken=${userToken}&infoType=Vehicles`
      )
      .then((response) => {
        setCoverNote(response.data.data[0]?.CoverNote);
        setVehNo(response.data.data[0]?.VehRegNo);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    claimCoverNote();
  }, [clientCode]);

  const policeCover = () => {
    let arr = [];
    for (let i = 0; i < coverNote?.length; i++) {
      arr.push(
        <div
          data-bs-target="#reportCoverNo"
          data-bs-toggle="collapse"
          onClick={() => setPoliceCoverNote(`${coverNote[i].Number}`)}
        >
          <p>{coverNote[i]?.Description}</p>
        </div>
      );
    }
    return arr;
  };

  const policeVehical = () => {
    let arr = [];
    for (let i = 0; i < vehNo?.length; i++) {
      arr.push(
        <div
          data-bs-target="#reportVehNo"
          data-bs-toggle="collapse"
          onClick={() => setPoliceVehNo(`${vehNo[i]}`)}
        >
          <p>{vehNo[i]}</p>
        </div>
      );
    }
    return arr;
  };

  const submilClaimReport = () => {
    if (
      claimD?.latitude &&
      claimD?.latitude != "" &&
      claimD?.longitude &&
      claimD?.longitude != "" &&
      claimD?.autoaddress &&
      claimD?.autoaddress != "" &&
      claimD?.incidentDate &&
      claimD?.incidentDate != "" &&
      claimD?.incidentDetails &&
      claimD?.incidentDetails != "" &&
      claimD?.inspectionLocation &&
      claimD?.inspectionLocation != "" &&
      claimD?.reportToPolice &&
      claimD?.reportToPolice != "" &&
      claimD?.claimType &&
      claimD?.claimType != "" &&
      claimD?.AccessToken &&
      claimD?.AccessToken != "" &&
      ((claimD?.vehRegNo && claimD?.vehRegNo != "") ||
        (claimD?.CoverNoteNo && claimD?.CoverNoteNo != ""))
    ) {
      axios
        .post(`https://tzapis.aris-world.com/sendClaimNotification.php`, claimD)
        .then((response) => {
          if (response.data.success == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${response.data.message}`, 
            });
            setClaimD({
              'autoaddress': "",
              'incidentDate': "",
              'incidentDetails': "",
              'inspectionLocation': "",
              'reportToPolice': "",
              'claimType': "",
              'AccessToken': "",
              'vehRegNo': "",
              'CoverNoteNo': "",
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${response.data.message}`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All Fields Are Required `,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  // const studentMinDate = new Date();
  // studentMinDate.setFullYear(studentMinDate.getFullYear()-3);

  const currentDate = new Date();

  const startMaxDate = new Date(currentDate);
  startMaxDate.setMonth(startMaxDate.getMonth() - 1); 

  const StartFormattedMinDate = startMaxDate.toISOString().split('T')[0]; 

  const studentMinDate = new Date(currentDate);
  studentMinDate.setFullYear(studentMinDate.getFullYear() - 30);

  const studentMaxDate = new Date(currentDate);
  studentMaxDate.setFullYear(studentMaxDate.getFullYear() - 5); 

  const studentFormattedMinDate = studentMinDate.toISOString().split('T')[0];
  const studentFormattedMaxDate = studentMaxDate.toISOString().split('T')[0];

  const studentGarMinDate = new Date();
  studentGarMinDate.setFullYear(studentGarMinDate.getFullYear()-18);


  const jointEducationDetils = (e) => {
    const { name, value } = e.target;
    setJointEducationD({ ...jointEducationD, [name]: value });
  };
  useEffect(() => {
    const standard = { ...jointEducationD };
    standard.Standard = jeStander;
    standard.AccessToken = userToken;
    standard.SchoolContact = 255 + jointSchoolConDetils;
    standard.Guardian1ID = gardian1CNO + " " + gardion1Cdetail;
    standard.Guardian2ID = gardian2CNO + " " + gardion2Cdetail;
    // standard.StudentDOB =  studentDob
    standard.Guardian1DOB = studentGar1
    standard.Guardian2DOB = studentGar2
// rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr

    setJointEducationD(standard);
  }, [jeStander, jointSchoolConDetils, gardion1Cdetail, gardion2Cdetail,studentGar1,studentGar2]);

 



  const velidationNida=(el)=>{
    var month = el?.getUTCMonth()+1 //months from 1-12
    var day = el?.getUTCDate()+1;
    var year = el?.getUTCFullYear();
    if(`${month}`.length == 1){
      month = "0"+month
    }
    if(`${day}`.length == 1){
      day = "0"+day
    }
     
    const newdate = year +""+ month +""+day;
    return newdate
  }
  const gardian1Dob = velidationNida(jointEducationD?.Guardian1DOB)
  const gardian2Dob = velidationNida(jointEducationD?.Guardian2DOB)
 
 
  
  
  useEffect(()=>{
    if(gardian1Dob == gardion1Cdetail.toLocaleString(0,6) ){
      setGardianNida1(true)
    }else{
      setGardianNida1(false) 
    }
    if(gardian2Dob == gardion2Cdetail.toLocaleString(0,6) ){
      setGardianNida2(true)
    }else{
      setGardianNida2(false) 
    }
  },[gardion1Cdetail,gardion2Cdetail,gardian1Dob,gardian2Dob])

 

 

function calculateAge(inputDate) {
  // Convert the input date to a JavaScript Date object
  const date = new Date(inputDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years
  const age = currentDate.getFullYear() - date.getFullYear();

  // Check if the birthday has occurred this year
  if (
      currentDate.getMonth() < date.getMonth() ||
      (currentDate.getMonth() === date.getMonth() && currentDate.getDate() < date.getDate())
  ) {
      // If not, subtract 1 from the age
      return age - 1;
  }

  return age;
}


const studentAge = new Date(jointEducationD ?.StudentDOB);
const studentGuardian1Age = new Date(jointEducationD ?.Guardian1DOB); 
const studentGuardian2Age = new Date(jointEducationD ?.Guardian2DOB);

const findStudentAge = calculateAge(studentAge) 
const findStudentGuardian1Age = calculateAge(studentGuardian1Age) 
const findStudentGuardian2Age = calculateAge(studentGuardian2Age)

 



  const submitJointEducationD = (e) => {
    if (
      jointEducationD?.SchoolName &&
      jointEducationD?.SchoolName != "" &&
      jointEducationD?.Standard &&
      jointEducationD?.Standard != "" &&
      jointEducationD?.StudentName &&
      jointEducationD?.StudentName != "" &&
      jointEducationD?.SchoolFees &&
      jointEducationD?.SchoolFees != "" &&
      jointEducationD?.Guardian1 &&
      jointEducationD?.Guardian1 != "" &&
      jointEducationD?.Guardian2 &&
      jointEducationD?.Guardian2 != "" &&
      jointEducationD?.snote &&
      jointEducationD?.snote != "" &&
      jointEducationD?.StudentDOB &&
      jointEducationD?.StudentDOB != "" &&
      jointEducationD?.Guardian1DOB &&
      jointEducationD?.Guardian1DOB != "" &&
      jointEducationD?.Guardian1ID &&
      jointEducationD?.Guardian1ID != "" &&
      jointEducationD?.Guardian2DOB &&
      jointEducationD?.Guardian2DOB != "" &&
      jointEducationD?.Guardian2ID &&
      jointEducationD?.Guardian2ID != "" &&
      jointEducationD?.SchoolContact &&
      jointEducationD?.SchoolContact != ""
    ) {
      if((findStudentAge < findStudentGuardian1Age) && (findStudentAge < findStudentGuardian2Age)){ 
        axios
          .post(
            `https://tzapis.aris-world.com/calculatePremium7.php`,
            jointEducationD
          )
          .then((response) => {
            if (response.data.success == true) {
              setJeBox(e);
              setJointEducationOffer(response.data.data);
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${response.data.message}`,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }else{
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Parent's age is not correct!`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All Fields Are Required`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const funeralDetails = (next,table,thColor) => {
    setFuneral(next); 
    setFuneralPackegTable(table)
    setTableColor(thColor)
  };


 
   

 

  useEffect(() => {
    const funeral = { ...funeralD };

    funeral.BenefitOption = funeralPackeg;
    funeral.PremiumType = funeralPackegOffer; 
    funeral.startdate = funeralDate;
    funeral.snote = funeralNote;
    funeral.AccessToken = userToken;
    setFuneralD(funeral); 
  }, [funeralPackeg, funeralPackegOffer, funeralDate, funeralNote, userToken]);

 

  

  const funeralPackegSubmit = () => {
    if (
      funeralD.BenefitOption &&
      funeralD.BenefitOption != "" &&
      funeralD.PremiumType &&
      funeralD.PremiumType != "" &&
      funeralD.startdate &&
      funeralD.startdate != "" &&
      funeralD.snote &&
      funeralD.snote != "" &&
      funeralD.AccessToken &&
      funeralD.AccessToken != ""
    ) {
      axios
        .post(`https://tzapis.aris-world.com/calculatePremium6.php`, funeralD)
        .then((response) => { 
          if (response.data.success == true) {
            setFuneral("funeral4");
            setFuneralOffer(response.data.data);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${response.data.message}`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All Fields Are Required`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };


  const highlite = () =>{
    const text= document.querySelector('.myPolicyss')
    text.classList.add('hover')
    setTimeout(()=>{
    text.classList.remove('hover') 
    },1000) 
  }

 
 

  const individualCart = () =>{
    let arr = []
    for(let i = 0 ; i < discription?.Products?.length ; i++){ 
      arr.push(
        <div
        class="bg-light mt-3 row "
        style={{
          boxShadow:
          "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
          borderRadius: "20.28px",
        }}
        onClick={() => { 
          setFuneralPackeg(`${discription?.Products?.[i]?.titleName}`)
          const child =  funeralPackegTitle[discription?.Products?.[i]?.titleName]
          if(child == "c+1"){
            setFuneralPackegOffer(discription?.Products?.[i]?.plan1Caption)
          }else{
            setFuneralPackegOffer(discription?.Products?.[i]?.plan2Caption)

          }
        }}
      >
        <div class="p-2 px-3">
          <div class="d-flex justify-content-between">
            <div>
              <p
                class="fs-3 fw-semibold"
                style={{ color: `${discription?.Products?.[i]?.titleColorCode}` }}
              >
               {discription?.Products?.[i]?.titleName}
              </p>
            </div>
            <div>
              <GoPrimitiveDot
                class="fs-3"
                style={
                  funeralPackeg == `${discription?.Products?.[i]?.titleName}`
                    ? { color: "red" }
                    : { color: "gray" }
                }
              />
            </div>
          </div>
          <p class="fw-semibold">
            Benefits: Coverage upto - {discription?.Products?.[i]?.benefitsUpto}
          </p>
        </div>
        <hr class="p-0 m-0" />
        <div class="row">
          <div class="col-6 p-2 g-grid align-items-center justify-content-center">
            <p
              class="text-center"
              onClick={() =>{
                funeralPackegTitle[discription?.Products?.[i]?.titleName] = "c+1"
                setFuneralPackegBlue(funeralPackegTitle)
              
              }}
              style={
                "c+1" ==  funeralPackegTitle[discription?.Products?.[i]?.titleName]
                  ? {
                      background: "#FFEFEF",
                      cursor: "pointer",
                      padding: "2px 5px",
                      borderRadius: "10px",
                      border: "2px solid red",
                      color: "red",
                      fontWeight: "700",
                    }
                  : {
                      cursor: "pointer",
                      background: "#f0f0f0",
                      padding: "2px 5px",
                      borderRadius: "10px",
                    }
              }
            >
             {discription?.Products?.[i]?.plan1Amount}
            </p>
            <p
              class="text-center"
              style={{ color: "gray", fontSize: "12px" }}
            >
             {discription?.Products?.[i]?.plan1Text}
            </p>
          </div>
          <div class="col-6 p-2 g-grid align-items-center justify-content-center">
            <p
              class="text-center"
              onClick={() =>{
                funeralPackegTitle[discription?.Products?.[i]?.titleName] = "c+2" 
                setFuneralPackegBlue(funeralPackegTitle)  
              }}
              style={ 
                "c+2" ==  funeralPackegTitle[discription?.Products?.[i]?.titleName]
                  ? {
                      background: "#FFEFEF",
                      cursor: "pointer",
                      padding: "2px 5px",
                      borderRadius: "10px",
                      border: "2px solid red",
                      color: "red",
                      fontWeight: "700",
                    }
                  : {
                      cursor: "pointer",
                      background: "#f0f0f0",
                      padding: "2px 5px",
                      borderRadius: "10px",
                    }
              }
            >
              {discription?.Products?.[i]?.plan2Amount}
            </p>
            <p
              class="text-center"
              style={{ color: "gray", fontSize: "12px" }}
            >
              {discription?.Products?.[i].plan2Text}
            </p>
          </div>
        </div>
        <hr class="p-0 m-0" />

        <div class="text-end p-0">
          {/* <br /> */}
          <button
            class="btn  mt-3 text-white px-4"
            id="button"
            style={{ borderRadius: "20px 0px 20px 20px" }}
            onClick={() =>
              funeralDetails(
                "funeral2",  
                discription?.Products?.[i]?.Tables,
                discription?.Products?.[i].titleColorCode

              )
            }
          >
            View Details
          </button>
        </div>
      </div>
      )
    }

    return arr;
  }

  return (
    <>
    {!userToken ? navigate('/log-in'):
    
    <div
      style={{
        backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight: "100vh",
      }}
    >
      <br />
      <nav
        class="d-flex"
        style={{
          background: "red",
          borderRadius: "50px",
          margin: "0 10px",
          height: "98px",
        }}
      >
        <div
          class="d-flex justify-content-between align-items-center"
          style={{ width: "95%", margin: "auto" }}
        >
          <div class="text-start d-flex align-items-center gap-3">
            <div>
              <img
                style={{ height: "60px" }}
                src={require("../../Images/home/aris-w-logo.png")}
                alt=""
              />
            </div>
            <div>
              <p class="text-white" style={{ fontWeight: "12px" }}>
                Welcome
              </p>
              <p class="text-white fw-bold userHName">{navClientName}</p>
            </div>
          </div>
          <div class="d-flex">
            <div class="btn-group dropstart">
              <div
                class="rounded-5"
                type="button"
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
                // onClick={() => setShowNoti(!showNoti)}
              >
                <IoNotifications class="fs-2" />
              </div>
              {!showNoti ? (
                ""
              ) : (
                <ul
                  class="text-start px-3 bg-light rounded"
                  style={{
                    minWidth: "300px",
                    position: "absolute",
                    left: "-200px",
                    top: "50px",
                    zIndex: "999",
                    listStyle: "none",
                  }}
                >
                  <p class="fs-5 fw-bold">Notifications</p>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">Claim Accepted</p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">
                          Policy No- XXXX45241 renewal has been due on 12-Apr
                          2019
                        </p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">Claim accepted by Insurer</p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowNoti(!showNoti)}
                  >
                    <div
                      class="d-flex gap-2 align-items-center"
                      style={{ borderBottom: "1px solid" }}
                    >
                      <div>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={require("../../Images/dashoard/notif.png")}
                          alt=""
                        />
                      </div>
                      <div>
                        <p class="fw-bold">
                          Claim amount has been transferred to customer account
                        </p>
                        <p style={{ fontSize: "12px" }}>1h57min ago</p>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div
              class="btn-group mx-2 "
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <div
                class="dropdown rounded-5"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  borderRadius: "50px",
                  background: "white",
                  padding: "10px",
                }}
              >
                <FaUserAlt class="fs-2" />
              </div>
              <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                  <div style={{ width: "100%" }}>
                    <p
                      class="offcanvas-title fs-4 fw-bold"
                      id="offcanvasRightLabel"
                    >
                      Switch Account
                    </p>
                  </div>
                </div>
                <div class="offcanvas-body">
                  <div
                    style={{
                      display: "grid",
                      background: "rgb(230 230 230)",
                      height: "auto",
                      gap: "1px",
                    }}
                  >
                    {details?.map((el) => {
                      const sortName = el.clientName
                        .split(" ")
                        .map((el) => el[0]);
                      const sn = sortName.join("");
                      let pn = "";
                      for (let i = 0; i < sn.length; i++) {
                        if (i < 2) {
                          pn += sn[i];
                        }
                      }

                      return (
                        <div
                          class="d-flex justify-content-between p-2 userCard"
                          style={{ background: "white" }}
                          onClick={() => client(el.clientCode)}
                        >
                          <div class="d-flex gap-3">
                            <div>
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50px",
                                  background: `#${Math.floor(
                                    Math.random() * 16777215
                                  ).toString(16)}`,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p class="fs-5 text-white">{pn}</p>
                              </div>
                            </div>
                            <div class="text-start">
                              <p class="fw-bold">{el.clientName}</p>
                              {/* <p
                                class="text-secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {el.clientCode}
                              </p> */}
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <input
                              class="form-check-input radio-danger"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked={code == el.clientCode}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {
                    data?.canAddAccount == "Yes" ?
                  <button 
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/add-account")}
                  >
                    <span>
                      <FaUserPlus class="fs-5 mx-2" /> <span>Add Account</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                  :
                  ""
                  }

                  {/* <button
                    id="button2"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                    onClick={() => navigate("/file-upload")}
                  >
                    <span>
                      <AiFillFile class="fs-5 mx-2" /> <span>Upload files</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button> */}

                  <button
                    id="button2LogOut"
                    class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                    onClick={logOut}
                  >
                    <span>
                      <MdLogout class="fs-5 mx-2" /> <span>Log-Out</span>{" "}
                    </span>
                    <span>
                      <AiOutlineRight />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div
              class="rounded-5"
              type="button"
              style={{
                borderRadius: "50px",
                background: "white",
                padding: "10px",
              }}
              onClick={() => navigate("/dashboard")}
            >
              <IoIosHome class="fs-2" />
            </div>
          </div>
        </div>
      </nav>
      <SyncLoader
        color="red"
        loading={loading}
        // cssOverride={override}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
        style={{
          position: "absolute",
          left: "0%",
          top: "50%",
          width: "100%",
          zIndex: "999999",
        }}
      />

      <div class="row g-0">
        {/* ----------- start left section ---------- */}
        <div class="col-lg-5" style={{ borderRight: "gray 1px solid" }}>
          <div class="p-3">
            <p class="text-light fw-semibold fs-5 text-start m-4">
              Manage Your Insurance
            </p>
            <div
              class="gap-4"
              style={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                justifyContent: "center",
              }}
            >
              <div class="circulCard" onClick={() => navigate("/renewals")}>
                <p class="fs-4 fw-bold text-white">
                  {dashboardData?.[0]?.clientRenewals}
                </p>
                <p class="text-white">Expiring</p>
              </div>
              <div class="circulCard " onClick={() => navigate("/my-policies")}>
                <p class="fs-4 fw-bold text-white">
                  {dashboardData?.[0]?.clientCovers}
                </p>
                <p class="text-white">Policies</p>
              </div>

              <div class="circulCard " onClick={() => navigate("/claims")}>
                <p class="fs-4 fw-bold text-white">
                  {dashboardData?.[0]?.clientClaims}
                </p>
                <p class="text-white">Claims</p>
              </div> 
                <div class="circulCard  " onClick={() => navigate("/in-progress")}>
                  <p class="fs-4 fw-bold text-white">{dashboardData?.[0]?.clientProgress}</p>
                  <p class="text-white">In Progress</p>
                </div>
            
            </div>
          </div>
          <br />
          <div class="bg-light">
            <div class="p-3">
              <p class="fw-bold">Help</p>
              <Link
                onClick={highlite}
                activeClass="active"
                to="myPolicyss"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                style={{textDecoration:'none'}}
              >
              <button
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                type="button"
                
              >
                <span>
                  <MdPolicy class="fs-5 mx-2" /> <span>Buy Policy</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </button>
              </Link>
              <button
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#callNow"
                aria-controls="callNow"
              >
                <span>
                  <MdWifiCalling3 class="fs-5 mx-2" /> <span>Call Now</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </button>

              <a
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-3 "
                type="button"
                href={`mailto:${dashboardData?.[0]?.HandlerEmail}`}
              >
                
                <span>
                  < MdMail class="fs-5 mx-2" /> <span>Email Now</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </a>
              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="callNow"
                aria-labelledby="callNowLabel"
                style={{
                  backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 110%",
                }}
              >
                <div class="offcanvas-header">
                  <p class="text-white fs-4 fw-bold" id="offcanvasExampleLabel">
                    Call Now
                  </p>
                  <button
                    type="button"
                    class="btn text-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <AiOutlineClose class="fw-bold fs-3" />
                  </button>
                </div>
                <div class="offcanvas-body">
                  <div style={{ width: "70%", margin: "auto" }}>
                    {/* --------- */}

                    {!dashboardData?.[0]?.AccHandlerMobile ? (
                      ""
                    ) : (
                      <div>
                        <p class="text-secondary text-start p-2 mt-4">
                          {/* {dashboardData?.[0]?.AccHandler} */}
                        </p>
                        <a
                          id="button2"
                          class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center  "
                          href={`tel:${dashboardData?.[0]?.AccHandlerMobile}`}
                        >
                          <span>
                            <MdWifiCalling3 class="fs-5 mx-2" />{" "}
                            <span>{dashboardData?.[0]?.AccHandler}</span>{" "}
                          </span>
                          <span>
                            <AiOutlineRight />
                          </span>
                        </a>
                      </div>
                    )}
                    {/* ---------- */}

                    {!dashboardData?.[0]?.AccManagerMobile ? (
                      ""
                    ) : (
                      <div>
                        <p class="text-secondary text-start p-2 mt-4">
                          {/* {dashboardData?.[0]?.AccManager} */}
                        </p>
                        <a
                          id="button2"
                          class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center  "
                          href={`tel:${dashboardData?.[0]?.AccManagerMobile}`}
                        >
                          <span>
                            <MdWifiCalling3 class="fs-5 mx-2" />{" "}
                            <span>{dashboardData?.[0]?.AccManager}</span>{" "}
                          </span>
                          <span>
                            <AiOutlineRight />
                          </span>
                        </a>
                      </div>
                    )}
                    {/* ------- */}

                    {!dashboardData?.[0]?.GeneralLineNo ? (
                      ""
                    ) : (
                      <div>
                        <p class="text-secondary text-start p-2 mt-4">
                          {/* {dashboardData?.[0]?.GeneralLine} */}
                        </p>
                        <a
                          id="button2"
                          class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center  "
                          href={`tel:${dashboardData?.[0]?.GeneralLineNo}`}
                        >
                          <span>
                            <MdWifiCalling3 class="fs-5 mx-2" />{" "}
                            <span>{dashboardData?.[0]?.GeneralLine}</span>{" "}
                          </span>
                          <span>
                            <AiOutlineRight />
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#reportAClaim"
                aria-controls="reportAClaim"
              >
                <span>
                  <MdNoteAlt class="fs-5 mx-2" /> <span>Report a Claim</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </button>

              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="reportAClaim"
                aria-labelledby="reportAClaimLabel"
                style={{
                  backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 110%",
                }}
              >
                <div class="offcanvas-header">
                  <p class="text-white fs-4 fw-bold" id="offcanvasExampleLabel">
                    Report A Claim
                  </p>
                  <button
                    type="button"
                    class="btn text-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <AiOutlineClose class="fw-bold fs-3" />
                  </button>
                </div>
                <div class="offcanvas-body text-start">
                  {/* <Map details={setLocation}/> */}

                  {/* <input id="contactInputForm" class="form-control p-2 px-4 my-3" type="text" name="CoverNoteNo" placeholder="Cover Note No." onChange={claimDetails} /> */}
                  <div class="d-flex my-3">
                  <div class="input-group mb-3 ">
                    <input
                      id="numContactInputForm"
                      style={{ borderRadius: " 50px 0px 0px 50px " }}
                      class="form-control p-2 px-4  m-0"
                      type="text"
                      name="autoaddress"
                      placeholder="Location"
                      value={claimD?.autoaddress}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <span
                      class="input-group-text "
                      id="numContactInputForm"
                      style={{ borderRadius: "0px 50px 50px 0px" }}
                    >
                      <BiCurrentLocation
                        onClick={() => setShowMap(!showMap)}
                        style={{
                          color: "rgb(147, 144, 144)",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </div>
                  <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                  {!showMap ? (
                    ""
                  ) : (
                    <div class="mb-3">
                      <div class="text-end">
                        <button
                          class="btn fs-4 text-white m-0 p-0"
                          onClick={() => setShowMap(!showMap)}
                        >
                          <AiOutlineClose />
                        </button>
                      </div>
                      <MapContainer
                        data={setPosition}
                        lat={latitude}
                        lng={longitude}
                      />
                    </div>
                  )}
                  <div class="d-flex gap-4">
                    <div class="d-flex gap-3 align-items-center">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radio"
                          id="CoverNoteNo"
                          onClick={() => setClaimType("coverNote")}
                        />
                        <label class="form-check-label text-light" for=" ">
                          Cover Note No.
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radio"
                          id=" VehRegNo"
                          onClick={() => setClaimType("vehNo")}
                        />
                        <label class="form-check-label text-light" for=" ">
                          Veh. Reg. No.
                        </label>
                      </div>
                    </div>
                  </div>
                  {claimType == "coverNote" ? (
                    <div class="text-start mt-3">
                      <label
                        class="form-check-label text-light text-start m-0 px-3"
                        for=" "
                      >
                        Cover Note No.
                      </label>
                      <div class="d-flex">
                      <div
                        id="contactInputForm"
                        class="form-control p-2 px-4 mt-0  mb-0 d-flex justify-content-between"
                        data-bs-toggle="collapse"
                        href="#reportCoverNo"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        style={{   }}
                      >
                        <p style={{ color: "rgb(147, 144, 144)" }}>
                        {claimD?.CoverNoteNo}
                        </p>
                        <p style={{ color: "rgb(147, 144, 144)" }}>
                          <AiOutlineDown />
                        </p>
                      </div>
                      <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                      <div class="collapse" id="reportCoverNo">
                        <div class="card card-body reportPolice text-start">
                          {policeCover()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {claimType == "vehNo" ? (
                    <div class="text-start mt-3">
                      <label
                        class="form-check-label text-light text-start m-0 px-3"
                        for=" "
                      >
                        Veh. Reg. No.
                      </label>
                      <div class="d-flex">
                      <div
                        id="contactInputForm"
                        class="form-control p-2 px-4 mt-0  mb-0 d-flex justify-content-between"
                        data-bs-toggle="collapse"
                        href="#reportVehNo"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        style={{  }}
                      >
                        <p style={{ color: "rgb(147, 144, 144)" }}>
                        {claimD?.vehRegNo}
                        </p>
                        <p style={{ color: "rgb(147, 144, 144)" }}>
                          <AiOutlineDown />
                        </p>
                      </div>
                      <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                      <div class="collapse" id="reportVehNo">
                        <div class="card card-body reportPolice text-start">
                          {policeVehical()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <label
                    class="form-check-label text-light text-start mt-3 px-3"
                    for=" "
                  >
                    Incident Date
                  </label>
                  <div class="d-flex">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    minDate={thirtyDaysAgo}
                    maxDate={today}
                    showTimeSelect={false}
                    style={{zIndex:'9999'}}

                  />
                         <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                  {/* <input id="datepicker" class="form-control p-2 px-4 my-3" type="date" name="incidentDate" placeholder="Inciden Date" onChange={claimDetails} /> */}
                  <div class="d-flex my-3">
                  <input
                    id="contactInputForm"
                    class="form-control p-2 px-4 m-0"
                    type="text"
                    name="incidentDetails"
                    placeholder="Incident Details"
                    minLength={20}
                    value={claimD?.incidentDetails}
                    onChange={claimDetails}
                  />
                   <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                  <p
                    class="text-danger text-start px-3 fw-semibold"
                    style={
                      claimD?.incidentDetails?.length >= "20"
                        ? { display: "none" }
                        : {
                            marginTop: "-15px",
                            display: "block",
                            fontSize: "14px",
                          }
                    }
                  >
                    Provide Details - Minimum 20 charactor Required
                  </p>
                  <div class="d-flex my-3"> 
                  <input
                    id="contactInputForm"
                    class="form-control p-2 px-4 m-0"
                    type="text"
                    name="inspectionLocation"
                    placeholder="Inspection Location"
                    value={claimD?.inspectionLocation}
                    onChange={claimDetails}
                  />
                      <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                  {/* <input id="contactInputForm" class="form-control p-2 px-4 my-3" type="text" name="vehRegNo" placeholder="Veh. Reg. No." onChange={claimDetails} /> */}
                  {/* <div
                    id="contactInputForm"
                    class="form-control p-2 px-4 my-3 mb-0 d-flex justify-content-between"
                    data-bs-toggle="collapse"
                    href="#reportPolice"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    style={{ zIndex: "999999999999" }}
                  >
                    <p style={{ color: "rgb(147, 144, 144)" }}>
                      {reportToPolice}
                    </p>
                    <p style={{ color: "rgb(147, 144, 144)" }}>
                      <AiOutlineDown />
                    </p>
                  </div>
                  <div class="collapse" id="reportPolice">
                    <div class="card card-body reportPolice text-start">
                      <div
                        data-bs-target="#reportPolice"
                        data-bs-toggle="collapse"
                        onClick={() => setReportToPolice("Yes")}
                      >
                        <p>Yes</p>
                      </div>
                      <div
                        data-bs-target="#reportPolice"
                        data-bs-toggle="collapse"
                        onClick={() => setReportToPolice("No")}
                      >
                        <p>No</p>
                      </div>
                    </div>
                  </div> */}

                  <div class="d-flex gap-3 mt-3 mx-3">
                    <div>
                      <p class="text-white"> Reported to Police?</p>
                    </div>
                    <div>
                      <div
                        class="d-flex  justify-content-between gap-1 text-white"
                        style={{
                          borderRadius: "150px",
                          Color: "white",
                          background: "#484C55",
                          border: "1.3px solid #494E59",
                          boxShadow:
                            "0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <div
                          class="px-2"
                          style={
                            reportToPolice == "Yes"
                              ? {
                                  borderRadius: "50px",
                                  background: "red",
                                  cursor: "pointer",
                                }
                              : { cursor: "pointer" }
                          }
                          onClick={() => setReportToPolice("Yes")}
                        >
                          Yes
                        </div>
                        <div
                          class="px-2"
                          style={
                            reportToPolice == "No"
                              ? {
                                  borderRadius: "50px",
                                  background: "red",
                                  cursor: "pointer",
                                }
                              : { cursor: "pointer" }
                          }
                          onClick={() => setReportToPolice("No")}
                        >
                          No
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* --claim-- */}
                  <div class="d-flex my-3"> 
                  <div
                    id="contactInputForm"
                    class="form-control p-2 px-4 m-0 mb-0 d-flex justify-content-between"
                    data-bs-toggle="collapse"
                    href="#claimType"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  
                  >
                    <p style={{ color: "white" }}>{claimD?.claimType}</p>
                    <p style={{ color: "rgb(147, 144, 144)" }}>
                      <AiOutlineDown />
                    </p>
                  </div>
                  <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                  <div class="collapse" id="claimType">
                    <div class="card card-body claimType text-start">
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() => setClaimTypes("Accident")}
                      >
                        <p>Accident</p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() =>
                          setClaimTypes("Third Party Property Damage")
                        }
                      >
                        <p>Third Party Property Damage</p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() =>
                          setClaimTypes("Third Party/Pedestrian Bodily Injury")
                        }
                      >
                        <p>Third Party / Pedestrian Bodily Injury</p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() => setClaimTypes("Passenger Bodily Injury")}
                      >
                        <p>Passenger Bodily Injury</p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() =>
                          setClaimTypes("Rider Bodily Injury/Medical Expenses")
                        }
                      >
                        <p>Rider Bodily Injury / Medical Expenses </p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() => setClaimTypes("Rifer Death Benefit")}
                      >
                        <p>Rifer Death Benefit</p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() => setClaimTypes("Rider Burial Expenses")}
                      >
                        <p>Rider Burial Expenses</p>
                      </div>
                      <div
                        data-bs-target="#claimType"
                        data-bs-toggle="collapse"
                        onClick={() => setClaimTypes("Theft")}
                      >
                        <p>Theft</p>
                      </div>
                    </div>
                  </div>

                  {/* <a
                    style={{ width: "100%", margin: "auto" }}
                    id="button2LogOut"
                    class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                    type="button"
                  >
                    <span>
                      <AiOutlineUpload class="fs-5 mx-2" />{" "}
                      <span>Upload 1</span>{" "}
                    </span> 
                  </a> */}
                  {/* <a
                    style={{ width: "100%", margin: "auto" }}
                    id="button2LogOut"
                    class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                    type="button"
                  >
                    <span>
                      <AiOutlineUpload class="fs-5 mx-2" />{" "}
                      <span>Upload 2</span>{" "}
                    </span> 
                  </a> */}
                  {/* <a
                    style={{ width: "100%", margin: "auto" }}
                    id="button2LogOut"
                    class="btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                    type="button"
                  >
                    <span>
                      <AiOutlineUpload class="fs-5 mx-2" />{" "}
                      <span>Upload 3</span>{" "}
                    </span> 
                  </a> */}

                  <button
                    id="button"
                    class="btn btn-danger mt-3 p-2 rounded-5"
                    style={{ width: "100%" }}
                    onClick={submilClaimReport}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <button
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                type="button"
                onClick={() => navigate("/faq")}
              >
                <span>
                  <FaQuestion class=" mx-2 mb-1" />{" "}
                  <span>Frequently Asked Questions</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </button>

              <button
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                type="button"
                onClick={() => navigate("/terms-conditions")}
              >
                <span>
                  <BsJournalBookmark class=" mx-2 mb-1" />{" "}
                  <span>Terms of Use</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </button>

              <button
                id="button2"
                class="col-md-10 col-12 m-auto btn d-flex rounded-5 p-2 justify-content-between  align-items-center mt-4 "
                type="button"
                onClick={() => navigate("/disclaimer")}
              >
                <span>
                  <MdDangerous class=" mx-2 mb-1" />{" "}
                  <span>Disclaimer</span>{" "}
                </span>
                <span>
                  <AiOutlineRight />
                </span>
              </button> 
              <br />
            </div>
          </div>
        </div>
        {/* ----------end left section --------- */}

        {/* ------------start right section------------- */}
        <div class="col-lg-7 ">
          <div class="col-10 text-center m-auto">
            <p class="fs-5 fw-bold myPolicyss mt-3">Buy Policy</p>

            {/* ------------------moter------------------------ */}
            {/* <div
              class="bg-light mt-3 row"
              style={{
                boxShadow:
                  "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                borderRadius: "20.28px",
              }}
            >
              <div class="col-md-3 col-5 p-0 d-flex align-items-center">
                <img
                  style={{ width: "100%" }}
                  src={require("../../Images/dashoard/carCard.png")}
                  alt=""
                />
              </div>
            
              <div class="col-md-9 col-7 text-start m-0 p-0 d-grid align-items-end">
                <div class="p-3">
                  <p class="text-danger fs-4 fw-bold">Motor Insurance</p>
                  <p class="">You brought a good car. Secure it with a great cover</p>
                </div>
                <div class="text-end">
                  <button
                    class="btn  mt-3 text-white px-5"
                    id="button"
                    style={{ borderRadius: "20px 0px 20px 20px" }}
                  >
                    {" "}
                    View Details
                  </button>
                </div>
              </div>
            </div> */}

            {/* -------------------domestic---------------- */}
            <div
              class="bg-light mt-3 row"
              style={{
                boxShadow:
                  "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                borderRadius: "20.28px",
              }}
            >
              <div class="col-md-3 col-5 p-0 d-flex align-items-center">
                <img
                  style={{ width: "100%" }}
                  src={require("../../Images/dashoard/homeCard.png")}
                  alt=""
                />
              </div>
              <div class="col-md-9 col-7 text-start m-0 p-0 d-grid align-items-end">
                <div class="p-3">
                  <p class="text-danger fs-4 fw-bold">Domestic Package Insurance</p>
                  <p class="">
                    Your house to home journey has been special. Protect your
                    home’s valuable possessions
                  </p>
                </div>
                <div class="text-end">
                  <button
                    class="btn  mt-3 text-white px-5"
                    id="button"
                    style={{ borderRadius: "20px 0px 20px 20px" }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasDomestic"
                    aria-controls="offcanvasScrolling"
                    onClick={() => getDescription(1)}
                  >
                    {" "}
                    View Details
                  </button>
                  <div
                    class="offcanvas offcanvas-end"
                    data-bs-scroll="true"
                    data-bs-backdrop="false"
                    tabindex="-1"
                    id="offcanvasDomestic"
                    aria-labelledby="offcanvasScrollingLabel"
                    style={{
                      backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 110%",
                    }}
                  >
                    <div class="offcanvas-header">
                      <p></p>
                      <h5
                        class="offcanvas-title text-white text-center"
                        id="offcanvasRightLabel"
                      >
                        Domestic Package Insurance
                      </h5>
                      <div class="dropdown">
                          <p class=" text-white fs-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <MdHelp/>
                          </p>
                          <ul class="dropdown-menu" style={{background:'none',minWidth:'fit-content' }}>
                            <li style={{width:'fit-content'}}><p class='text-danger fs-4' data-bs-toggle="offcanvas" data-bs-target="#callNow"   aria-controls="callNow" style={{cursor:'pointer'}}> <MdWifiCalling3 /></p></li>
                            <li style={{width:'fit-content'}}><a class='text-danger fs-4' href={`mailto:${dashboardData?.[0]?.HandlerEmail}`}> <MdMail/> </a></li>
                        
                          </ul>
                        </div> 

                      <button
                        type="button"
                        class="btn text-white "
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <AiOutlineClose />
                      </button>
                    </div>
                    <div class="offcanvas-body">
                      {box == "box0" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="col-11 m-auto">
                              <img
                                style={{ width: "100%" }}
                                src={require("../../Images/dashoard/home.png")}
                                alt=""
                              />
                            </div>
                            <div class="rounded-3 p-3 bg-light mt-3">
                              <p class="fs-5 text-danger fw-bold">
                                {discription?.[0]?.CoverTitle}
                              </p>
                              <hr />
                              {/* <p class='fw-bold'>Details</p> */}

                              <p class="mt-2">
                                {discription?.[0]?.coverDescription}
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box1")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {box == "box1" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setBox("box0")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                                Enter Location
                              </p>
                            </div>

                            {/* <input id="contactInputForm" class="form-control p-2 px-4 my-3"
                            type="text"  name="autoaddress" placeholder="Location" value={locations} onChange={(e)=>setLocations(e.target.value)}/> */}

                            <div class="input-group mb-3 mt-4">
                              <input
                                id="numContactInputForm"
                                style={{ borderRadius: " 50px 0px 0px 50px " }}
                                class="form-control p-2 px-4  m-0"
                                type="text"
                                name="autoaddress"
                                placeholder="Location"
                                value={dometicD?.autoaddress}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                              <span
                                class="input-group-text "
                                id="numContactInputForm"
                                style={{ borderRadius: "0px 50px 50px 0px" }}
                              >
                                <BiCurrentLocation
                                  onClick={() => setShowMap(!showMap)}
                                  style={{
                                    color: "rgb(147, 144, 144)",
                                    cursor: "pointer",
                                  }}
                                />
                              </span>
                              <p
                                class="fs-3 mx-1 text-danger"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            {!showMap ? (
                              ""
                            ) : (
                              <div class="mb-3">
                                <div class="text-end">
                                  <button
                                    class="btn fs-4 text-white m-0 p-0"
                                    onClick={() => setShowMap(!showMap)}
                                  >
                                    <AiOutlineClose />
                                  </button>
                                </div>
                                <MapContainer
                                  data={setPosition}
                                  lat={latitude}
                                  lng={longitude}
                                />
                              </div>
                            )}
                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0 "
                                type="text"
                                name="flatno"
                                placeholder="Flat/Building"
                                value={dometicD?.flatno}
                                onChange={domesticDetails}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>

                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="street"
                                placeholder="Street"
                                value={dometicD?.street}
                                onChange={domesticDetails}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>

                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="city"
                                placeholder=" City in Tanzania"
                                value={dometicD?.city}
                                onChange={domesticDetails}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="landmark"
                                placeholder="Landmark"
                                value={dometicD?.landmark}
                                onChange={domesticDetails}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <div class="d-flex gap-3 mt-3 mx-3">
                              <div>
                                <p class="text-white">Owner ?</p>
                              </div>
                              <div>
                                <div
                                  class="d-flex  justify-content-between gap-1 text-white"
                                  style={{
                                    borderRadius: "150px",
                                    Color: "white",
                                    background: "#484C55",
                                    border: "1.3px solid #494E59",
                                    boxShadow:
                                      "0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25)",
                                  }}
                                >
                                  <div
                                    class="px-2"
                                    style={
                                      owner == "Yes"
                                        ? {
                                            borderRadius: "50px",
                                            background: "red",
                                            cursor: "pointer",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() => setOwner("Yes")}
                                  >
                                    Yes
                                  </div>
                                  <div
                                    class="px-2"
                                    style={
                                      owner == "No"
                                        ? {
                                            borderRadius: "50px",
                                            background: "red",
                                            cursor: "pointer",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() => setOwner("No")}
                                  >
                                    No
                                  </div>
                                </div>
                              </div>
                            </div>


                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box2")}
                              disabled={(dometicD?.autoaddress && dometicD?.autoaddress != "" && dometicD?.flatno && dometicD?.flatno != "" && dometicD?.street && dometicD?.street != "" && dometicD?.city && dometicD?.city != "" && dometicD?.landmark && dometicD?.landmark != "") ? false : true  }
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* {box == "box12" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setBox("box1")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                              Enter Value to Insure
                              </p>
                            </div>
                            
                            <div class='d-flex align-items-center gap-2'> 
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4"
                                type="text"
                                name="SectionA"
                                placeholder="Buildings including Boundary Walls"
                                onChange={domesticDetails}
                              />
                               
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4  "
                              type="text"
                              name="SectionA1"
                              placeholder="Annual Rent (if not owned)"
                              onChange={domesticDetails}
                            />
                            
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4  "
                              type="text"
                              name="SectionB"
                              placeholder="Household Contents"
                              onChange={domesticDetails}
                            />
                              
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="SectionC"
                              placeholder="Valuables"
                              onChange={domesticDetails}
                            /> 
                            </div>

                           
                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="SectionE"
                              placeholder="Owners (Public) Liability"
                              onChange={domesticDetails}
                            /> 
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="SectionF"
                              placeholder="Occupier\'s Personal Liability"
                              onChange={domesticDetails}
                            /> 
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="SectionG"
                              placeholder="Electronic Equipments including power surge"
                              onChange={domesticDetails}
                            />  
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="SectionI"
                              placeholder="Machinery Breakdown"
                              onChange={domesticDetails}
                            /> 
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="SectionD"
                              placeholder="Domestic Compensation"
                              onChange={domesticDetails}
                            /> 
                            </div>

                            <div class='d-flex align-items-center gap-2'> 
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="DomesticHelp"
                              placeholder="How Many Staff"
                              onChange={domesticDetails}
                            /> 
                            </div>

                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box3")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */} 

                      {box == "box2" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox("box1")}
                            >
                              <BiArrowBack />
                            </button>
                            <div class="d-flex gap-3">
                              <div class="rounded-3 p-3 bg-light mt-3">
                                <p class="fs-5  fw-bold">
                                  {information?.[0]?.SectionDescription}
                                </p>
                                <hr />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: information?.[0]?.SectionInfo,
                                  }}
                                ></div>
                                <hr />
                                <p class="fs-5 fw-bold text-danger">Note</p>
                                <br />
                                <p>{information?.[0]?.SectionNotes}</p>
                                <p class="mt-2">
                                  {discription?.[0]?.coverDescription}
                                </p>
                              </div>
                            </div>
                            <div class="d-flex my-3" style={{position:'relative'}}> 
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="SectionA" 
                                value={(dometicD?.SectionA)}
                                placeholder="Buildings including Boundary Walls"
                                onChange={domesticDetails}
                                style={{color:'transparent'}}
                                maxLength={12}
                              /> 
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionA ?  Number(dometicD?.SectionA).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }):"" }</p>

                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox(owner == "Yes" ? "box3" : "box4")}
                              disabled={(dometicD?.SectionA && dometicD?.SectionA != "") ? false : true}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {
                        owner == "Yes" ?
                        <div> 
                          {box == "box3" ? (
                            <div>
                              <div class="text-start p-2">
                                <button
                                  class="btn text-white"
                                  onClick={() => setBox("box2")}
                                >
                                  <BiArrowBack />
                                </button>
                                <div class="d-flex gap-3">
                                  <div class="rounded-3 p-3 bg-light mt-3">
                                    <p class="fs-5  fw-bold">
                                      {information?.[1]?.SectionDescription}
                                    </p>
                                    <hr />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: information?.[1]?.SectionInfo,
                                      }}
                                    ></div>
                                    <hr />
                                    <p class="fs-5 fw-bold text-danger">Note</p>
                                    <br />
                                    <p>{information?.[1]?.SectionNotes}</p>
                                  </div>
                                </div>
                                    <div class="d-flex my-3" style={{position:'relative'}}> 
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 m-0 "
                                    type="text"
                                    name="SectionA1"
                                    value={dometicD?.SectionA1}
                                    placeholder="Annual Rent (if not owned)"
                                    onChange={domesticDetails}
                                    maxLength={9} 
                                    style={{color:'transparent'}}
                                  />
                                  <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionA1 ?  Number(dometicD?.SectionA1).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :'' }</p>
    
                                  <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div>
                                <button
                                  id="button"
                                  class="btn btn-danger mt-3 p-2 rounded-5"
                                  style={{ width: "100%" }}
                                  onClick={() => setBox("box4")}
                                  disabled={(dometicD?.SectionA1 && dometicD?.SectionA1 != "") ? false : true}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}  
                        </div>
                        :''
                      }


                      {box == "box4" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox(owner == "Yes" ? "box3" : "box2")}
                            >
                              <BiArrowBack />
                            </button>
                            <div class="d-flex gap-3">
                              <div class="rounded-3 p-3 bg-light mt-3">
                                <p class="fs-5  fw-bold">
                                  {information?.[2]?.SectionDescription}
                                </p>
                                <hr />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: information?.[2]?.SectionInfo,
                                  }}
                                ></div>
                                <hr />
                                <p class="fs-5 fw-bold text-danger">Note</p>
                                <br />
                                <p>{information?.[2]?.SectionNotes}</p>
                              </div>
                            </div>
                            <div class="d-flex my-3" style={{position:'relative'}}>  
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0 "
                                type="text"
                                name="SectionB"
                                value={dometicD?.SectionB}
                                placeholder="Household Contents"
                                maxLength={9} 
                                onChange={domesticDetails}
                                style={{color:'transparent'}}
                              />
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionB ? Number(dometicD?.SectionB).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }): "" }</p>

                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box5")}
                              disabled={(dometicD?.SectionB && dometicD?.SectionB != "") ? false : true}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {box == "box5" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox("box4")}
                            >
                              <BiArrowBack />
                            </button>
                            <div class="d-flex gap-3">
                              <div class="rounded-3 p-3 bg-light mt-3">
                                <p class="fs-5  fw-bold">
                                  {information?.[3]?.SectionDescription}
                                </p>
                                <hr />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: information?.[3]?.SectionInfo,
                                  }}
                                ></div>
                                <hr />
                                <p class="fs-5 fw-bold text-danger">Note</p>
                                <br />
                                <p>{information?.[3]?.SectionNotes}</p>
                              </div>
                            </div>

                            <div class="d-flex my-3" style={{position:'relative'}}>  
                                  
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0 "
                                type="text"
                                name="SectionC"
                                value={dometicD?.SectionC}
                                placeholder="Valuables"
                                maxLength={9} 
                                onChange={domesticDetails}
                                style={{color:'transparent'}}
                              />
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionC ? Number(dometicD?.SectionC).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :""}</p>

                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox(owner == "Yes" ? "box6" : "box7")}
                              disabled={(dometicD?.SectionC && dometicD?.SectionC != "") ? false : true}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}


                      {
                        owner == "Yes" ? 
                        <div> 
                          {box == "box6" ? (
                            <div>
                              <div class="text-start p-2">
                                <button
                                  class="btn text-white"
                                  onClick={() => setBox("box5")}
                                >
                                  <BiArrowBack />
                                </button>
                                <div class="d-flex gap-3">
                                  <div class="rounded-3 p-3 bg-light mt-3">
                                    <p class="fs-5  fw-bold">
                                      {information?.[5]?.SectionDescription}
                                    </p>
                                    <hr />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: information?.[5]?.SectionInfo,
                                      }}
                                    ></div>
                                    <hr />
                                    <p class="fs-5 fw-bold text-danger">Note</p>
                                    <br />
                                    <p>{information?.[5]?.SectionNotes}</p>
                                  </div>
                                </div>
    
                                <div class="d-flex my-3" style={{position:'relative'}}> 
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 m-0 "
                                    type="text"
                                    name="SectionE"
                                    value={dometicD?.SectionE}
                                    placeholder="Owners (Public) Liability"
                                    onChange={domesticDetails}
                                    maxLength={9} 
                                    style={{color:'transparent'}}
                                  />
                                  <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionE ? Number(dometicD?.SectionE).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :'' }</p>
    
                                  <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div>
                                <button
                                  id="button"
                                  class="btn btn-danger mt-3 p-2 rounded-5"
                                  style={{ width: "100%" }}
                                  onClick={() => setBox("box8")}
                                  disabled={(dometicD?.SectionE && dometicD?.SectionE != "") ? false : true}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div> :''
                      }

                      {
                          owner == "No" ?
                          <div> 
                            {box == "box7" ? (
                              <div>
                                <div class="text-start p-2">
                                  <button
                                    class="btn text-white"
                                    onClick={() => setBox("box5",)}
                                  >
                                    <BiArrowBack />
                                  </button>
                                  <div class="d-flex gap-3">
                                    <div class="rounded-3 p-3 bg-light mt-3">
                                      <p class="fs-5  fw-bold">
                                        {information?.[6]?.SectionDescription}
                                      </p>
                                      <hr />
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: information?.[6]?.SectionInfo,
                                        }}
                                      ></div>
                                      <hr />
                                      <p class="fs-5 fw-bold text-danger">Note</p>
                                      <br />
                                      <p>{information?.[6]?.SectionNotes}</p>
                                    </div>
                                  </div>
      
                                  <div class="d-flex my-3" style={{position:'relative'}}>
                                  
                                    <input
                                      id="contactInputForm"
                                      class="form-control p-2 px-4 m-0 "
                                      type="text"
                                      name="SectionF"
                                      value={dometicD?.SectionF}
                                      placeholder="Occupier\'s Personal Liability"
                                      maxLength={9} 
                                      onChange={domesticDetails}
                                      style={{color:'transparent'}}
                                    />
                                    <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionF ? Number(dometicD?.SectionF).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :''}</p>
      
                                    <p
                                      class="fs-3  text-danger mx-1"
                                      style={{ marginTop: "-5px" }}
                                    >
                                      *
                                    </p>
                                  </div>
                                  <button
                                    id="button"
                                    class="btn btn-danger mt-3 p-2 rounded-5"
                                    style={{ width: "100%" }}
                                    onClick={() => setBox("box8")}
                                    disabled={(dometicD?.SectionF && dometicD?.SectionF != "") ? false : true}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div> :''
                      }

                      {box == "box8" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox(owner == "Yes" ? "box6" : "box7")}
                            >
                              <BiArrowBack />
                            </button>
                            <div class="d-flex gap-3">
                              <div class="rounded-3 p-3 bg-light mt-3">
                                <p class="fs-5  fw-bold">
                                  {information?.[7]?.SectionDescription}
                                </p>
                                <hr />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: information?.[7]?.SectionInfo,
                                  }}
                                ></div>
                                <hr />
                                <p class="fs-5 fw-bold text-danger">Note</p>
                                <br />
                                <p>{information?.[7]?.SectionNotes}</p>
                              </div>
                            </div>

                            <div class="d-flex my-3" style={{position:'relative'}}>
                                  
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="SectionG"
                                value={dometicD?.SectionG}
                                placeholder="Electronic Equipments including power surge"
                                onChange={domesticDetails}
                                maxLength={9} 
                                style={{color:'transparent'}}
                              />
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionG ? Number(dometicD?.SectionG).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :'' }</p>

                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box9")}
                              disabled={(dometicD?.SectionG && dometicD?.SectionG != "") ? false : true}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {box == "box9" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox("box8")}
                            >
                              <BiArrowBack />
                            </button>
                            <div class="d-flex gap-3">
                              <div class="rounded-3 p-3 bg-light mt-3">
                                <p class="fs-5  fw-bold">
                                  {information?.[8]?.SectionDescription}
                                </p>
                                <hr />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: information?.[8]?.SectionInfo,
                                  }}
                                ></div>
                                <hr />
                                <p class="fs-5 fw-bold text-danger">Note</p>
                                <br />
                                <p>{information?.[8]?.SectionNotes}</p>
                              </div>
                            </div>

                            <div class="d-flex my-3" style={{position:'relative'}}>
                                  
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0 "
                                type="text"
                                name="SectionI"
                                value={dometicD?.SectionI}
                                placeholder="Machinery Breakdown"
                                onChange={domesticDetails}
                                maxLength={9} 
                                style={{color:'transparent'}}
                              />
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionI ?  Number(dometicD?.SectionI).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :'' }</p>

                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box10")}
                              disabled={(dometicD?.SectionI && dometicD?.SectionI != "") ? false : true}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {box == "box10" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox("box9")}
                            >
                              <BiArrowBack />
                            </button>
                            <div class="d-flex gap-3">
                              <div class="rounded-3 p-3 bg-light mt-3">
                                <p class="fs-5  fw-bold">
                                  {information?.[4]?.SectionDescription}
                                </p>
                                <hr />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: information?.[4]?.SectionInfo,
                                  }}
                                ></div>
                                <hr />
                                <p class="fs-5 fw-bold text-danger">Note</p>
                                <br />
                                <p>{information?.[4]?.SectionNotes}</p>
                              </div>
                            </div>

                            <div class="d-flex my-3" style={{position:'relative'}}>
                                  
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0 "
                                type="text"
                                name="SectionD"
                                value={dometicD?.SectionD}
                                placeholder="Domestic Compensation"
                                onChange={domesticDetails}
                                maxLength={9} 
                                style={{color:'transparent'}}
                              />
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{dometicD?.SectionD ? Number(dometicD?.SectionD).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :''}</p>

                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>

                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="DomesticHelp"
                                value={dometicD?.DomesticHelp}
                                maxLength={3} 
                                placeholder="How many Domestic workers"
                                onChange={domesticDetails}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box12")}
                              disabled={(dometicD?.SectionD && dometicD?.SectionD != "" && dometicD?.DomesticHelp && dometicD?.DomesticHelp != "") ? false : true}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* {box == "box11" ? (
                        <div>
                          <div class="text-start p-2">
                            <button
                              class="btn text-white"
                              onClick={() => setBox("box10")}
                            >
                              <BiArrowBack />
                            </button>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 "
                              type="text"
                              name="DomesticHelp"
                              placeholder="How Many Staff"
                              onChange={domesticDetails}
                            />
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setBox("box12")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}

                      {/* ------------------------------------ */}
                      {box == "box12" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setBox("box10")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                                Fill Details
                              </p>
                            </div>

                            <label htmlFor="" class="m-0 px-2 text-white">
                              Start Date
                            </label>
                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="date"
                                name="startdate"
                                placeholder="startdate"
                                value={dometicD?.startdate}
                                onChange={domesticDetails}
                                min={getTodayDate()}
                                max={domesticmaxDateformet}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>

                            <div class="d-flex my-3">
                              <input
                                id="contactInputForm"
                                class="form-control p-2 px-4 m-0"
                                type="text"
                                name="snote"
                                value={dometicD?.snote}
                                placeholder="Previous Insurance Particulars (if any):"
                                onChange={domesticDetails}
                                maxLength={100}
                              />
                              <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            {/* 
                          <label htmlFor="" class='text-white px-3'>Mortgage</label> 
                          <div
                            id="contactInputForm"
                            class="form-control p-2 px-4 mb-0 d-flex justify-content-between"
                            data-bs-toggle="collapse"
                            href="#mortgageCollaps"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            style={{ zIndex: "999999999999",margin:'0' }}
                          >
                            <p style={{ color: "rgb(147, 144, 144)" }}>
                              {mortgage}
                            </p>
                            <p style={{ color: "rgb(147, 144, 144)" }}>
                              <AiOutlineDown />
                            </p>
                          </div>
                          <div class="collapse" id="mortgageCollaps">
                            <div class="card card-body jointEducationCollaps">
                              <div
                                data-bs-target="#mortgageCollaps"
                                data-bs-toggle="collapse"
                                onClick={() => setMortgage("Yes")}
                              >
                                <p>Yes</p>
                              </div>
                              <div
                                data-bs-target="#mortgageCollaps"
                                data-bs-toggle="collapse"
                                onClick={() => setMortgage("No")}
                              >
                                <p>No</p>
                              </div> 
                            </div>
                          </div> */}

                            <div class="d-flex gap-3 mt-3 mx-3">
                              <div>
                                <p class="text-white">Mortgage ?</p>
                              </div>
                              <div>
                                <div
                                  class="d-flex  justify-content-between gap-1 text-white"
                                  style={{
                                    borderRadius: "150px",
                                    Color: "white",
                                    background: "#484C55",
                                    border: "1.3px solid #494E59",
                                    boxShadow:
                                      "0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25)",
                                  }}
                                >
                                  <div
                                    class="px-2"
                                    style={
                                      mortgage == "Yes"
                                        ? {
                                            borderRadius: "50px",
                                            background: "red",
                                            cursor: "pointer",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() => setMortgage("Yes")}
                                  >
                                    Yes
                                  </div>
                                  <div
                                    class="px-2"
                                    style={
                                      mortgage == "No"
                                        ? {
                                            borderRadius: "50px",
                                            background: "red",
                                            cursor: "pointer",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() => setMortgage("No")}
                                  >
                                    No
                                  </div>
                                </div>
                              </div>
                            </div>

                            {mortgage == "No" ? (
                              ""
                            ) : (
                              ""
                            )}

                            
{mortgage == "Yes" ?  (
                              <div>
                                <label htmlFor="" class="text-white px-3 mt-3">
                                  Bank Name
                                </label>
                                <div class="d-flex ">
                                  <div class="col-11">
                                    <div
                                      id="contactInputForm"
                                      class="form-control p-2 px-4 mb-0 d-flex justify-content-between"
                                      data-bs-toggle="collapse"
                                      href="#branchNameCollaps"
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                      style={{
                                       
                                        margin: "0",
                                      }}
                                    >
                                      <p
                                        style={{ color: "white" }}
                                      >
                                        {bankBranch + " " + branchNameDp}
                                      </p>
                                      <p
                                        style={{ color: "rgb(147, 144, 144)" }}
                                      >
                                        <AiOutlineDown />
                                      </p>
                                    </div>
                                    <div
                                      class="collapse"
                                      id="branchNameCollaps"
                                    >
                                      <div class="card card-body jointEducationCollaps">
                                        {bankName?.map((el) => {
                                          return (
                                            <div
                                              data-bs-target="#branchNameCollaps"
                                              data-bs-toggle="collapse"
                                              onClick={() =>
                                                bankBrancheDetails(
                                                  "Yes",
                                                  el.BankNo,
                                                  el.BankName
                                                )
                                              }
                                            >
                                              <p>{el.BankName}</p>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div>

                                <label htmlFor="" class="text-white px-3 mt-3">
                                  Branch Name
                                </label>
                                <div class="d-flex">
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 m-0"
                                    type="text"
                                    name="snote"
                                    placeholder="Branch Name"
                                    maxLength={20} 
                                    onChange={(e) =>
                                      setBranchNameDp(e.target.value)
                                    }
                                  />
                                  <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div>
                              </div>
                            ):""}

                            {mortgage == "No" ?
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => domesticSubmit("box13")}
                              disabled={(dometicD?.snote && dometicD?.snote != "" && dometicD?.startdate && dometicD?.startdate != "") ? false : true}

                            >
                              Submit
                            </button>
                            :
                            <button
                            id="button"
                            class="btn btn-danger mt-3 p-2 rounded-5"
                            style={{ width: "100%" }}
                            onClick={() => domesticSubmit("box13")}
                            disabled={(dometicD?.snote && dometicD?.snote != "" && dometicD?.startdate && dometicD?.startdate != "" && dometicD?.BankBranch && dometicD?.BankBranch != "" && branchNameDp && branchNameDp != "") ? false : true}

                          >
                            Submited
                          </button>

                            }
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {box == "box13" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setBox("box12")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                                Offer for you{" "}
                              </p>
                            </div>

                            {domesticOffer?.map((el, i) => {
                              return (
                                <div
                                  class="bg-light mt-4"
                                  style={{
                                    boxShadow:
                                      "0px 4px 9px rgba(0, 0, 0, 0.33), inset 3px 6px 4px #E4E3E3, inset -6px -6px 4px #E0DADA",
                                    borderRadius: "13px",
                                  }}
                                >
                                  <div class="p-3 d-flex gap-2">
                                    <div>
                                      <img
                                        src={`https://tzapis.aris-world.com/icons/${el.incologo}`}
                                        style={{ width: "40px" }}
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <p class="text-danger fw-bold">
                                        {el.inconame}
                                      </p>
                                      <div class="d-flex gap-2">
                                        <p>Period :</p>
                                        <p
                                          class="btn   p-0 px-2 rounded-4"
                                          style={
                                            period[i] == `month`
                                              ? {
                                                  border: "1px solid red",
                                                  color: "red",
                                                  background: "#ffefef",
                                                }
                                              : {
                                                  border: "none",
                                                  background: "#f0f0f0",
                                                  color: "gray",
                                                }
                                          }
                                          onClick={() =>
                                            setPeriod({
                                              ...period,
                                              [i]: "month",
                                            })
                                          }
                                        >
                                          6 months
                                        </p>
                                        <p
                                          class="btn   p-0 px-2 rounded-4"
                                          style={
                                            period[i] == "year"
                                              ? {
                                                  border: "1px solid red",
                                                  color: "red",
                                                  background: "#ffefef",
                                                }
                                              : {
                                                  border: "none",
                                                  background: "#f0f0f0",
                                                  color: "gray",
                                                }
                                          }
                                          onClick={() =>
                                            setPeriod({
                                              ...period,
                                              [i]: "year",
                                            })
                                          }
                                        >
                                          1 year
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <div class="">
                                      <p class="fs-5 fw-semibold px-3">
                                        TZS
                                        {period[i] == `year` ? (
                                          <span>{el.fullyear}</span>
                                        ) : (
                                          <span>{el.halfyear}</span>
                                        )}
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        id="button"
                                        class="btn text-white px-3"
                                        style={{
                                          borderRadius: "15px 0px 13px 15px ",
                                        }}
                                        onClick={() =>
                                          OfferPayment({
                                            quoteid: el.quoteid,
                                            tenure:
                                              period[i] == "year"
                                                ? "Full Year"
                                                : "Half Year",
                                            AccessToken: userToken,
                                          })
                                        }
                                      >
                                        Book
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------------------funeral------------------- */}
            <div
              class="bg-light mt-3 row"
              style={{
                boxShadow:
                  "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                borderRadius: "20.28px",
              }}
            >
              <div class="col-md-3 col-5 p-0 d-flex align-items-center">
                <img
                  style={{ width: "100%" }}
                  src={require("../../Images/dashoard/funeral.png")}
                  alt=""
                />
              </div>
              <div class="col-md-9 col-7 text-start m-0 p-0 d-grid align-items-end">
                <div class="p-3">
                  <p class="text-danger fs-4 fw-bold">Individual Last Expenses Policy</p>
                  <p class="">Guarantee the respect that you deserve</p>
                </div>
                <div class="text-end">
                  <button
                    class="btn  mt-3 text-white px-5"
                    id="button"
                    style={{ borderRadius: "20px 0px 20px 20px" }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFuneral"
                    aria-controls="offcanvasScrolling"
                    onClick={() => getDescription(6)}
                  >
                    {" "}
                    View Details
                  </button>
                  <div
                    class="offcanvas offcanvas-end"
                    data-bs-scroll="true"
                    data-bs-backdrop="false"
                    tabindex="-1"
                    id="offcanvasFuneral"
                    aria-labelledby="offcanvasScrollingLabel"
                    style={{
                      backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 110%",
                    }}
                  >
                    <div class="offcanvas-header">
                      <p></p>
                      <h5
                        class="offcanvas-title text-white text-center"
                        id="offcanvasRightLabel"
                      >
                        Individual Last Expenses Policy
                      </h5>
                      <div class="dropdown">
                          <p class=" text-white fs-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <MdHelp/>
                          </p>
                          <ul class="dropdown-menu" style={{background:'none',minWidth:'fit-content' }}>
                            <li style={{width:'fit-content'}}><p class='text-danger fs-4' data-bs-toggle="offcanvas" data-bs-target="#callNow"   aria-controls="callNow" style={{cursor:'pointer'}}> <MdWifiCalling3 /></p></li>
                            <li style={{width:'fit-content'}}><a class='text-danger fs-4' href={`mailto:${dashboardData?.[0]?.HandlerEmail}`}> <MdMail/> </a></li>
                        
                          </ul>
                        </div> 

                      <button
                        type="button"
                        class="btn text-white "
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <AiOutlineClose />
                      </button>
                    </div>
                    <div class="offcanvas-body">
                      {funeral == "funeral0" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="col-11 m-auto">
                              <img
                                style={{ width: "100%" }}
                                src={require("../../Images/dashoard/funeral1.png")}
                                alt=""
                              />
                            </div>
                            <div class="rounded-3 p-3 bg-light mt-3">
                              <p class="fs-5 text-danger fw-bold">
                                {discription?.Main?.CoverTitle}
                              </p>
                              <hr />
                              {/* <p class='fw-bold'>Details</p>  */}
                              <p class="mt-2">
                                {discription?.Main?.coverDescription}
                              </p>
                            </div>

                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setFuneral("funeral1")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {funeral == "funeral1" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setFuneral("funeral0")}
                              >
                                <BiArrowBack />
                              </button>
                            </div>
                            {individualCart()} 
                            <br />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {funeral == "funeral2" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setFuneral("funeral1")}
                              >
                                <BiArrowBack />
                              </button>
                              {/* <p class="text-white  fs-3 fw-semibold">
                                Fill Personal Details
                              </p> */}
                            </div>
                            <div>
                               

                              {funeralPackegTable?.map((el)=>{
                                return(
                                  <div>
                                    <p class='text-danger fw-bold mt-3'>{el?.title}</p>
                                    <div style={{ overflowX: 'auto' }}>
                                      <table class="table table-bordered text-white my-2" style={{ borderColor: 'gray' }}>
                                        <thead style={{ position: 'sticky', top: "0", background: `${tableColor}` }}>
                                          <tr>
                                            <th scope="col" style={{ color: `white` }}>{el?.lhsHeader}</th>
                                            <th scope="col" style={{ color: `white` }} >{el?.rhsHeader}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            el?.content.map((el) => {
                                              return (
                                                <tr>
                                                  <td>{el.lhsColumn}</td>
                                                  <td>{el.rhsColumn}</td>
                                                </tr>
                                              )
                                            })
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                  </div> 
                                )
                              })}


                            </div>

                           

                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setFuneral("funeral3")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {funeral == "funeral3" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setFuneral("funeral2")}
                              >
                                <BiArrowBack />
                              </button>
                              {/* <p class="text-white  fs-3 fw-semibold">
                                Fill Personal Details
                              </p> */}
                            </div>
                            <label htmlFor="" class="text-white px-2 m-0">
                              Start Date
                            </label>
                           <div class='d-flex'>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="date"
                              name=" "
                              min={getTodayDate()}
                              max={individualmaxDateformet}
                              onChange={(e) => setFuneralDate(e.target.value)}
                            />
                             <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <div class='d-flex my-3'>
                            <textarea
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name=""
                              placeholder="Addition Details..."
                              maxLength={100}
                              onChange={(e) => setFuneralNote(e.target.value)}
                            />
                             {/* <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p> */}
                            </div>
                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => funeralPackegSubmit()}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {funeral == "funeral4" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setFuneral("funeral1")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                                Offer for you{" "}
                              </p>
                            </div>

                            {funeralOffer?.map((el, i) => {
                              return (
                                <div
                                  class="bg-light mt-4"
                                  style={{
                                    boxShadow:
                                      "0px 4px 9px rgba(0, 0, 0, 0.33), inset 3px 6px 4px #E4E3E3, inset -6px -6px 4px #E0DADA",
                                    borderRadius: "13px",
                                  }}
                                >
                                  <div class="p-3 d-flex gap-2">
                                    <div>
                                      <img
                                        src={`https://tzapis.aris-world.com/icons/${el.incologo}`}
                                        style={{ width: "40px" }}
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <p class="text-danger fw-bold">
                                        {el.inconame}
                                      </p>
                                      <div class="d-flex gap-2">
                                        <p>Period :</p>
                                        <p
                                          class="btn   p-0 px-2 rounded-4"
                                          style={{
                                            border: "1px solid red",
                                            color: "red",
                                            background: "#ffefef",
                                          }}
                                        >
                                          1 year
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <div class="">
                                      <p class="fs-5 fw-semibold px-3">
                                        TZS <span>{el.fullyear}</span>
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        id="button"
                                        class="btn text-white px-3"
                                        style={{
                                          borderRadius: "15px 0px 13px 15px ",
                                        }}
                                        onClick={() =>
                                          OfferPayment({
                                            quoteid: el.quoteid,
                                            tenure: "Full Year",
                                            AccessToken: userToken,
                                          })
                                        }
                                      >
                                        Book
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------joint education --------------- */}
            <div
              class="bg-light mt-3 row"
              style={{
                boxShadow:
                  "0px 6.24px 14.04px rgba(0, 0, 0, 0.33), inset 4.68px 9.36px 6.24px #E4E3E3, inset -9.36px -9.36px 6.24px #E0DADA",
                borderRadius: "20.28px",
              }}
            >
              <div class="col-md-3 col-5 p-0 d-flex align-items-center">
                <img
                  style={{ width: "100%" }}
                  src={require("../../Images/dashoard/jointEducation.png")}
                  alt=""
                />
              </div>
              <div class="col-md-9 col-7 text-start m-0 p-0 d-grid align-items-end">
                <div class="p-3">
                  <p class="text-danger fs-4 fw-bold">
                  Dream Education Life Policy 
                  </p>
                  <p class="">Secure your children’s education. Right Now.</p>
                </div>
                <div class="text-end">
                  <button
                    class="btn  mt-3 text-white px-5"
                    id="button"
                    style={{ borderRadius: "20px 0px 20px 20px" }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasJointEucation"
                    aria-controls="offcanvasScrolling"
                    onClick={() => getDescription(7)}
                  >
                    {" "}
                    View Details
                  </button>
                  <div
                    class="offcanvas offcanvas-end"
                    data-bs-scroll="true"
                    data-bs-backdrop="false"
                    tabindex="-1"
                    id="offcanvasJointEucation"
                    aria-labelledby="offcanvasScrollingLabel"
                    style={{
                      backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 110%",
                    }}
                  >
                    <div class="offcanvas-header">
                      <p></p>
                      <h5
                        class="offcanvas-title text-white text-center"
                        id="offcanvasRightLabel"
                      >
                       Dream Education Life Policy 
                      </h5>
                      <div class="dropdown">
                          <p class=" text-white fs-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <MdHelp/>
                          </p>
                          <ul class="dropdown-menu" style={{background:'none',minWidth:'fit-content' }}>
                            <li style={{width:'fit-content'}}><p class='text-danger fs-4' data-bs-toggle="offcanvas" data-bs-target="#callNow"   aria-controls="callNow" style={{cursor:'pointer'}}> <MdWifiCalling3 /></p></li>
                            <li style={{width:'fit-content'}}><a class='text-danger fs-4' href={`mailto:${dashboardData?.[0]?.HandlerEmail}`}> <MdMail/> </a></li>
                        
                          </ul>
                        </div> 
                      <button
                        type="button"
                        class="btn text-white "
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <AiOutlineClose />
                      </button>
                    </div>
                    <div class="offcanvas-body">
                    {jeBox == "jeBox-1" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="col-11 m-auto">
                              <img
                                style={{ width: "100%" }}
                                src={require("../../Images/dashoard/jointE1.png")}
                                alt=""
                              />
                            </div>
                            <div class="rounded-3 p-3 bg-light mt-3">
                              <p class="fs-5 text-danger fw-bold">
                                {discription?.Main?.CoverTitle}
                              </p>
                              <hr />
                              {/* <p class='fw-bold'>Details</p>  */}
                              <p class="mt-2">
                                {discription?.Main?.coverDescription}
                              </p>
                            </div>

                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setJeBox("jeBox0")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {jeBox == "jeBox0" ? (
                        <div>
                          <div class="text-start p-2">
                          <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setJeBox("jeBox-1")}
                              >
                                <BiArrowBack />
                              </button> 
                            </div>

                            {
                              discription?.Data?.map((el)=>{
                                return(
                                    <div class="rounded-3 p-3 bg-light mt-3">
                                      <p class="fs-5 text-danger fw-bold"> 
                                         {el.CoverTitle}
                                      </p>
                                      <hr />
                                      <p dangerouslySetInnerHTML={{ __html: el.coverDescription }}></p>
                                  
                                    </div>
                                )
                              })
                            }
                            
                        
 

                             

                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => setJeBox("jeBox1")}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {jeBox == "jeBox1" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setJeBox("jeBox0")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                                Fill Details
                              </p>
                            </div>
                            <div class='d-flex my-3'>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name="StudentName"
                              placeholder="Student Name"
                              onChange={jointEducationDetils}
                            />
                            <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div>
                            <div class='d-flex my-3'>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name="SchoolName"
                              placeholder="School Name"
                              onChange={jointEducationDetils}
                            />
                            <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                            </div> 

                        <div class="input-group  my-3"  >
                          <span class="input-group-text " id='numContactInputForm' style={{borderRadius:"50px 0px 0px 50px"}}>+255</span>
                          <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name="SchoolContact"
                              placeholder="School Contact Number"
                              maxLength={9}
                              style={{borderRadius:'0 50px 50px 0'}}
                              onChange={(e) =>
                                setJointSchoolConDetils(e.target.value)
                              }
                            />
                            <p
                                class="fs-3  text-danger mx-1"
                                style={{ marginTop: "-5px" }}
                              >
                                *
                              </p>
                        </div>

                      

                            <label htmlFor="" class="text-white px-3">
                              Standard / Level
                            </label>
                            <div class='d-flex'>
                            <div
                              id="contactInputForm"
                              class="form-control p-2 px-4 mb-0 d-flex justify-content-between"
                              data-bs-toggle="collapse"
                              href="#jointEducationCollaps"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              style={{   margin: "0" }}
                            >
                              <p style={{ color: "white" }}>
                                {jeStander}
                              </p>
                              <p style={{ color: "rgb(147, 144, 144)" }}>
                                <AiOutlineDown />
                              </p>
                            </div>
                            <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>
                            <div class="collapse" id="jointEducationCollaps">
                              <div class="card card-body jointEducationCollaps">
                                {/* <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Nursery")}
                                >
                                  <p>Nursery</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("KG1")}
                                >
                                  <p>KG1</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("KG2")}
                                >
                                  <p>KG2</p>
                                </div> */}
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 1")}
                                >
                                  <p>Grade 1</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 2")}
                                >
                                  <p>Grade 2 </p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 3")}
                                >
                                  <p>Grade 3</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 4")}
                                >
                                  <p>Grade 4</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 5")}
                                >
                                  <p>Grade 5</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 6")}
                                >
                                  <p>Grade 6</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() => setJeStander("Grade 7")}
                                >
                                  <p>Grade 7</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Form 1 (O Levels)")
                                  }
                                >
                                  <p>Form 1 (O Levels)</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Form 2 (O Levels)")
                                  }
                                >
                                  <p>Form 2 (O Levels)</p>
                                </div>

                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Form 3 (O Levels)")
                                  }
                                >
                                  <p>Form 3 (O Levels)</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Form 4 (O Levels)")
                                  }
                                >
                                  <p>Form 4 (O Levels)</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Form 5 (A Levels)")
                                  }
                                >
                                  <p>Form 5 (A Levels)</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Form 6 (A Levels)")
                                  }
                                >
                                  <p>Form 6 (A Levels)</p>
                                </div>
                                <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("University")
                                  }
                                >
                                  <p>University</p>
                                </div>
                                {/* <div
                                  data-bs-target="#jointEducationCollaps"
                                  data-bs-toggle="collapse"
                                  onClick={() =>
                                    setJeStander("Others")
                                  }
                                >
                                  <p>Others</p>
                                </div> */}
                              </div>
                            </div>

                            {jeStander.split(" ")[0] != "Others" ? (
                              ""
                            ) : (
                              <div>
                                <label htmlFor="" class="text-white px-3 mt-3">
                                  Specify Year
                                </label>
                                <div class='d-flex'>
                                <div
                                  id="contactInputForm"
                                  class="form-control p-2 px-4 mb-0 d-flex justify-content-between"
                                  data-bs-toggle="collapse"
                                  href="#higherLearningCollaps"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapseExample"
                                  style={{
                                   
                                    margin: "0",
                                  }}
                                >
                                  <p style={{ color: "rgb(147, 144, 144)" }}>
                                    {jeStander}
                                  </p>
                                  <p style={{ color: "rgb(147, 144, 144)" }}>
                                    <AiOutlineDown />
                                  </p>
                                </div>
                                <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>
                                <div
                                  class="collapse"
                                  id="higherLearningCollaps"
                                >
                                  <div class="card card-body jointEducationCollaps">
                                    <div
                                      data-bs-target="#higherLearningCollaps"
                                      data-bs-toggle="collapse"
                                      onClick={() =>
                                        setJeStander("University 1 Year")
                                      }
                                    >
                                      <p>1 Year</p>
                                    </div>
                                    <div
                                      data-bs-target="#higherLearningCollaps"
                                      data-bs-toggle="collapse"
                                      onClick={() =>
                                        setJeStander("University 2 Year")
                                      }
                                    >
                                      <p>2 Year</p>
                                    </div>
                                    <div
                                      data-bs-target="#higherLearningCollaps"
                                      data-bs-toggle="collapse"
                                      onClick={() =>
                                        setJeStander("University 3 Year")
                                      }
                                    >
                                      <p>3 Year</p>
                                    </div>
                                    <div
                                      data-bs-target="#higherLearningCollaps"
                                      data-bs-toggle="collapse"
                                      onClick={() =>
                                        setJeStander("University 4 Year")
                                      }
                                    >
                                      <p>4 Year</p>
                                    </div>
                                    <div
                                      data-bs-target="#higherLearningCollaps"
                                      data-bs-toggle="collapse"
                                      onClick={() =>
                                        setJeStander("University 5 Year")
                                      }
                                    >
                                      <p>5 Year</p>
                                    </div>
                                    <div
                                      data-bs-target="#higherLearningCollaps"
                                      data-bs-toggle="collapse"
                                      onClick={() =>
                                        setJeStander("University 6 Year")
                                      }
                                    >
                                      <p>6 Year</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
 <div class='d-flex my-3'>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name="Guardian1"
                              placeholder="Guardian Name 1"
                              onChange={jointEducationDetils}
                            />
                             <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>
                        <div class='d-flex my-3'>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name="Guardian2"
                              placeholder="Guardian Name 2"
                              onChange={jointEducationDetils}
                            />
                             <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div> 
                        <div class='d-flex my-3' style={{position:'relative'}}>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="text"
                              name="SchoolFees"
                              placeholder="Anual School Fees"
                              maxLength={7}
                              style={{color:'transparent'}}
                              onChange={jointEducationDetils}
                            />
                              <p style={{position:'absolute',padding:'10px 20px'}} class='text-white'>{jointEducationD?.SchoolFees ?  Number(jointEducationD?.SchoolFees).toLocaleString('en-TZ', { style: 'currency', currency: 'TZS',minimumFractionDigits: 0, }) :'' }</p>

                            <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>

                            <label htmlFor="" class="text-white px-3">
                              Start Date
                            </label>
                            <div class='d-flex'>
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="date"
                              name="startdate"
                              placeholder="Start Date"
                              min={StartFormattedMinDate}
                              onChange={jointEducationDetils}
                            />
                            <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>

                            <label htmlFor="" class="text-white px-3 mt-3">
                              Student DOB
                            </label>
                            <div class='d-flex'>  
                            <input
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="date"
                              name="StudentDOB"
                              placeholder="Student DOB" 
                              min={studentFormattedMinDate}
                              max={studentFormattedMaxDate}

                              onChange={jointEducationDetils}
                            />
                            <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>

                            <label htmlFor="" class="text-white px-3 mt-3">
                              Guardian 1 DOB
                            </label>
                            <div class='d-flex '>
                           
                               <DatePicker
                          selected={studentGar1}
                          onChange={(date) => setStudentGar1(date)} 
                          maxDate={studentGarMinDate}
                          showTimeSelect={false}
                          style={{zIndex:'10'}}

                        />

                          
                            <p
                            class="fs-3  text-danger mx-1"
                            style={{ marginTop: "-5px" }}
                          >
                            *
                          </p>
                        </div>
                            <div class="d-flex flex-wrap gap-2 px-2 my-3">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  onChange={() => setGardion1CNO("NIDA")}
                                  checked={gardian1CNO == "NIDA" ? true : false}
                                />
                                <label class="form-check-label text-white">
                                  NIDA
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  onChange={() => setGardion1CNO("Passport")}
                                  checked={
                                    gardian1CNO == "Passport" ? true : false
                                  }
                                />
                                <label class="form-check-label text-white">
                                  Passport
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  onChange={() =>
                                    setGardion1CNO("Driving License")
                                  }
                                  checked={
                                    gardian1CNO == "Driving License"
                                      ? true
                                      : false
                                  }
                                />
                                <label class="form-check-label text-white">
                                  Driving License
                                </label>
                              </div>
                            </div>

                            {gardian1CNO != "NIDA" ?  "" :
                             
                              <div> 
                                  <div class='d-flex'>
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 mb-3 m-0"
                                    type="text"
                                    name="Guardian2DOB"
                                    placeholder={gardian1CNO}
                                    onChange={(e) =>
                                      setGardion1Cdetail(e.target.value)
                                    }
                                  />
                                  <p
                                  class="fs-3  text-danger mx-1"
                                  style={{ marginTop: "-5px" }}
                                >
                                  *
                                </p>
                                  </div> 
                                    <p class='fw-semibold' style={gardianNida1 ? {display:'none'}:{marginTop:'-20px',marginLeft:'20px',marginBottom:'10px' ,color:'red',display:'block'}}>Enter your currect nida number</p>
                              </div>
                            }

                            {gardian1CNO != "Passport" ?  "" : 
                             <div> 
                                 <div class='d-flex'>
                                 <input
                                   id="contactInputForm"
                                   class="form-control p-2 px-4 mb-3 m-0"
                                   type="text"
                                   name="Guardian2DOB"
                                   placeholder={gardian1CNO}
                                   maxLength={10}
                                   onChange={(e) =>
                                     setGardion1Cdetail(e.target.value)
                                   }
                                 />
                                 <p
                                 class="fs-3  text-danger mx-1"
                                 style={{ marginTop: "-5px" }}
                               >
                                 *
                               </p>
                                 </div> 
                                   <p class='fw-semibold' style={gardianNida1 ? {display:'none'}:{marginTop:'-20px',marginLeft:'20px',marginBottom:'20px' ,color:'red',display:'block'}}> </p>
                             </div>
                           }

                            {gardian1CNO != "Driving License" ?  "" : 
                             <div> 
                                 <div class='d-flex'>
                                 <input
                                   id="contactInputForm"
                                   class="form-control p-2 px-4 mb-3 m-0"
                                   type="text"
                                   name="Guardian2DOB"
                                   placeholder={gardian1CNO}
                                   maxLength={15}
                                   onChange={(e) =>
                                     setGardion1Cdetail(e.target.value)
                                   }
                                 />
                                 <p
                                 class="fs-3  text-danger mx-1"
                                 style={{ marginTop: "-5px" }}
                               >
                                 *
                               </p>
                                 </div> 
                                   <p class='fw-semibold' style={gardianNida1 ? {display:'none'}:{marginTop:'-20px',marginLeft:'20px',marginBottom:'20px' ,color:'red',display:'block'}}> </p>
                             </div>
                           }

                            <label htmlFor="" class="text-white px-3 ">
                              Guardian 2 DOB
                            </label>
                            <div class='d-flex'>
                            
                               <DatePicker
                                selected={studentGar2}
                                onChange={(date) => setStudentGar2(date)} 
                                maxDate={studentGarMinDate}
                                showTimeSelect={false}
                                style={{zIndex:'10'}} 
                              />
                              <p
                              class="fs-3  text-danger mx-1"
                              style={{ marginTop: "-5px" }}
                            >
                              *
                            </p>
                          </div>

                            <div class="d-flex flex-wrap gap-2 px-2 my-3">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  onChange={() => setGardion2CNO("NIDA")}
                                  checked={gardian2CNO == "NIDA" ? true : false}
                                />
                                <label class="form-check-label text-white">
                                  NIDA
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  onChange={() => setGardion2CNO("Passport")}
                                  checked={
                                    gardian2CNO == "Passport" ? true : false
                                  }
                                />
                                <label class="form-check-label text-white">
                                  Passport
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  onChange={() =>
                                    setGardion2CNO("Driving License")
                                  }
                                  checked={
                                    gardian2CNO == "Driving License"
                                      ? true
                                      : false
                                  }
                                />
                                <label class="form-check-label text-white">
                                  Driving License
                                </label>
                              </div>
                            </div>

                            {gardian2CNO != "NIDA" ? "" :
                              <div>
                                <div class='d-flex'>
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 m-0"
                                    type="text"
                                    name="SchoolFees"
                                    placeholder={gardian2CNO}
                                    onChange={(e) =>
                                      setGardion2Cdetail(e.target.value)
                                    }
                                  />
                                    <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div> 
                                <p class='fw-semibold' style={gardianNida2?{display:'none'}: {marginTop:'0px',marginLeft:'20px',marginBottom:'10px' ,color:'red',display:"block"}}>Enter your currect nida number</p>
                              </div>
                            }

                            {gardian2CNO != "Passport" ? "" :
                              <div>
                                <div class='d-flex'>
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 m-0"
                                    type="text"
                                    name="SchoolFees"
                                    placeholder={gardian2CNO}
                                    maxLength={10}
                                    onChange={(e) =>
                                      setGardion2Cdetail(e.target.value)
                                    }
                                  />
                                    <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div> 
                                <p class='fw-semibold' style={gardianNida2?{display:'none'}: {marginTop:'0px',marginLeft:'20px',marginBottom:'20px' ,color:'red',display:"block"}}></p>
                              </div>
                            }

                            {gardian2CNO != "Driving License" ? "" :
                              <div>
                                <div class='d-flex'>
                                  <input
                                    id="contactInputForm"
                                    class="form-control p-2 px-4 m-0"
                                    type="text"
                                    name="SchoolFees"
                                    placeholder={gardian2CNO}
                                    maxLength={15}
                                    onChange={(e) =>
                                      setGardion2Cdetail(e.target.value)
                                    }
                                  />
                                    <p
                                    class="fs-3  text-danger mx-1"
                                    style={{ marginTop: "-5px" }}
                                  >
                                    *
                                  </p>
                                </div> 
                                <p class='fw-semibold' style={gardianNida2?{display:'none'}: {marginTop:'0px',marginLeft:'20px',marginBottom:'20px' ,color:'red',display:"block"}}> </p>
                              </div>
                            }
                          
                          <div class='d-flex my-3'>
                            <textarea
                              id="contactInputForm"
                              class="form-control p-2 px-4 m-0"
                              type="textarea"
                              name="snote"
                              placeholder="Any comments "
                              onChange={jointEducationDetils}
                            />
                            <p
                              class="fs-3  text-danger mx-1"
                              style={{ marginTop: "-5px" }}
                            >
                              *
                            </p>
                          </div>

                            <button
                              id="button"
                              class="btn btn-danger mt-3 p-2 rounded-5"
                              style={{ width: "100%" }}
                              onClick={() => submitJointEducationD("jeBox2")}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {jeBox == "jeBox2" ? (
                        <div>
                          <div class="text-start p-2">
                            <div class="d-flex gap-3">
                              <button
                                class="btn text-white"
                                onClick={() => setJeBox("jeBox1")}
                              >
                                <BiArrowBack />
                              </button>
                              <p class="text-white  fs-3 fw-semibold">
                                Offer for you{" "}
                              </p>
                            </div>

                            {jointEducationOffer?.map((el, i) => {
                              return (
                                <div
                                  class="bg-light mt-4"
                                  style={{
                                    boxShadow:
                                      "0px 4px 9px rgba(0, 0, 0, 0.33), inset 3px 6px 4px #E4E3E3, inset -6px -6px 4px #E0DADA",
                                    borderRadius: "13px",
                                  }}
                                >
                                  <div class="p-3 d-flex gap-2">
                                    <div>
                                      <img
                                        src={`https://tzapis.aris-world.com/icons/${el.incologo}`}
                                        style={{ width: "40px" }}
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <p class="text-danger fw-bold">
                                        {el.inconame}
                                      </p>
                                      <div class="d-flex gap-2">
                                        <p>Period :</p>
                                        <p
                                          class="btn   p-0 px-2 rounded-4"
                                          style={{
                                            border: "1px solid red",
                                            color: "red",
                                            background: "#ffefef",
                                          }}
                                        >
                                          1 year
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <div class="">
                                      <p class="fs-5 fw-semibold px-3">
                                        TZS <span>{el.fullyear}</span>
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        id="button"
                                        class="btn text-white px-3"
                                        style={{
                                          borderRadius: "15px 0px 13px 15px ",
                                        }}
                                        onClick={() =>
                                          OfferPayment({
                                            quoteid: el.quoteid,
                                            tenure: "Full Year",
                                            AccessToken: userToken,
                                          })
                                        }
                                      >
                                        Book
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        {/* -----------end right section -------------- */}
      </div>
    </div>
    }
    </>
  );
};

export default DashboardCopy;
