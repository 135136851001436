import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";


const PaymentSuccess = () => {

    let [loading, setLoading] = useState(false);
    const userToken = localStorage.getItem("User_token");
    const quitedId = sessionStorage.getItem("quoteid"); 

    const navigate = useNavigate()
    
    useEffect(()=>{
        axios.post(`https://tzapis.aris-world.com/paymentStatus.php`,{quoteid:quitedId,AccessToken:userToken})
        .then((response)=>{
            // console.log(response);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[userToken,quitedId ])

    useEffect(() => { 
        setLoading(true);
        setTimeout(() => { 
          setLoading(false);
          navigate('/my-policies')
        }, 2000);
      }, [ ]);



  return (
    <>
    {!userToken ? navigate('/log-in'): 
    <div
      style={{
        backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight: "100vh",
    }}
    class='d-flex align-items-center justify-content-center'
    >
            <div style={{width:'350px'}}>
                <img style={{width:'100%', height:'100%'}} src={require('../../Images/navbar/logo.png')} alt="" />
            </div>
            <SyncLoader
        color="red"
        loading={loading}
        // cssOverride={override}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
        style={{
          position: "absolute",
          left: "0%",
          top: "50%",
          width: "100%",
          zIndex: "999999",
        }}
      />


    </div>
}
</>
  )
}

export default PaymentSuccess