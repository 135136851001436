import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
 

const Verification = () => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [getOtp,setGetOtp] = useState('')
    const [show, setShow]= useState(false)
 
    const userToken =localStorage.getItem("User_token");
    const number =localStorage.getItem("number"); 
  const code = localStorage.getItem("clientCode");
 

    let lastNumber = ''
    for(let i = 0; i < number.length; i++){
      if( i > 4){ 
        lastNumber += number[i]
      }  
    }

 

       
 
        
    const sendOtp = () =>{  
        axios.post(`https://tzapis.aris-world.com/fetchMobileOTP.php?accesstoken=${userToken}`)
        .then((response) =>{  
          // console.log( 'res' ,response.data)
          if(response.data.success == true){ 
            setOtp(response.data.data)
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: `${response.data.message}`,
              showConfirmButton: false,
              timer: 1500
            })
          }else{
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: `${response.data.message}`, 
              showConfirmButton: false,
              timer: 2500
            })
          }
      })
      .catch((error) => {
        console.log("error", error); 
      });

    }
    useEffect(()=>{
      if(code){
        navigate("/dashboard")
      }else{ 
        sendOtp() 
      }
    },[ ]) 
 
    const counters =()=>{
      sendOtp() 
      setShow(true) 
    }

    const verifyOtp = () =>{
      if( otp == getOtp ){
        navigate('/dashboard')
      }else{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `OTP does not match `,
          showConfirmButton: false,
          timer: 1500
        })
      }  
    }


    if(getOtp.length == 6){
      verifyOtp()
    }

 
       
 
    
 
  return (
    <div style={{backgroundImage:`url(${require('../../Images/login/arisLogin.png')})`,backgroundRepeat:"no-repeat",backgroundSize:'100% 110%',height:'100vh'}}> 
        <br />
        <br />

        <div class='text-start   p-5' style={{maxWidth:'550px',margin:'auto'}}>
            <img src={require('../../Images/login/sms.png')} alt="" />
            <p class='text-white fs-1 fw-bold mt-3'>Phone<br /> Verification</p>
            <p class='text-white'>Please type the verification code <br /> sent to +255 *****<span>{lastNumber}</span> <span><button class='btn text-danger p-0 m-0 border-0 mb-2' disabled={show ? true : false}    onClick={counters}>Resend OTP?</button></span> <span></span> </p>

            
            <div class='mt-2'>
            <input  class=" p-2 px-4 my-2" type="text" id='contactInputForm' placeholder='OTP' onChange={(e)=>setGetOtp(e.target.value)}  maxlength="6" />
            </div>

            {/* <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2" style={{width:'250px'}}> 
                <input class="m-1 text-center form-control rounded-3" type="text" id="first" maxlength="1" onChange={handelUserOtp} />
                <input class="m-1 text-center form-control rounded-3" type="text" id="second" maxlength="1" onChange={handelUserOtp} /> 
                <input class="m-1 text-center form-control rounded-3" type="text" id="third" maxlength="1" onChange={handelUserOtp} /> 
                <input class="m-1 text-center form-control rounded-3" type="text" id="fourth" maxlength="1" onChange={handelUserOtp} />  
                <input class="m-1 text-center form-control rounded-3" type="text" id="third" maxlength="1" onChange={handelUserOtp} /> 
                <input class="m-1 text-center form-control rounded-3" type="text" id="fourth" maxlength="1" onChange={handelUserOtp} />  
            </div>   */}
            <div>
                <button id='button'class='btn btn-danger mt-5 p-2 rounded-5' style={{width:'100%'}} onClick={verifyOtp}>Verify</button>
            </div>
        </div>
    </div>
  )
}

export default Verification