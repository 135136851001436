import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react' 
import { useNavigate } from 'react-router-dom' 

import md5 from 'md5-hash'  
 import sha256 from 'sha256' 
import axios from 'axios'
 
import Swal from 'sweetalert2'
 


 



const Login = () => {
    const navigate = useNavigate() 
    const [mobileNumber,setMobileNumber] = useState('')
    const [crypt, setCrypt] = useState('') 
    const date = new Date().toISOString().replace('-',"").split('T')[0].replace('-', '');
    const cMobileNo = (255+mobileNumber).split(" ").join('')
    const md5Mobile =md5(cMobileNo)
    const md5Date =md5(date)
    const cryptToken = (sha256(crypt))  
    // console.log(mobileNumber);

 

    // let lastNumber = ''
    // for(let i = 0; i < cMobileNo.length; i++){
    //   if( i > 7){ 
    //     lastNumber += cMobileNo[i]
    //   }  
    // }
    // window.localStorage.setItem("last_number",lastNumber) 
    
    let lastNumber = ''
    for(let i = 0; i < cMobileNo.length; i++){
      if( i > 2){ 
        lastNumber += cMobileNo[i]
      }  
    }
    window.localStorage.setItem("number",lastNumber) 
    const code = localStorage.getItem("clientCode");



    
    const cryptMaker = () =>{
        axios.post('https://hi-labsolution.org/aris.php',{Mobile:md5Mobile , Double:md5Date,accessKey:'2GuBwAiJ7Akshk3DGKK6AVR410JD7S7dj'})
      .then((response) =>{  
          // console.log( 'res' ,response.data.data)
          setCrypt(response.data.data)
      })
      .catch(error => console.log('error',error)) 

    }

    const getLogin = () =>{
      if((cMobileNo[3] == 7 || cMobileNo[3] == 6) && (cMobileNo.length == 12 )){ 
        axios.get(`https://tzapis.aris-world.com/checkMobile.php?mobileNo=+${cMobileNo}&accesstoken=${cryptToken}`)
        .then((response) =>{  
            // console.log( 'res' ,response.data)
            window.localStorage.setItem("User_token",response.data.token)
            if(response.data?.message == "Existing User"){
              navigate('/verification')
        
             }else{
              Swal.fire({
                title: 'No client associated with this Mobile Number',
                icon: 'info', 
              
                  html:
                  '<hr/>'+   
                  '<a style="color:red; padding:4px 20px;background:red;border-radius: 50px;color: white;width:100%;text-decoration: none" href="/find-account">Find Your Account</a> ' +
                  '<br/>'+
                  '<hr/>'+ 
                  '<a style="color:red; padding:4px 20px;background:white;border-radius: 50px;color: black;width:100%;text-decoration: none" href="/new-account">Setup New Account</a> ' 
                  + '<br/>',
                showCloseButton: true,
                // showCancelButton: false,
                // focusConfirm: false, 
              })
             }
        })
        .catch(error => console.log('error',error)) 
      }else{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title:'incorrect mobile number',
          showConfirmButton: false,
          timer: 1500
        })
      }
     
  }
  cryptMaker()   
  return (
    <>
    { code ? navigate("/dashboard") :
    <div style={{backgroundImage:`url(${require('../../Images/login/arisLogin.png')})`,backgroundRepeat:"no-repeat",backgroundSize:'100% 110%',height:'100vh'}}> 
        <br />
        <br />

        <div class='text-start   p-5' style={{maxWidth:'550px',margin:'auto',}}>
            <img src={require('../../Images/login/mobile.png')} alt="" />
            <p class='text-white fs-1 fw-bold mt-3'>Enter your <br /> Phone Number</p>
            <p class='text-white'>Please sign in with your registered Phone number</p> 

            <div class="input-group mb-3 mt-4"  >
              <span class="input-group-text " id='numContactInputForm' style={{borderRadius:"50px 0px 0px 50px"}}>+255</span>
              <input id='numContactInputForm' style={{borderRadius:" 0px 50px 50px 0px "}} class="form-control p-2  m-0" type="text" placeholder='Number' onChange={(e)=>setMobileNumber(e.target.value)} maxlength="9" /> 
            </div>
            {/* <input id='contactInputForm' class="form-control p-2 px-4 my-2" type="text" placeholder='Number' onChange={(e)=>setMobileNumber(e.target.value)} />  */}
            <div>
                <button id='button'class='btn btn-danger mt-3 p-2 rounded-5' style={{width:'100%'}} onClick={getLogin}>Get OTP</button>
                <p class='text-white text-center mt-3'><span style={{cursor:'pointer'}} onClick={()=>navigate('/find-account')}>Find Your Account</span> <span class='mx-2'> | </span> <span style={{cursor:'pointer'}} onClick={()=>navigate('/find-account')}>Forgot Mobile?</span> <span class='mx-2'> | </span> <span style={{cursor:'pointer'}} onClick={()=>navigate('/log-in')}>New Registration</span></p>
            </div>
        </div>
        {/* <Loader/> */}
    </div>
    }
    </>
    
  )
}

export default Login






// $tokenDate = date('Ymd');
// $token = crypt($tokenDate,$strippedcontrolno); 
// $tokenHash = hash("sha256", $token); 
// $strippedcontrolno = without + sign >> 2557875609