import React from 'react'
import { useEffect } from 'react'
import { BsArrowUp } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import './Style.css'
import { FaBeer, FaLongArrowAltUp } from 'react-icons/fa';
const Blanck = () => {
    const navigate = useNavigate()

   
    useEffect(()=>{ 
        const section = document.querySelector('.blankSection')
        const button = document.querySelector('.blankBtn')
        const p1 = document.querySelector('.started') 
        const circle = document.querySelector('.clipPath-circle-pc') 
        const img = document.querySelector('.logoAnimation') 
        const p2 = document.querySelector('.started1') 

        let color = 'black' 
        button.addEventListener('click' ,()=>{
            section.style.background = color
            circle.style.background = 'black'
            img.style.backgroundImage=`url(${require('../Images/navbar/arisLogow.png')})`
            circle.classList.add('active-circle') 
            p1.style.color = 'white' 
            p2.style.color = 'white' 

        }) 

        setTimeout(()=>{
            section.style.background = color
            circle.style.background = 'black'
            img.style.backgroundImage=`url(${require('../Images/navbar/arisLogow.png')})`
            circle.classList.add('active-circle') 
            p1.style.color = 'white' 
            p2.style.color = 'white' 
            setTimeout(()=>{navigate('/log-in')},1000)
        },3000)
         
    })

       
    
 

    let startingY, movingY;
    const touchStart=(e)=>{ 
        startingY = e.touches[0].clientY; 
    }
    const touchMove=(e)=>{ 
        movingY = e.touches[0].clientY; 
    }
    const touchEnd=()=>{ 
        const section = document.querySelector('.blankSection')
        const button = document.querySelector('.blankBtn')
        const p1 = document.querySelector('.started') 
        const p2 = document.querySelector('.started1') 

        const circle = document.querySelector('.clipPath-circle-lp') 
        const img = document.querySelector('.logoAnimation') 
        let color = 'black' 
         if(startingY-100 > movingY){
            // console.log("up"); 
            section.style.background = color
            circle.style.background = 'black'
            img.style.backgroundImage=`url(${require('../Images/navbar/arisLogow.png')})`
            circle.classList.add('active-circle') 
            p1.style.color = 'white'
            p2.style.color = 'white' 

            setTimeout(()=>{

                navigate("/log-in")
            },1200)
        } 
        }
    
  return (
    <div class='blankSection'>  

    <div class='clipPath-circle-pc'>
         {/* <button class='btn blankBtn  text-light text-center'onClick={()=>setTimeout(()=>{navigate('/log-in')},1000)}><BsArrowUp class='fs-5 fw-bold'/> </button>  */}
         <FaLongArrowAltUp class=" blankBtn text-white fs-1"  onClick={()=>setTimeout(()=>{navigate('/log-in')},1000)}/>
     </div>
        
     {/* <div class='clipPath-circle-lp'>  
        <FaLongArrowAltUp class=" blankBtn text-white fs-1" onTouchStart={(e)=>touchStart(e)} onTouchMove={(e)=>touchMove(e)} onTouchEnd={()=>touchEnd()}/>
     </div> */}

      
        <div class='blankBox' >  
            <div class='logoAnimation' style={{width:'182px',height:'80px',margin:'auto',backgroundImage:`url(${require('../Images/navbar/arislogob.png')})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%'}}></div>
            <div class='mt-5' style={{position:'relative'}}> 
                <p class='started1  fw-bold' style={{margin:'auto'}}>Your Insurance needs in just a few clicks.</p>
                <p class='started2 text-danger fs-4 fw-bold' style={{margin:'auto'}}>SIMPLE …. FAST …. SECURE</p>

            </div>
        <p class='started'>Let’s get started</p>
        </div>
    </div>
  )
}

export default Blanck