import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
 
import Swal from 'sweetalert2';

const FileUpload = () => {

  const navigate = useNavigate()
  const userToken =localStorage.getItem("User_token");
  const [formData,setFormData] = useState({})

  const [radio, setRadio] = useState(""); 
  const [img,setImg] = useState('')
  const code = localStorage.getItem("clientCode");

  // const [userData, setUserData] = useState({uploadType:"client",accesstoken:userToken,uploadFor:radio});
 
  // console.log(radio);
  // console.log(formData);

   const  handleLogoUpload = async ({ currentTarget }) => {  
    if (currentTarget && currentTarget.files[0]) {
      const selectedFile = currentTarget.files[0]
      const contentType = selectedFile.type; 
      const formData = new FormData();
       formData.append("userfile" , selectedFile)
       formData.append("uploadType" , "client")
       formData.append("accesstoken" , userToken)
       formData.append("uploadFor" , radio)
        // debugger
       setFormData(formData)
     
      //  console.log("formData :" ,formData);
      const base64Url = await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
    });
    setImg(base64Url)
      // console.log(base64Url);
      // console.log(selectedFile);
      // console.log(contentType); 
      
      }
}

useEffect(()=>{
  handleLogoUpload()
},[radio])




 

 

  const submitfile = () =>{
      axios.post('https://tzapis.aris-world.com/clientFileUpload.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res)=>{
        console.log(res.data);
      if(res.data.success == true){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title:`${res.data.message}`,
          showConfirmButton: false,
          timer: 2000
        })
      }
      else{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title:`${res.data.message}`,
          showConfirmButton: false,
          timer: 2000
        })
      }
      })
      
  }

  useEffect(()=>{
    let inputFile = document.querySelector("#fileInputForm");
    let fileNameFild = document.querySelector("#file-name");
   inputFile.addEventListener("change", function(e){
    let uploadedFileName = e.target.files[0].name;
    fileNameFild.textContent = uploadedFileName;
   })

  })

  return (
    <>
    {!userToken ? navigate('/log-in'): 
    <div  style={{
        backgroundImage: `url(${require("../../Images/dashoard/dasBg.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 110%",
        minHeight: "100vh",
      }}> 
        <div class="text-start p-5" style={{ maxWidth: "550px", margin: "auto" }} > 
            <img style={{width:'80px'}} src={require("../../Images/dashoard/file.png")} alt="" />
            <p class='text-white fs-1 fw-bold'>File Upload</p>
            <p class='text-white'>Please Select your file </p> 
            <select class='p-2 px-3 border-white' style={{width:'100%',border:'none'}} id="contactInputForm" onChange={(e)=>setRadio(e.target.value)}>
              <option value=' ' >Select</option> 
              <option value='TIN' >TIN</option>
              <option value='NIDA' >NIDA</option> 
            </select>
                     <br /> 
              <div class='mt-3' style={{ width: "100%" }}>
                <input
                  id="fileInputForm"
                  class="form-control p-2 px-4 my-2 "
                  type="file"
                  name="userfile"
                  placeholder="select file"
                  onChange={handleLogoUpload}
                />
                <div style={{padding:'0px 15px'}} id='contactInputForm'>

                <label style={{border:'1px solid gray', padding:'5px'}} for='fileInputForm'> Choose File</label>
                  <span class='mx-3' id="file-name">No file chosen</span>
                </div>
              </div>
              <div class='my-3ni ' style={{width:'200px'}}>
                  <img style={{width:'100%'}} src={img} alt="" />
              </div>

              <button class='btn btn-danger rounded-5' style={{width:'100%'}} id='button' onClick={submitfile}> Submit</button>
              <button class='btn btn-danger rounded-5 mt-4' style={{width:'100%'}} id='button' onClick={()=>navigate('/dashboard')}> Back</button>
              
        </div>

      </div>
    }
    </>
  )
}

export default FileUpload