import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Contact from './components/Contact';
import AboutUs from './components/AboutUs';
import Insurance from './components/Insurance';
import TeamMembers from './components/TeamMembers';
import Login from './components/Login/Login';
import FindAccount from './components/Login/FindAccount';
import Verification from './components/Login/Verification'; 
import Blanck from './components/Blanck';
import NewAccount from './components/Login/NewAccount';
 
import AddAccount from './components/Login/AddAccount'; 
import Renewals from './components/Dashboard/Renewals';
import MyPolicies from './components/Dashboard/MyPolicies';
import Claims from './components/Dashboard/Claims';
import FileUpload from './components/Dashboard/FileUpload'; 
 
import MapContainer from './components/Dashboard/Maps';
import DashboardCopy from './components/Dashboard/DashboardCopy';
import PaymentSuccess from './components/Dashboard/PaymentSuccess';
import PopUp from './components/Dashboard/PopUp';
import FAQ from './components/Dashboard/FAQ';
import TermACondition from './components/Dashboard/TermACondition';
import InProgress from './components/Dashboard/InProgress';
import Disclaimer from './components/Dashboard/Disclaimer';
// import { PrivateRoute } from './components/PrivateRoute';
 
 
 

function App() {

  
  return (
    <div className="App"> 
     <Routes>
        <Route path='/home' element={<Home/>} />
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/insurance' element={<Insurance/>}/>
        <Route path='/members' element={<TeamMembers/>}/>  
        <Route path='/log-in' element={<Login/>}/>
        <Route path='/find-account' element={<FindAccount/>}/>
        <Route path='/verification' element={<Verification/>}/>
        <Route path='/' element={<Blanck/>} />
        <Route path='/new-account' element={<NewAccount/>} />
        <Route path='/add-account' element={<AddAccount/>} />

        <Route path='/dashboard' element={<DashboardCopy />} />
        <Route path='/renewals' element={<Renewals/>} />
        <Route path='/in-progress' element={<InProgress/>} />

        <Route path='/my-policies' element={<MyPolicies/>} />
        <Route path='/claims' element={ <Claims/>} />
        <Route path='/terms-conditions' element={ <TermACondition/>} />
        <Route path='/disclaimer' element={ <Disclaimer/>} />


        <Route path='/file-upload' element={ <FileUpload/>} />
      
        <Route path='/faq' element={  <FAQ/>} />

        <Route path='/payment-success' element={  <PaymentSuccess/> } />   
    
     </Routes>  
    </div>
  );
}

export default App;
