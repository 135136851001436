import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './Navbar';
import Footer from './Footer';


const insurance = [
    {
        lines:'Leading risk service provider in East Africa. We are ISO 9001:2015 certified.'
    }
    ,
    {
        lines:'We are the only Chartered Insurance Brokers in mainland Africa.'
    },
    {
        lines:'We are a member of the Inclusivity Pledge, a UK based initiative towards commitment to inclusive behaviours in an organization.'
    }
]


const Insurance = () => {
    useEffect(()=>{ 
        AOS.init({duration: 1500});  
    },[])
  return (
    <>
    <Navbar/>
    <div>
        <div class='d-flex justify-content-center' data-aos="zoom-in" style={{height:'50vh',backgroundImage:`url(${require('../Images/aboutUs/upperBg.png')})`,alignItems:'center'}}>
            <p class='text-white fs-1 fw-bold'>Insurance</p>
        </div>
        <div class='row justify-content-around py-5'>
            <div class='col-md-6 text-start p-4'>
                <p class='text-white fs-1 fw-bold' data-aos="fade-up">Motor <br /> Insurance</p>
                 <br />
                 <button id='button' class='btn btn-danger rounded-5 px-3' data-aos="fade-up">Book An Appointment</button>
            </div>
            <div class='col-md-5 text-end p-4'>
                <p class='text-white fs-5' data-aos="fade-up">Single Motor Insurance and Motor Fleet Insurance.
                Motor insurance protects the insured against financial loss in the event that the motor vehicle is involved in an accident, burnt or stolen.</p>
            </div>
        </div>
        <div class='row justify-content-around gap-5'>
            <div class='col-md-5 text-start'>
               <img style={{width:'100%'}} data-aos="zoom-in" src={require('../Images/aboutUs/insurance.png')} alt="" />
            </div>
            <div class='col-md-5 text-end p-4'>
                {
                    insurance.map((el)=>{
                         return(
                            <button class='btnWhyAris text-start ' data-aos="fade-up">{el.lines}</button>
                        )
                    })
                } 
            </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Insurance