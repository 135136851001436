import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './Navbar';
import Footer from './Footer';

const AboutUs = () => {
    useEffect(()=>{ 
        AOS.init({duration: 1500});  
    },[])
  return (
    <>
    <Navbar/>
    <div style={{overflow:"hidden"}}> 
        <div class='d-flex justify-content-center'  data-aos="zoom-in"style={{height:'50vh',backgroundImage:`url(${require('../Images/aboutUs/upperBg.png')})`,alignItems:'center'}}>
            <p class='text-white fs-1 fw-bold' > About Us</p>
        </div>
        <div class='row justify-content-around aboutUsAris'>
            <div class='col-lg-6'>
                <div class='p-5 text-start'>
                    <p class='fw-bold fs-1 text-white' data-aos="fade-up">Aris <br /> Company</p>
                </div>
                <img data-aos="zoom-in" style={{width:'100%',maxHeight:'60vh'}} src={require('../Images/aboutUs/arisHouse.png')} alt="" />
                
            </div>
            <div class='col-lg-6 p-5 text-start'>
                <p class='text-white fs-5' data-aos="fade-up">ARiS grew to its current position from humble beginnings dating back to 1998. It started as an insurance agency with a small office in Dar-es-Salaam, Tanzania.</p>
                <br />
                <p class='text-white fs-5' data-aos="fade-up">As the insurance industry developed, the founder, Mr. Sanjay Suchak decided to upgrade into an insurance brokerage firm as a means to provide a broader spectrum of services for client needs.</p>
                <br />
                <p class='text-white fs-5' data-aos="fade-up">Later, Alexander Forbes (then a leading financial services provider headquartered in South Africa) purchased a majority stake in 2002 to facilitate ARiS business expansion to cater for multinational corporations amongst other considerations.</p>
                <br />
                <p class='text-white fs-5' data-aos="fade-up">In 2004, the company achieved the status of being the leading insurance broker in Tanzania, a position it has maintained for over a decade and a half now.</p>
                <br />
                <p class='text-white fs-5' data-aos="fade-up">In 2013, an American group acquired Alexander Forbes. However, in Tanzania, the management decided to purchase the company and renamed it ARiS (African Risk and Insurance Services Limited).
                    The company has subsidiaries in Uganda, Kenya and the United Kingdom, the first insurance entity based in Tanzania to do so.
                    In 2018, the company became the largest insurance entity (amongst brokers and insurers) for the sum total of gross premiums booked for all classes of insurance.
                </p> 
            </div>
        </div>
        <div>
            <div class='row justify-content-around my-5 gap-5'>
                <div class='col-lg-4 text-start ' data-aos="fade-up" style={{backgroundImage:`url(${require("../Images/aboutUs/loverBg1.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'20% 100%',backgroundPosition:'top 0 left 0'}}>
                    <div class='p-5'> 
                        <p class='text-danger fs-1 fw-bold'>Our Vision</p>
                        <br />
                        <p class='text-white fs-5'>To be the Leading Risk Service Provider in East and Central Africa region.</p>
                        <br />
                        <button id='button' class='btn btn-danger px-3 p-2 rounded-5'>View Quality Policy</button>
                    </div>
                </div>
                <div class='col-lg-7' data-aos="fade-up"> 
                    <div style={{backgroundImage:`url(${require("../Images/aboutUs/loverBg2.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'90% 100%',backgroundPosition:'top 0 right 0'}}>                         
                    <div class='p-4 '> 
                        <div class='text-start' style={{width:'80%', margin:'auto 20%'}}>
                        <p class='fs-1 fw-bold mt-2'>Our Mission</p>
                        <br />
                        <p class='fs-5'>To develop in our stakeholders a sense of appreciation of risk management by offering effective risk solutions that endow peace of mind. This shall be achieved through five pillars:</p>
                        <br />
                        <br />
                        </div>
                    </div>
                    </div>
                        <div class='row justify-content-center gap-3' style={{marginTop:'-50px'}}>
                            <div class='col-sm-3 col-8' data-aos="fade-up">
                                <img src={require('../Images/aboutUs/om1.png')} alt="" />
                                <p class='fs-5 fw-bold text-white'>Integrity</p>
                            </div>
                            <div class='col-sm-3 col-8' data-aos="fade-up">
                                <img src={require('../Images/aboutUs/om2.png')} alt="" />
                                <p class='fs-5 fw-bold text-white'>Delivery of Innovative Knowledge and Products</p>
                            </div>
                            <div class='col-sm-3 col-8' data-aos="fade-up">
                                <img src={require('../Images/aboutUs/om3.png')} alt="" />
                                <p class='fs-5 fw-bold text-white'>Claims as Moment of Truth</p>
                            </div> 
                        </div>
                </div> 
            </div>

            <div class='row justify-content-around gap-3 p-3'>
                <div class='col-md-5' data-aos="fade-up">
                    <button class='ourVisionbtn  fs-1 fw-bold    '>Our Board Members</button>
                </div>
                <div class='col-md-5' data-aos="fade-up">
                    <button class='ourVisionbtn   fs-1 fw-bold  '>Our Key Staff</button>
                </div>
            </div>

        </div>
    </div>
    <Footer/>
    </>
  )
}

export default AboutUs