import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import { CiFacebook, CiGlass, CiInstagram, CiLinkedin } from 'react-icons/ci';
import Navbar from './Navbar';
import Footer from './Footer';
 

const members=[
    {
        id:'1',
        name:'Hon (Rtd) A. O. Kinana',
        post:'Chairman1',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m1.png')}`,
    },
    {
        id:'2',
        name:'Akash',
        post:'Chairman2',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m2.png')}`,
    },{
        id:'3',
        name:'Rakes',
        post:'Chairman3',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m6.png')}`,
    },{
        id:'4',
        name:'Krishna',
        post:'Chairman4',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m4.png')}`,
    },{
        id:'5',
        name:'Ritika',
        post:'Chairman5',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m5.png')}`,
    },{
        id:'6',
        name:'Ritik',
        post:'Chairman6',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m6.png')}`,
    },{
        id:'7',
        name:'Harsh',
        post:'Chairman7',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m1.png')}`,
    },{
        id:'8',
        name:'yaman',
        post:'Chairman8',
        detail:'<p>A Harvard University graduate, Hon. Abdulrahman Omar Kinana holds a number of key leadership positions, apart from being a successful businessman. He is a former Secretary General of the ruling party, CCM and the Chairman of Tanzania Pharmaceutical Industries, Diamond Shipping Company, Tanzania Red Cross, Tanzania Red Cross the Board of Trustees – Muhimbili National Hospital, and a Board Member of Tanzania Tobacco Leaf Company.</br> </br> Previous positions: Speaker of E. A. Legislative Assembly, Commission Member – Disaster and Relief Commission of Inter Federation of Red Cross and Red Crescent, Member – Development Commission of International Federation of Red Cross, Board Member – Mellicom International Company, Special Envoy of the President to the Great Lakes, Minister of Defence and National Service. Read More</p>',
        facebook:'#',
        insta:'#',
        linkdin:'#',
        img:`${require('../Images/membrs/m2.png')}`,
    },
]
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 2
    },
     
  };
const TeamMembers = () => {
    const [memberId,setMemberId]=useState('1')
    const [userData,setUserData] = useState([])
    // console.log('memberId :',memberId)

    const showData = ()=>{ 
        for(let i = 0 ; i < members.length; i++){
            if(members[i].id == memberId){  
                setUserData(members[i])
            }
        }
    }
    useEffect(()=>{
        showData()
    },[memberId])

    // console.log('detaisl :- ', userData)
  
  return (
    <>
    <Navbar/>
    <div> 
        <br />
        <br />
        <br />
        <div class='row justify-content-around'>
            <div class='col-md-6 text-start p-5'>
                <p class='fs-1 fw-bold text-white'>{userData.name}</p>
                <p class='text-danger fw-bold'>{userData.post}</p>
                <br />
                <p class='text-white' dangerouslySetInnerHTML={{__html :userData.detail}}></p>
                <div class='d-flex gap-4 mt-4'>
                    <CiFacebook class='text-light' style={{fontSize:'40px'}}/>
                    <CiInstagram class='text-light' style={{fontSize:'40px'}}/>
                    <CiLinkedin class='text-light' style={{fontSize:'40px'}}/>
                </div> 
            </div>
            <div class='col-md-5 p-5'>
                <img style={{width:'100%'}} src={userData.img} alt="" />

            </div> 
        </div>  
         
        <div style={{background:' rgba(223, 223, 223, 0.3)',backdropFilter: 'blur(19.5px)'}}> 
        <br />
        <br />
        <br />
            <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={false}  autoPlaySpeed={3000} showDots={false}     >
                {members.map((el)=>{
                    return( 
                        <div>
                            <img style={{width:'150px', height:'150px',cursor:'pointer'}} src={el.img} alt="" onClick={()=>setMemberId(`${el.id}`)} />
                        </div>
                        )
                })} 
            </Carousel>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default TeamMembers


  